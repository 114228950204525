import React, { useEffect } from "react";
import "./App.css";
import WOW from "wowjs";
import "./css/animate.min.css";
import Routing from "./Router/Routing";
import "./Main.css";
import 'semantic-ui-css/semantic.min.css';


function App() {
  useEffect(() => {
    const _wow = new WOW.WOW();
    _wow.init();
  }, []);
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
