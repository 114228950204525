import { useEffect, useState } from "react";
import { Accordion, AccordionButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import { Flag, Segment } from "semantic-ui-react";

type LanguageOption = {
  value: string;
  label: JSX.Element;
};

const MobileMenu = (logo: any) => {
  const [activeMenu, setActiveMenu] = useState("");
  const [multiMenu, setMultiMenu] = useState("");
  const [isClicked, setIsclicked] = useState(false);

  const activeMenuSet = (value: any) =>
      setActiveMenu(activeMenu === value ? "" : value),
    activeLi = (value: any) =>
      value === activeMenu ? { display: "block" } : { display: "none" };

    const { i18n } = useTranslation();
    const { t } = useTranslation();
  
    const languages: LanguageOption[] = [
      {
        value: "en",
        label: (
          <div style={{ display: "flex", alignItems: "center", color: "#000" }}>
            <Flag name="vg" /> En
          </div>
        ),
      },
      {
        value: "az",
        label: (
          <div style={{ display: "flex", alignItems: "center", color: "#000" }}>
            <Flag name="az" /> Az
          </div>
        ),
      },
      {
        value: "ar",
        label: (
          <div style={{ display: "flex", alignItems: "center", color: "#fff" }}>
            <Flag name="ae" /> Ae
          </div>
        ),
      },
      {
        value: "ru",
        label: (
          <div style={{ display: "flex", alignItems: "center", color: "#fff" }}>
            <Flag name="ru" /> Ru
          </div>
        ),
      },
    ];
  
 
    const [activeLang, setActiveLang] = useState<string>("en");



    const handleLangChange = (selectedOption: any) => {
      setActiveLang(selectedOption.value);
      i18n.changeLanguage(selectedOption.value);
    };
  return (
    <nav className="main-menu navbar-expand-lg d-block d-lg-none mobile-header">
      <Accordion>
        <div className="navbar-header">
          <div className="mobile-logo my-15">
            <Link to="/">
              <a>
                <img
                  src={"/assets/tth_logo.png"}
                  alt="Logo"
                  title="Logo"
                  style={{ height: "35px", width: "auto" }}
                />
              </a>
            </Link>
          </div>


          <div className="d-flex justify-content-center align-items-center me-2">
          <Select
            value={languages.find((lang) => lang.value === activeLang)}
            onChange={handleLangChange}
            options={languages}
            isSearchable={false}
          />
        </div>
          {/* Toggle Button */}
          <div className="menu-sidebar d-block d-lg-none">
            <button onClick={() => setIsclicked(true)}>
              <span className="icon-bar" style={{ backgroundColor: "#000" }} />
              <span className="icon-bar" style={{ backgroundColor: "#000" }} />
              <span className="icon-bar" style={{ backgroundColor: "#000" }} />
            </button>
          </div>
        </div>
        <Accordion.Collapse
          eventKey="nav"
          className="navbar-collapse clearfix"
          style={{ display: `${isClicked ? "block" : "none"}` }}
        >
          <ul className="navigation clearfix">
            <li className="dropdown">
              <Link to="/">{t("navHome")}</Link>
            </li>
            <li className="dropdown">
              <a href="#">{t("navServices")}</a>
              <ul style={activeLi("services")}>
                <li>
                  <Link to="/web-development">{t("navServicesSub1")}</Link>
                </li>
                <li>
                  <Link to="/mobile-app-development">
                  {t("navServicesSub2")}
                  </Link>
                </li>
                <li>
                  <Link to="/software-development">{t("navServicesSub3")}</Link>
                </li>
                <li>
                  <Link to="/dev-ops">{t("navServicesSub4")}</Link>
                </li>
                <li>
                  <Link to="/cloud-computing">{t("navServicesSub5")}</Link>
                </li>
                <li>
                  <Link to="/digital-marketing">{t("navServicesSub6")}</Link>
                </li>
              </ul>
              <div
                className="dropdown-btn"
                onClick={() => activeMenuSet("services")}
              >
                <span className="fas fa-chevron-down" />
              </div>
            </li>
            <li className="dropdown">
              <Link to="/projects">{t("navProject")}</Link>
            </li>
            <li className="dropdown">
              <Link to="/about-us">{t("navAbout")}</Link>
            </li>
            <li>
              <Link to="/contact-us">{t("navContact")}</Link>
            </li>
          </ul>
        </Accordion.Collapse>
      </Accordion>
    </nav>
  );
};
export default MobileMenu;
