import { Fragment, useState } from "react";
import { sideBarToggle } from "./utils";
import axios from "axios";
import { useTranslation } from "react-i18next";
const SideBar = () => {
  const [data, setData] = useState({
    name: "",
    mail: "",
    phonenumber: "",
    subject: "",
    message: "",
  });

  const handleContact = async (e: any) => {
    e.preventDefault();
    try {
      await axios.post("https://tth-website-api.onrender.com/ContactUs", {
        name: data.name,
        userEmail: data.mail,
        userPhonenumber: data.phonenumber,
        subject: data.subject,
        message: data.message,
      });
      setData({
        name: "",
        mail: "",
        phonenumber: "",
        subject: "",
        message: "",
      });
      alert("Mail Send Successfuly");
    } catch (err: any) {
      console.log(err.message);
      alert("Got internal error");
    }
  };
  const {t}=useTranslation()
  return (
    <>
      {/*Form Back Drop*/}
      <div className="form-back-drop" onClick={() => sideBarToggle()} />
      {/* Hidden Sidebar */}
      <section className="hidden-bar">
        <div className="inner-box text-center">
          <div className="cross-icon" onClick={() => sideBarToggle()}>
            <span className="fa fa-times" />
          </div>
          <div className="title">
            <h4>{t("navEnquiry")}</h4>
          </div>
          {/*Appointment Form*/}
          <div className="appointment-form">
            <form onSubmit={handleContact}>
              <div className="form-group">
                <input
                  type="text"
                  name="text"
                  defaultValue=""
                  placeholder="Name"
                  required
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  defaultValue=""
                  placeholder="Email Address"
                  required
                  onChange={(e) => setData({ ...data, mail: e.target.value })}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="text"
                  defaultValue=""
                  placeholder="Subject"
                  required
                  onChange={(e) =>
                    setData({ ...data, subject: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <textarea
                  placeholder="Message"
                  rows={5}
                  defaultValue={""}
                  onChange={(e) =>
                    setData({ ...data, message: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="theme-btn m-0"
                  style={{ width: "100%" }}
                >
                  Submit Now
                </button>
              </div>
            </form>
          </div>
          {/*Social Icons*/}
          <div className="social-style-one">
            <a href="https://twitter.com/home?lang=en-in" target="_blank">
              <i className="fab fa-twitter" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61550827268393"
              target="_blank"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a
              href="https://www.instagram.com/tthtechnologies/?hl=en"
              target="_blank"
            >
              <i className="fab fa-instagram" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};
export default SideBar;
