import React, { useEffect } from "react";
import "./Main.css";
import Footer from "./Footer/Footer";
import Header from "./Home/Header";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Project = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const {t}=useTranslation()
  return (
    <>
      <Helmet>
        <title>A Showcase of Our Finest Projects</title>
        <meta
          name="description"
          content="Discover our showcased projects, where innovation meets excellence. Explore our portfolio of digital triumphs and see what sets us apart."
        />
      </Helmet>
      <Header />
      <section className="page-banner-area Projectbanner pt-245 rpt-150 pb-170 rpb-100 rel z-1 bgc-lighter text-center">
        <div className="container">
          <div className="banner-inner rpt-10">
            <h1 className="page-title wow fadeInUp delay-0-2s">
              {t("projBannerH")}
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <a>{t("projBannerHome")}</a>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  style={{ color: "#fff" }}
                >
                  {t("projBannerHomeNav")}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="banner-shapes">
          <div
            className="circle projectCircle wow zoomInLeft delay-0-2s"
            data-wow-duration="2s"
          />
          <img
            className="shape-one"
            src="/images/shapes/hero-shape1.png"
            alt="Shape"
            loading="lazy"
          />
          <img
            className="shape-two"
            src="/images/shapes/hero-shape2.png"
            alt="Shape"
            loading="lazy"
          />
        </div>
      </section>
      <div className="p-2">
      <section>
          <div className="container">
            <div className="row mb-4" style={{ paddingTop: "4rem" }}>
              <div className="col-lg-12 col-md-12 col-sm-12 p-0 image-hover">
                <img
                  src="/assets/8 Projects/crm.webp"
                  loading="lazy"
                  className="img-fluid"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
            <div
              className="row justify-content-center mb-4"
              style={{
                backgroundColor: "rgb(31, 39, 50)",
                padding: "2.3rem",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projSpeedH")}</h3>
                <p className="projP-client">{t("projSpeedPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projSpeedDurH")}</h3>
                <p className="projP-client">{t("projSpeedDurPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projSpeedDateH")}</h3>
                <p className="projP-client">{t("projSpeedDatePara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projSpeedCateH")}</h3>
                <p className="projP-client">{t("projSpeedCatePara")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 p-0">
                <div>
                  <h2 style={{ color: "#d5aac9", fontWeight: "bold" }}>
                  {t("prjKeyH")}
                  </h2>
                  <ul>
                    <li className="convenience">{t("projSpeedKeyLi1H")}</li>
                    <p>
                    {t("projSpeedKeyLi1Para")}
                    </p>
                    <li className="convenience">{t("projSpeedKeyLi2H")}</li>
                    <p>
                    {t("projSpeedKeyLi2Para")}
                    </p>
                    <li className="convenience">{t("projSpeedKeyLi3H")}</li>
                    <p>
                    {t("projSpeedKeyLi3Para")}
                    </p>
                    <li className="convenience">{t("projSpeedKeyLi4H")}</li>
                    <p>
                    {t("projSpeedKeyLi4Para")}
                    </p>
                    <li className="convenience">{t("projSpeedKeyLi5H")}</li>
                    <p>
                    {t("projSpeedKeyLi5Para")}
                    </p>
                    <li className="convenience">{t("projSpeedKeyLi6H")}</li>
                    <p>
                    {t("projSpeedKeyLi6Para")}
                    </p>
                    <li className="convenience">{t("projSpeedKeyLi7H")}</li>
                    <p>
                    {t("projSpeedKeyLi7Para")}
                    </p>
                    <li className="convenience">{t("projSpeedKeyLi8H")}</li>
                    <p>
                    {t("projSpeedKeyLi8Para")}
                    </p>
                  </ul>
                </div>
                <div className="d-flex justify-content-end align-items-center p-2">
                  <div style={{ width: "100%", textAlign: "start" }}>
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/react2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo"
                      loading="lazy"
                      src="assets/Project/bootstrap2.png"
                      style={{ width: "3%" }}
                    />
                          <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/aws2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo"
                      loading="lazy"
                      src="assets/Project/spring2.png"
                      style={{ width: "3%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ paddingTop: "4rem" }}>
          <div className="container">
            <div className="row mb-4" style={{ paddingTop: "4rem" }}>
              <div className="col-lg-12 col-md-12 col-sm-12 p-0 image-hover">
                <img
                  src="/assets/8 Projects/wishiton.webp"
                  className="img-fluid"
                  loading="lazy"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
            <div
              className="row justify-content-center mb-4"
              style={{
                backgroundColor: "rgb(31, 39, 50)",
                padding: "2.3rem",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projwishCLientH")}</h3>
                <p className="projP-client">{t("projwishCLientPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projwishDurH")}</h3>
                <p className="projP-client">{t("projwishDurPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projwishDateH")}</h3>
                <p className="projP-client">{t("projwishDatePara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projwishCateH")}</h3>
                <p className="projP-client">{t("projwishCatePara")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 p-0">
                <div>
                  <h2 style={{ color: "#d5aac9", fontWeight: "bold" }}>
                  {t("prjKeyH")}
                  </h2>
                  <ul>
                    <li className="proj-Para">
                    {t("projWishLi1")}
                    </li>
                    <li className="proj-Para">
                    {t("projWishLi2")}
                    </li>
                    <li className="proj-Para">
                    {t("projWishLi3")}
                    </li>
                    <li className="proj-Para">
                    {t("projWishLi4")}
                    </li>
                    <li className="proj-Para">
                    {t("projWishLi5")}       
                                 </li>
                    <li className="proj-Para">
                    {t("projWishLi6")}
                    </li>
                    <li className="proj-Para">
                    {t("projWishLi7")}
                    </li>
                    <li className="proj-Para">
                    {t("projWishLi8")}
                    </li>
                    <li className="proj-Para">
                    {t("projWishLi9")}
                    </li>
                    <li className="proj-Para">
                    {t("projWishLi10")}
                    </li>
                    <li className="proj-Para">
                    {t("projWishLi11")}
                    </li>
                    <li className="proj-Para">
                    {t("projWishLi12")}
                    </li>
                    <li className="proj-Para">
                    {t("projWishLi13")}
                    </li>
                  </ul>
                </div>
                <div className="d-flex justify-content-end align-items-center p-2">
                  <div style={{ width: "100%", textAlign: "start" }}>
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/react2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/node2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/express2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/bootstrap2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo1 me-3"
                      loading="lazy"
                      src="assets/Project/flutter2.png"
                      style={{ width: "2.5%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/aws2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo"
                      loading="lazy"
                      src="assets/Project/spring2.png"
                      style={{ width: "3%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="line">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
              <div className="dot dot4"></div>
              <div className="dot dot5"></div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mb-4" style={{ paddingTop: "4rem" }}>
              <div className="col-lg-12 col-md-12 col-sm-12 p-0 image-hover">
                <img
                  src="/assets/8 Projects/dryo.webp"
                  loading="lazy"
                  className="img-fluid"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
            <div
              className="row justify-content-center mb-4"
              style={{
                backgroundColor: "rgb(31, 39, 50)",
                padding: "2.3rem",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projDry0H")}</h3>
                <p className="projP-client">{t("projDryoPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projDryoDurH")}</h3>
                <p className="projP-client">{t("projDryoDurPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projDryoDateH")}</h3>
                <p className="projP-client">{t("projDryoDatePara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projDryoCateH")}</h3>
                <p className="projP-client">{t("projDryoCatePara")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 p-0">
                <div>
                  <h2 style={{ color: "#d5aac9", fontWeight: "bold" }}>
                  {t("prjKeyH")}
                  </h2>
                  <ul>
                    <li className="convenience">{t("projDryoKeyLi1H")}</li>
                    <p>
                    {t("projDryoKeyLi1Para")}
                    </p>
                    <li className="convenience">{t("projDryoKeyLi2H")}</li>
                    <p>
                    {t("projDryoKeyLi2Para")}
                    </p>
                    <li className="convenience">{t("projDryoKeyLi3H")}</li>
                    <p>
                    {t("projDryoKeyLi3Para")}
                    </p>
                    <li className="convenience">{t("projDryoKeyLi4H")}</li>
                    <p>
                    {t("projDryoKeyLi4Para")}
                    </p>
                    <li className="convenience">{t("projDryoKeyLi5H")}</li>
                    <p>
                    {t("projDryoKeyLi5Para")}
                    </p>
                    <li className="convenience">{t("projDryoKeyLi6H")}</li>
                    <p>
                    {t("projDryoKeyLi6Para")}
                    </p>
                    <li className="convenience">{t("projDryoKeyLi7H")}</li>
                    <p>
                    {t("projDryoKeyLi7Para")}
                    </p>
                    <li className="convenience">{t("projDryoKeyLi8H")}</li>
                    <p>
                    {t("projDryoKeyLi8Para")}
                    </p>
                  </ul>
                </div>
                <div className="d-flex justify-content-end align-items-center p-2">
                  <div style={{ width: "100%", textAlign: "start" }}>
                    <img
                      className="img-fluid p-1 proglogo1 me-3"
                      loading="lazy"
                      src="assets/Project/flutter2.png"
                      style={{ width: "2%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/spring2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo1"
                      loading="lazy"
                      src="assets/Project/mongo2.png"
                      style={{ width: "2%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="line">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
              <div className="dot dot4"></div>
              <div className="dot dot5"></div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row mb-4" style={{ paddingTop: "4rem" }}>
              <div className="col-lg-12 col-md-12 col-sm-12 p-0 image-hover">
                <img
                  src="/assets/8 Projects/balitek.webp"
                  loading="lazy"
                  className="img-fluid"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
            <div
              className="row justify-content-center mb-4"
              style={{
                backgroundColor: "rgb(31, 39, 50)",
                padding: "2.3rem",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projBaliH")}</h3>
                <p className="projP-client">{t("projBaliPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projBaliDurH")}</h3>
                <p className="projP-client">{t("projBaliDurPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projBaliDateH")}</h3>
                <p className="projP-client">{t("projBaliDatePara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projBaliCateH")}</h3>
                <p className="projP-client">{t("projBaliCatePara")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 p-0">
                <div>
                  <h2 style={{ color: "#d5aac9", fontWeight: "bold" }}>
                  {t("prjKeyH")}
                  </h2>
                  <ul>
                    <li className="convenience">{t("projBaliKeyLi1H")}</li>
                    <p>
                    {t("projBaliKeyLi1Para")}
                    </p>
                    <li className="convenience">{t("projBaliKeyLi2H")}</li>
                    <p>
                    {t("projBaliKeyLi2Para")}
                    </p>
                    <li className="convenience">{t("projBaliKeyLi3H")}</li>
                    <p>
                    {t("projBaliKeyLi3Para")}
                    </p>
                    <li className="convenience">{t("projBaliKeyLi4H")}</li>
                    <p>
                    {t("projBaliKeyLi4Para")}
                    </p>
                    <li className="convenience">
                    {t("projBaliKeyLi5H")}
                    </li>
                    <p>
                    {t("projBaliKeyLi5Para")}
                    </p>
                    <li className="convenience">{t("projBaliKeyLi6H")}</li>
                    <p>
                    {t("projBaliKeyLi6Para")}
                    </p>
                    <li className="convenience">{t("projBaliKeyLi7H")}</li>
                    <p>
                    {t("projBaliKeyLi7Para")}
                    </p>
                    <li className="convenience">{t("projBaliKeyLi8H")}</li>
                    <p>
                    {t("projBaliKeyLi8Para")}
                    </p>
                  </ul>
                </div>
                <div className="d-flex justify-content-end align-items-center p-2">
                  <div style={{ width: "100%", textAlign: "start" }}>
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/html2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/css2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo"
                      loading="lazy"
                      src="assets/Project/js2.png"
                      style={{ width: "3%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="line">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
              <div className="dot dot4"></div>
              <div className="dot dot5"></div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row mb-4" style={{ paddingTop: "4rem" }}>
              <div className="col-lg-12 col-md-12 col-sm-12 p-0 image-hover">
                <img
                  src="/assets/Project/pay.jpg"
                  loading="lazy"
                  className="img-fluid"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
            <div
              className="row justify-content-center mb-4"
              style={{
                backgroundColor: "rgb(31, 39, 50)",
                padding: "2.3rem",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projPayH")}</h3>
                <p className="projP-client">{t("projPayPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projPayDUrH")}</h3>
                <p className="projP-client">{t("projPayDurPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projPayDateH")}</h3>
                <p className="projP-client">{t("projPayDatePara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projPayCateH")}</h3>
                <p className="projP-client">{t("projPayCatePara")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 p-0">
                <div>
                  <h2 style={{ color: "#d5aac9", fontWeight: "bold" }}>
                  {t("prjKeyH")}
                  </h2>
                  <ul>
                    <li className="convenience">{t("projPayKeyLi1H")}</li>
                    <p>
                    {t("projPayKeyLi1Para")}
                    </p>
                    <li className="convenience">{t("projPayKeyLi2H")}</li>
                    <p>
                    {t("projPayKeyLi2Para")}
                    </p>
                    <li className="convenience">{t("projPayKeyLi3H")}</li>
                    <p>
                    {t("projPayKeyLi3Para")}
                    </p>
                    <li className="convenience">{t("projPayKeyLi4H")}</li>
                    <p>
                    {t("projPayKeyLi4Para")}
                    </p>
                    <li className="convenience">{t("projPayKeyLi5H")}</li>
                    <p>
                    {t("projPayKeyLi5Para")}
                    </p>
                    <li className="convenience">{t("projPayKeyLi6H")}</li>
                    <p>
                    {t("projPayKeyLi6Para")}
                    </p>
                    <li className="convenience">{t("projPayKeyLi7H")}</li>
                    <p>
                    {t("projPayKeyLi7Para")}
                    </p>
                    <li className="convenience">
                    {t("projPayKeyLi8H")}
                    </li>
                    <p>
                    {t("projPayKeyLi8Para")}
                    </p>
                    <li className="convenience">{t("projPayKeyLi9H")}</li>
                    <p>
                    {t("projPayKeyLi9Para")}
                    </p>
                    <li className="convenience">{t("projPayKeyLi10H")}</li>
                    <p>
                    {t("projPayKeyLi10Para")}
                    </p>
                  </ul>
                </div>
                <div className="d-flex justify-content-end align-items-center p-2">
                  <div style={{ width: "100%", textAlign: "start" }}>
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/react2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/node2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/express2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo1 me-3"
                      loading="lazy"
                      src="assets/Project/flutter2.png"
                      style={{ width: "2%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/aws2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/bootstrap2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo"
                      loading="lazy"
                      src="assets/Project/spring2.png"
                      style={{ width: "3%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="line">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
              <div className="dot dot4"></div>
              <div className="dot dot5"></div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row mb-4" style={{ paddingTop: "4rem" }}>
              <div className="col-lg-12 col-md-12 col-sm-12 p-0 image-hover">
                <img
                  src="/assets/8 Projects/klcabs.webp"
                  loading="lazy"
                  className="img-fluid"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
            <div
              className="row justify-content-center mb-4"
              style={{
                backgroundColor: "rgb(31, 39, 50)",
                padding: "2.3rem",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projCabsH")}</h3>
                <p className="projP-client">{t("projCabsPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projCabsDurH")}</h3>
                <p className="projP-client">{t("projCabsDurPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projCabsDateH")}</h3>
                <p className="projP-client">{t("projCabsDataPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projCabsCateH")}</h3>
                <p className="projP-client">{t("projCabsCatePara")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 p-0">
                <div>
                  <h2 style={{ color: "#d5aac9", fontWeight: "bold" }}>
                  {t("prjKeyH")}
                  </h2>
                  <ul>
                    <li className="convenience">{t("projCabsKeyLi1H")}</li>
                    <p>
                    {t("projCabsKeyLi1Para")}
                    </p>
                    <li className="convenience">{t("projCabsKeyLi2H")}</li>
                    <p>
                    {t("projCabsKeyLi2Para")}
                    </p>
                    <li className="convenience">{t("projCabsKeyLi3H")}</li>
                    <p>
                    {t("projCabsKeyLi3Para")}
                    </p>
                    <li className="convenience">{t("projCabsKeyLi4H")}</li>
                    <p>
                    {t("projCabsKeyLi4Para")}
                    </p>
                    <li className="convenience">{t("projCabsKeyLi5H")}</li>
                    <p>
                    {t("projCabsKeyLi5Para")}
                    </p>
                    <li className="convenience">
                    {t("projCabsKeyLi6H")}
                    </li>
                    <p>
                    {t("projCabsKeyLi6Para")}
                    </p>
                    <li className="convenience">{t("projCabsKeyLi7H")}</li>
                    <p>
                    {t("projCabsKeyLi7Para")}
                    </p>
                    <li className="convenience">{t("projCabsKeyLi8H")}</li>
                    <p>
                    {t("projCabsKeyLi8Para")}
                    </p>
                    <li className="convenience">{t("projCabsKeyLi9H")}</li>
                    <p>
                    {t("projCabsKeyLi9Para")}
                    </p>
                  </ul>
                </div>
                <div className="d-flex justify-content-end align-items-center p-2">
                  <div style={{ width: "100%", textAlign: "start" }}>
                    <img
                      className="img-fluid p-1 proglogo1 me-3"
                      loading="lazy"
                      src="assets/Project/flutter2.png"
                      style={{ width: "2%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo1"
                      loading="lazy"
                      src="assets/Project/firebase2.png"
                      style={{ width: "2%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="line">
              <div className="container">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot dot5"></div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row mb-4" style={{ paddingTop: "4rem" }}>
              <div className="col-lg-12 col-md-12 col-sm-12 p-0 image-hover">
                <img
                  src="/assets/8 Projects/logic.webp"
                  loading="lazy"
                  className="img-fluid"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
            <div
              className="row justify-content-center mb-4"
              style={{
                backgroundColor: "rgb(31, 39, 50)",
                padding: "2.3rem",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projLogiH")}</h3>
                <p className="projP-client">{t("projLogiPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projLogiDurH")}</h3>
                <p className="projP-client">{t("projLogiDurPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projLogiDateH")}</h3>
                <p className="projP-client">{t("projLogiDatePara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projLogiCateH")}</h3>
                <p className="projP-client">{t("projLogiCatePara")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 p-0">
                <div>
                  <h2 style={{ color: "#d5aac9", fontWeight: "bold" }}>
                  {t("prjKeyH")}
                  </h2>
                  <ul>
                    <li className="convenience">{t("projLogiKeyLi1H")}</li>
                    <p>
                    {t("projLogiKeyLi1Para")}
                    </p>
                    <li className="convenience">{t("projLogiKeyLi2H")}</li>
                    <p>{t("projLogiKeyLi2Para")}</p>
                    <li className="convenience">{t("projLogiKeyLi3H")}</li>
                    <p>
                    {t("projLogiKeyLi3Para")}
                    </p>
                    <li className="convenience">{t("projLogiKeyLi4H")}</li>
                    <p>
                    {t("projLogiKeyLi4Para")}
                    </p>
                    <li className="convenience">{t("projLogiKeyLi5H")}</li>
                    <p>{t("projLogiKeyLi5Para")}</p>
                    <li className="convenience">{t("projLogiKeyLi6H")}</li>
                    <p>
                    {t("projLogiKeyLi6Para")}
                    </p>
                    <li className="convenience">{t("projLogiKeyLi7H")}</li>
                    <p>
                    {t("projLogiKeyLi7Para")}
                    </p>
                    <li className="convenience">{t("projLogiKeyLi8H")}</li>
                    <p>
                    {t("projLogiKeyLi8Para")}
                    </p>
                    <li className="convenience">
                    {t("projLogiKeyLi9H")}
                    </li>
                    <p>{t("projLogiKeyLi9Para")}</p>
                    <li className="convenience">{t("projLogiKeyLi10H")}</li>
                    <p>
                    {t("projLogiKeyLi10Para")}
                    </p>
                  </ul>
                </div>
                <div className="d-flex justify-content-end align-items-center p-2">
                  <div style={{ width: "100%", textAlign: "start" }}>
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/react2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo"
                      loading="lazy"
                      src="assets/Project/bootstrap2.png"
                      style={{ width: "3%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="line">
              <div className="container">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot dot5"></div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row mb-4" style={{ paddingTop: "4rem" }}>
              <div className="col-lg-12 col-md-12 col-sm-12 p-0 image-hover">
                <img
                  src="/assets/Project/ovantica.jpg"
                  loading="lazy"
                  className="img-fluid"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
            <div
              className="row justify-content-center mb-4"
              style={{
                backgroundColor: "rgb(31, 39, 50)",
                padding: "2.3rem",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projOvanH")}</h3>
                <p className="projP-client">{t("projOvanPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projOvanDurH")}</h3>
                <p className="projP-client">{t("projOvanDurPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projOvanDateH")}</h3>
                <p className="projP-client">{t("projOvanDatePara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projOvanCateH")}</h3>
                <p className="projP-client">{t("projOvanCatePara")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 p-0">
                <div>
                  <h2 style={{ color: "#d5aac9", fontWeight: "bold" }}>
                  {t("prjKeyH")}
                  </h2>
                  <ul>
                    <li className="convenience">{t("projOvanKeyLi1H")}</li>
                    <p>
                      {" "}
                      {t("projOvanKeyLi1Para")}
                    </p>
                    <li className="convenience">{t("projOvanKeyLi2H")}</li>
                    <p>
                    {t("projOvanKeyLi2Para")}
                    </p>
                    <li className="convenience">{t("projOvanKeyLi3H")}</li>
                    <p>
                    {t("projOvanKeyLi3Para")}
                    </p>
                    <li className="convenience">{t("projOvanKeyLi4H")}</li>
                    <p>
                    {t("projOvanKeyLi4Para")}
                    </p>
                    <li className="convenience">
                    {t("projOvanKeyLi5H")}
                    </li>
                    <p>
                    {t("projOvanKeyLi5Para")}
                    </p>
                    <li className="convenience">{t("projOvanKeyLi6H")}</li>
                    <p>
                    {t("projOvanKeyLi6Para")}
                    </p>
                    <li className="convenience">{t("projOvanKeyLi7H")}</li>
                    <p>
                    {t("projOvanKeyLi7Para")}
                    </p>
                    <li className="convenience">{t("projOvanKeyLi8H")}</li>
                    <p>
                      {" "}
                      {t("projOvanKeyLi8Para")}
                    </p>
                    <li className="convenience">{t("projOvanKeyLi9H")}</li>
                    <p>
                    {t("projOvanKeyLi9Para")}
                    </p>
                  </ul>
                </div>
                <div className="d-flex justify-content-end align-items-center p-2">
                  <div style={{ width: "100%", textAlign: "start" }}>
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/react2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo"
                      loading="lazy"
                      src="assets/Project/bootstrap2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/aws2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo"
                      loading="lazy"
                      src="assets/Project/spring2.png"
                      style={{ width: "3%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="line">
              <div className="container">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot dot5"></div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row mb-4" style={{ paddingTop: "4rem" }}>
              <div className="col-lg-12 col-md-12 col-sm-12 p-0 image-hover">
                <img
                  src="/assets/8 Projects/rice.webp"
                  loading="lazy"
                  className="img-fluid"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
            <div
              className="row justify-content-center mb-4"
              style={{
                backgroundColor: "rgb(31, 39, 50)",
                padding: "2.3rem",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projPureH")}</h3>
                <p className="projP-client">{t("projPurePara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projPureDurH")}</h3>
                <p className="projP-client">{t("projPureDurPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projPureDateH")}</h3>
                <p className="projP-client">{t("projPureDatePara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projPureCateH")}</h3>
                <p className="projP-client">{t("projPureCatePara")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 p-0">
                <div>
                  <h2 style={{ color: "#d5aac9", fontWeight: "bold" }}>
                  {t("prjKeyH")}
                  </h2>
                  <ul>
                    <li className="convenience">{t("projPureKeyLi1H")}</li>
                    <p>
                    {t("projPureKeyLi1Para")}
                    </p>
                    <li className="convenience">{t("projPureKeyLi2H")}</li>
                    <p>
                    {t("projPureKeyLi2Para")}
                    </p>
                    <li className="convenience">{t("projPureKeyLi3H")}</li>
                    <p>
                    {t("projPureKeyLi3Para")}
                    </p>
                    <li className="convenience">{t("projPureKeyLi4H")}</li>
                    <p>
                    {t("projPureKeyLi4Para")}
                    </p>
                  </ul>
                </div>
                <div className="d-flex justify-content-end align-items-center p-2">
                  <div style={{ width: "100%", textAlign: "start" }}>
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/react2.png"
                      style={{ width: "3%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo"
                      loading="lazy"
                      src="assets/Project/bootstrap2.png"
                      style={{ width: "3%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section>
          <div className="container">
            <div className="row mb-4" style={{ paddingTop: "4rem" }}>
              <div className="col-lg-12 col-md-12 col-sm-12 p-0 image-hover">
                <img
                  src="/assets/meatmarket.jpg"
                  loading="lazy"
                  className="img-fluid"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
            <div
              className="row justify-content-center mb-4"
              style={{
                backgroundColor: "rgb(31, 39, 50)",
                padding: "2.3rem",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projMMH")}</h3>
                <p className="projP-client">{t("projMMPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projMMDurH")}</h3>
                <p className="projP-client">{t("projMMDurPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projMMDateH")}</h3>
                <p className="projP-client">{t("projMMDatePara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projMMCateH")}</h3>
                <p className="projP-client">{t("projMMCatePara")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 p-0">
                <div>
                  <h2 style={{ color: "#d5aac9", fontWeight: "bold" }}>
                  {t("prjKeyH")}
                  </h2>
                  <ul>
                    <li className="convenience">{t("projMMKeyLi1H")}</li>
                    <p>
                    {t("projMMKeyLi1Para")}
                    </p>
                    <li className="convenience">{t("projMMKeyLi2H")}</li>
                    <p>
                    {t("projMMKeyLi2Para")}
                    </p>
                    <li className="convenience">{t("projMMKeyLi3H")}</li>
                    <p>
                    {t("projPureKeyLi3Para")}
                    </p>
                    <li className="convenience">{t("projMMKeyLi4H")}</li>
                    <p>
                    {t("projMMKeyLi4Para")}
                    </p>
                    <li className="convenience">{t("projMMKeyLi5H")}</li>
                    <p>
                    {t("projMMKeyLi5Para")}
                    </p>
                    <li className="convenience">{t("projMMKeyLi6H")}</li>
                    <p>
                    {t("projMMKeyLi6Para")}
                    </p>
                  </ul>
                </div>
                <div className="d-flex justify-content-end align-items-center p-2">
                  <div style={{ width: "100%", textAlign: "start" }}>
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/react2.png"
                      style={{ width: "3%" }}
                    />
                      <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/node2.png"
                      style={{ width: "3%" }}
                    />
                         <img
                      className="img-fluid p-1 me-1 proglogo1"
                      loading="lazy"
                      src="assets/Project/mongo2.png"
                      style={{ width: "2%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo"
                      loading="lazy"
                      src="assets/Project/bootstrap2.png"
                      style={{ width: "3%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mb-4" style={{ paddingTop: "4rem" }}>
              <div className="col-lg-12 col-md-12 col-sm-12 p-0 image-hover">
                <img
                  src="/assets/rg.jpeg"
                  loading="lazy"
                  className="img-fluid"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
            <div
              className="row justify-content-center mb-4"
              style={{
                backgroundColor: "rgb(31, 39, 50)",
                padding: "2.3rem",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projrgH")}</h3>
                <p className="projP-client">{t("projrgPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projrgDurH")}</h3>
                <p className="projP-client">{t("projrgDurPara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projrgDateH")}</h3>
                <p className="projP-client">{t("projrgDatePara")}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                <h3 className="proj-Client">{t("projrgCateH")}</h3>
                <p className="projP-client">{t("projrgCatePara")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 p-0">
                <div>
                  <h2 style={{ color: "#d5aac9", fontWeight: "bold" }}>
                  {t("prjKeyH")}
                  </h2>
                  <ul>
                    <li className="convenience">{t("projrgKeyLi1H")}</li>
                    <p>
                    {t("projrgKeyLi1Para")}
                    </p>
                    <li className="convenience">{t("projrgKeyLi2H")}</li>
                    <p>
                    {t("projrgKeyLi2Para")}
                    </p>
                    <li className="convenience">{t("projrgKeyLi3H")}</li>
                    <p>
                    {t("projrgKeyLi3Para")}
                    </p>
                    <li className="convenience">{t("projrgKeyLi4H")}</li>
                    <p>
                    {t("projrgKeyLi4Para")}
                    </p>
                    <li className="convenience">{t("projrgKeyLi5H")}</li>
                    <p>
                    {t("projrgKeyLi5Para")}
                    </p>
                    <li className="convenience">{t("projrgKeyLi6H")}</li>
                    <p>
                    {t("projrgKeyLi6Para")}
                    </p>
                  </ul>
                </div>
                <div className="d-flex justify-content-end align-items-center p-2">
                  <div style={{ width: "100%", textAlign: "start" }}>
                    <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/react2.png"
                      style={{ width: "3%" }}
                    />
                      <img
                      className="img-fluid p-1 proglogo me-3"
                      loading="lazy"
                      src="assets/Project/node2.png"
                      style={{ width: "3%" }}
                    />
                         <img
                      className="img-fluid p-1 me-1 proglogo1"
                      loading="lazy"
                      src="assets/Project/mongo2.png"
                      style={{ width: "2%" }}
                    />
                    <img
                      className="img-fluid p-1 proglogo"
                      loading="lazy"
                      src="assets/Project/bootstrap2.png"
                      style={{ width: "3%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
      <section className="call-to-action-area Projectbanner bgc-black mt-100 pt-80 pb-50">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-white mb-25 wow fadeInUp delay-0-2s">
                <h2>{t("projLetsH")}</h2>
                <p>
                {t("projLetsPara")}
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-lg-end">
              <Link to="/contact-us">
                <a className="theme-btn projectlearnCall style-two mb-30 wow fadeInUp delay-0-4s">
                {t("projLetsBtn")} <i className="fas fa-angle-double-right" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Project;
