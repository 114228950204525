import React, { useEffect } from "react";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";
import "../css/flaticon.min.css";
import "../css/fontawesome-5.14.0.min.css";
import "../Main.css";
import ProgressBar from "./ProgressBar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const WebDevelop = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const {t}=useTranslation()
  return (
    <>
      <Helmet>
        <title>
          Get Your Custom-Made Website: Expert Web Development | The TechHorse
        </title>
        <meta
          name="description"
          content=" Elevate your online presence with our expert web development services tailored to your brand. Get the edge with a custom-made website from The TechHorse."
        />
      </Helmet>
      <Header />
      <section className="page-banner-area pt-245 rpt-150 pb-170 rpb-100 rel z-1 bgc-lighter text-center">
        <div className="container">
          <div className="banner-inner rpt-10">
            <h1 className="page-title wow fadeInUp delay-0-2s">
              {t("webBannerH")}<span>{t("webBannerSpan")}</span>
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <a>{t("webhome")}</a>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  style={{ color: "#fff" }}
                >
                  {t("webTitle")}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="banner-shapes">
          <div
            className="circle webcircle wow zoomInLeft delay-0-2s"
            data-wow-duration="2s"
          />
          <img
            className="shape-one"
            src="/images/shapes/hero-shape1.png"
            alt="Shape"
            loading="lazy"
          />
          <img
            className="shape-two"
            src="/images/shapes/hero-shape2.png"
            alt="Shape"
            loading="lazy"
          />
        </div>
      </section>
      <section className="what-we-offer my-130 rel z-1">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-5 col-lg-6">
              <div className="what-we-offer-left mb-30 rmb-65 wow fadeInRight delay-0-2s">
                <div className="section-title mb-25">
                  <span
                    className="sub-title style-two mb-15"
                    style={{ color: "#76ada0" }}
                  >
                    {t("webWhat")}
                  </span>
                  <h2>{t("webWhatH")}</h2>
                </div>
                <p>
                {t("webWhatSpan")}
                </p>
                <Link to="/contact-us">
                  <a className="theme-btn style-two webLearnbtn mt-15">
                  {t("learnMoreBtn")} <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="what-we-offer-right wow fadeInLeft delay-0-2s">
                <h2 style={{ color: "#010B40" }}>
                {t("webTailor")}
                </h2>
                <p>
                {t("webTailorPara")}
                </p>
                <p>{t("webOurExpertise")}</p>
                <ul className="list-style-four webli pt-5">
                  <li>
                    <p className="mb-0">
                      <b>{t("webListT1")} </b>{t("webListT1Para")}
                    </p>
                  </li>
                  <li>
                    <p className="mb-0">
                    <b>{t("webListT2")} </b>{t("webListTPara")}
                    </p>
                  </li>
                  <li>
                    <p className="mb-0">
                    <b>{t("webListT3")} </b>{t("webListT3Para")}
                      </p>
                  </li>
                  <li>
                    <p className="mb-0">
                    <b>{t("webListT4")} </b>{t("webListT4Para")}
                    </p>
                  </li>
                  <li>
                    <p className="mb-0">
                    <b>{t("webListT5")} </b>{t("webListT5Para")}
                    </p>
                  </li>
                  <li>
                    <p className="mb-0">
                    <b>{t("webListT6")} </b>{t("webListT6Para")}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* What We Offer Area end */}
      {/* Features Area start */}
      <section className="features-area-seven pb-100 rel z-1">
        <div className="container">
          <div className="row row-cols-xl-5 row-cols-md-3 row-cols-sm-2 row-cols-1 justify-content-center">
            <div className="col">
              <div className="feature-item-seven mt-30 wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="flaticon-idea" />
                </div>
                <h5>{t("strategyH")}</h5>
                <p>
                {t("strategyPara")}
                </p>
              </div>
            </div>
            <div className="col">
              <div className="feature-item-seven color-two wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="flaticon-graphic-design" />
                </div>
                <h5>{t("webdegH")}</h5>
                <p>
                {t("webdegPara")}
                </p>
              </div>
            </div>
            <div className="col">
              <div className="feature-item-seven color-three mt-40 wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="flaticon-coding-2" />
                </div>
                <h5>{t("webDevH")}</h5>
                <p>
                {t("webDevPara")}
                </p>
              </div>
            </div>
            <div className="col">
              <div className="feature-item-seven color-four wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="flaticon-checklist" />
                </div>
                <h5>{t("projTestH")}</h5>
                <p>
                {t("projTestPara")}
                </p>
              </div>
            </div>
            <div className="col">
              <div className="feature-item-seven color-five mt-40 wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="flaticon-goal" />
                </div>
                <h5>{t("projLaunchH")}</h5>
                <p>
                {t("projLaunchPara")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Features Area end */}
      {/* What We Offer Two Area start */}
      <section className="what-we-offer-two mb-130 rel z-1">
        <div className="container">
          <div className="row large-gap align-items-center">
            <div className="col-lg-6">
              <div className="what-we-offer-image rmb-65 wow fadeInRight delay-0-2s">
                <img
                  src="/assets/customize web.webp"
                  alt="What We Offer"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="what-we-offer-content wow fadeInLeft delay-0-2s">
                <div className="section-title mb-25">
                  <span
                    className="sub-title style-two mb-15"
                    style={{ color: "#76ada0" }}
                  >
                    {t("webWhat")}
                  </span>
                  <h2>{t("customwebSolH")}</h2>
                </div>
                <p>
                {t("customwebSolPara")}</p>
                <ul className="list-style-four webli pt-5 pb-30">
                  <li>{t("customwebli1")}</li>
                  <li>{t("customwebli2")}</li>
                  <li>{t("customwebli3")}</li>
                </ul>
                <Link to="/contact-us">
                  <a className="theme-btn webLearnbtn style-two mt-15">
                    {t("learnMoreBtn")} <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* What We Offer Two Area end */}
      <section className="features-area pb-100 rel z-1">
        <div className="container">
          <div className="section-title text-center mb-50">
            <span className="sub-title mb-15" style={{ color: "#76ada0" }}>
              {t("webTechFeatureH")}
            </span>
            <h2>{t("webTechFeatureSub")}</h2>
          </div>
          <div className="row row-cols-xl-7 row-cols-lg-5 row-cols-md-4 row-cols-sm-3 row-cols-2 justify-content-center">
            <div className="col">
              <div className="feature-item wow fadeInUp delay-0-2s">
                <div className="image">
                  <img
                    src="/images/features/logo1.png"
                    alt="Logo"
                    loading="lazy"
                  />
                </div>
                <h5>Bootstrap</h5>
              </div>
            </div>
            <div className="col">
              <div className="feature-item wow fadeInDown delay-0-2s">
                <div className="image">
                  <img
                    src="/images/features/logo2.png"
                    alt="Logo"
                    loading="lazy"
                  />
                </div>
                <h5>HTML</h5>
              </div>
            </div>
            <div className="col">
              <div className="feature-item wow fadeInUp delay-0-2s">
                <div className="image">
                  <img
                    src="/images/features/logo3.png"
                    alt="Logo"
                    loading="lazy"
                  />
                </div>
                <h5>CSS</h5>
              </div>
            </div>
            <div className="col">
              <div className="feature-item wow fadeInDown delay-0-2s">
                <div className="image">
                  <img
                    src="/images/features/logo4.png"
                    alt="Logo"
                    loading="lazy"
                  />
                </div>
                <h5>JS</h5>
              </div>
            </div>
            <div className="col">
              <div className="feature-item wow fadeInUp delay-0-2s">
                <div className="image">
                  <img
                    src="/images/features/logo5.png"
                    alt="Logo"
                    loading="lazy"
                  />
                </div>
                <h5>Angular</h5>
              </div>
            </div>
            <div className="col">
              <div className="feature-item wow fadeInDown delay-0-2s">
                <div className="image">
                  <img
                    src="/images/features/logo6.png"
                    alt="Logo"
                    loading="lazy"
                  />
                </div>
                <h5>React JS</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Responsive Design Area start */}
      <section
        className="responsive-design-area mb-130 rel z-1"
        style={{ overflow: "hidden" }}
      >
        <div className="container">
          <div className="row large-gap align-items-center">
            <div className="col-lg-6">
              <div className="responsive-design-content rmb-65 wow fadeInLeft delay-0-2s">
                <div className="section-title mb-25">
                  <span
                    className="sub-title style-two mb-15"
                    style={{ color: "#76ada0" }}
                  >
                    {t("webResH")}
                  </span>
                  <h2>{t("webResSub")}</h2>
                </div>
                <p>
                {t("webResPara")}</p>
                <ul className="list-style-four webli pt-5 pb-30">
                  <li>{t("webResli1")}</li>
                  <li>{t("webResli2")}</li>
                  <li>{t("webResli3")}</li>
                </ul>
                <Link to="/contact-us">
                  <a className="theme-btn webLearnbtn style-two mt-15">
                  {t("learnMoreBtn")} <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="responsive-design-image wow fadeInRight delay-0-2s">
                <img
                  src="/images/about/web2.png"
                  alt="Responsive Design"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Responsive Design Area end */}
      {/* CTA Two Area start */}
      <section
        className="cta-two  bgc-lighter bgs-cover text-white py-75 rel z-1"
        style={{ backgroundImage: "url(/images/background/cta-two.png)" }}
      >
        <div className="container">
          <div className="row large-gap align-items-center">
            <div className="col-lg-5">
              <div className="cta-two-image rmb-65 wow fadeInRight delay-0-2s">
                <img src="/assets/seo.webp" alt="CTA" loading="lazy" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="cta-two-content-two wow fadeInLeft delay-0-2s">
                <div className="section-title mb-25">
                  <h2>
                  {t("webPostH")}
                  </h2>
                </div>
                <p>
                {t("webPostPara")}
                </p>
                <Link to="/contact-us">
                  <a className="theme-btn webBoxlearnCall style-two white-btn mt-15">
                  {t("createwebBtn")}{" "}
                    <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CTA Two Area end */}
      {/* Support & Marketing Area start */}
      <section className="support-marketing-area py-130 rel z-1">
        <div className="container">
          <div className="row large-gap justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="support-marketing-progress rmb-65 wow fadeInUp delay-0-4s">
                <div className="section-title mb-35">
                  <span
                    className="sub-title style-two mb-15"
                    style={{ color: "#76ada0" }}
                  >
                    {t("webSupportMarkH")}
                  </span>
                  <h2>{t("webSupportMarkSub")}</h2>
                </div>
                <p>
                {t("webSupportMarkPara")}
                </p>
                <div className="circle-counter">
                  <div className="circle-progress-item">
                    <ProgressBar value={89} color="#3180fc" />
                    <h5>{t("webSeo")}</h5>
                  </div>
                  <div className="circle-progress-item">
                    <ProgressBar value={76} color="#f1b000" />
                    <h5>{t("webcopywrite")}</h5>
                  </div>
                  <div className="circle-progress-item">
                    <ProgressBar value={63} color="#16b4f2" />
                    <h5>{t("webppc")}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="support-marketing-list wow fadeInUp delay-0-2s">
                <ul className="list-style-one">
                  <li>
                    <div className="content">
                      <h5>{t("webseoH")}</h5>
                      <p>
                      {t("webseoPara")}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <h5>{t("webcopyH")}</h5>
                      <p>
                      {t("webcopyPara")}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <h5>{t("webppcH")}</h5>
                      <p>
                      {t("webpccPara")}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Support & Marketing Area start */}

      <section className="call-to-action-area bgc-lighter  bgc-black mt-100 pt-80 pb-50">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-white mb-25 wow fadeInUp delay-0-2s">
                <h2>{t("webcraftH")}</h2>
                <p>
                {t("webcraftPara")}
                  </p>
              </div>
            </div>
            <div className="col-lg-3 text-lg-end">
              <Link to="/contact-us">
                <a className="theme-btn webBoxlearnCall style-two mb-30 wow fadeInUp delay-0-4s">
                {t("webletstartBtn")} <i className="fas fa-angle-double-right" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default WebDevelop;
