import React, { useState } from "react";
import "../Main.css";
import "../css/fontawesome-5.14.0.min.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import { Flag, Segment } from "semantic-ui-react";

type LanguageOption = {
  value: string;
  label: JSX.Element;
};

const Menu = () => {
  const [isHover, setIsHover] = useState({
    home: false,
    services: false,
    project: false,
    pages: false,
    shop: false,
    blog: false,
    multiplepage: false,
    onepage: false,
    team: false,
  });
  const handleMouseEnter = (item: any) => {
    setIsHover((prevState) => ({
      ...prevState,
      [item]: true,
    }));
  };

  const handleMouseLeave = (item: any) => {
    setIsHover((prevState) => ({
      ...prevState,
      [item]: false,
    }));
  };

  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const languages: LanguageOption[] = [
    {
      value: "en",
      label: (
        <div style={{ display: "flex", alignItems: "center", color: "#000" }}>
          <Flag name="vg" /> En
        </div>
      ),
    },
    {
      value: "az",
      label: (
        <div style={{ display: "flex", alignItems: "center", color: "#000" }}>
          <Flag name="az" /> Az
        </div>
      ),
    },
    {
      value: "ar",
      label: (
        <div style={{ display: "flex", alignItems: "center", color: "#fff" }}>
          <Flag name="ae" /> Ae
        </div>
      ),
    },
    {
      value: "ru",
      label: (
        <div style={{ display: "flex", alignItems: "center", color: "#fff" }}>
          <Flag name="ru" /> Ru
        </div>
      ),
    },
  ];

  const [activeLang, setActiveLang] = useState<string>("en");

  const handleLangChange = (selectedOption: any) => {
    setActiveLang(selectedOption.value);
    i18n.changeLanguage(selectedOption.value);
  };
  return (
    <>
      <ul className="navigation clearfix">
        <li
          className="dropdown"
          onMouseEnter={() => handleMouseEnter("home")}
          onMouseLeave={() => handleMouseLeave("home")}
        >
          <Link to="/">{t("navHome")}</Link>
        </li>
        <li
          className="dropdown"
          onMouseEnter={() => handleMouseEnter("services")}
          onMouseLeave={() => handleMouseLeave("services")}
        >
          <a href="#">{t("navServices")}</a>
          <ul style={{ display: `${isHover.services ? "block" : "none"}` }}>
            <li>
              <Link to="/web-development">{t("navServicesSub1")}</Link>
            </li>
            <li>
              <Link to="/mobile-app-development">{t("navServicesSub2")}</Link>
            </li>
            <li>
              <Link to="/software-development">{t("navServicesSub3")}</Link>
            </li>
            <li>
              <Link to="/dev-ops">{t("navServicesSub4")}</Link>
            </li>
            <li>
              <Link to="/cloud-computing">{t("navServicesSub5")}</Link>
            </li>
            <li>
              <Link to="/digital-marketing">{t("navServicesSub6")}</Link>
            </li>
          </ul>
          <div className="dropdown-btn">
            <span className="fas fa-chevron-down" />
          </div>
        </li>
        <li
          className="dropdown"
          onMouseEnter={() => handleMouseEnter("project")}
          onMouseLeave={() => handleMouseLeave("project")}
        >
          <Link to="/projects">{t("navProject")}</Link>
        </li>
        <li
          className="dropdown"
          onMouseEnter={() => handleMouseEnter("pages")}
          onMouseLeave={() => handleMouseLeave("pages")}
        >
          <Link to="/about-us">{t("navAbout")}</Link>
        </li>
        <li>
          <Link to="/contact-us">{t("navContact")}</Link>
        </li>
        <div className="d-flex justify-content-center align-items-center">
          <Select
            value={languages.find((lang) => lang.value === activeLang)}
            onChange={handleLangChange}
            options={languages}
            isSearchable={false}
          />
        </div>
      </ul>
    </>
  );
};

export default Menu;
