import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "en",

    resources: {
      en: {
        translation: {
          //Footer Start
          footerpara:
            "We're a software development company that builds products for companies worldwide. We've helped them to build better products, improve their business processes, and grow their businesses.",
          quickLinkHead: "Quick Links",
          quickLink1: "Web Development",
          quickLink2: "Mobile App Development",
          quickLink3: "Software Development",
          quickLink4: "Cloud Computing",
          quickLink5: "Digital Marketing",
          quickLink6: "About company",
          quickLink7: "Projects",
          quickLink8: "DevOps",
          quickLink9: "Contact",
          newsLetterHead: "Newsletter",
          newspara: "Sing up to get more every updates",
          newsletterplaceHolder: "Enter email",
          newsletterBtn: "Subscribe Now",
          copyRights: "© Copyright 2024 The TechHorse. All right reserved",
          //Footer End

          //Home Start
          bannerSpan1: "20 Years of Experience in web design solutions",
          bannerH1: "Building Tomorrow’s Digital Solutions Today",
          getStartBtn: "Get Started Us",
          bannerimgSpan1: "Web Design",
          bannerSpan2: "20 Years of Experience in digital solutions",
          bannerH2: "Your Technology Partners in a Digital World",
          bannerimgSpan2: "Development",
          coreFeatureH: "Core Features",
          coreFeatureSub: "Services We Offer",
          coreCards1: "Responsive modern design",
          coreCards2: "Powerful Customization",
          coreCards3: "Cool & modern animations",
          coreCards4: "SEO Friendly Coding",
          coreCards5: "Best Technical supports",
          coreCards6: "Varied Layouts & parallax",
          AllaboutH: "All about The TechHorse",
          AllaboutSub: "Best Digital Agency Solutions to Growth",
          missionH: "Mission:",
          missionPara:
            "Our mission is to use advanced artificial intelligence technology to revolutionize industries, improve human experiences, and build a better future. Our skilled team, with deep roots in both classic and modern IT solutions and international business, is dedicated to delivering top-tier services globally. We aim to be the most reliable leader in enhancing human interactions through innovative technology and exceptional teamwork.",
          visionH: "Vision:",
          visionPara:
            "To be a leader in AI-driven IT solutions, promoting a culture of creativity, teamwork, and ongoing learning. We differentiate ourselves by our passion for developing effective solutions and supporting our clients’ growth every step of the way.",
          philosophyH: "Philosophy:",
          philosophyPara:
            "Our philosophy is to create AI solutions that boost human capabilities and improve user experiences. We prioritize ethical AI practices, data privacy, and environmental sustainability, all while staying at the cutting edge of AI research and innovation. Our commitment to excellence and teamwork underpins our company culture, influencing our decisions and driving the development of transformative AI solutions.",
          coredesignH: "Core Design Featured",
          corededignSub: "What we provider for Businesses",
          core1: "Web Design & Development",
          core2: "Mobile App Development",
          core3: "Software Development",
          core4: "DevOps",
          core5: "Digital Marketing",
          core6: "Cloud Computing",
          recentProjH: "Recent Projects",
          recentProjSub: "Look at latest works gallery",
          wishitonH: "WishItOn Web",
          speedpH: "Speed-P Web",
          wishitonMobH: "WishItOn Mobile App",
          wishitonResp: "Responsive Design",
          workingProcessH: "Working Process",
          workingProcessSub: "How does we works",
          infoGatherH: "Information Gathering",
          infoGatherPara:
            "We initiate every project by thoroughly understanding the client's needs, objectives, and expectations.",
          PlanningH: "Planning & Analysis",
          PlanningPara:
            "Then, we develop a structured plan to guide the project to completion within the agreed timeline and budget.In this critical phase, analyze the gathered information to identify potential challenges and opportunities.",
          developH: "Development",
          developPara:
            "Begin creating the website or app using best practices in coding and design.",
          testingH: "Testing & Launch",
          testingPara:
            "Conduct comprehensive testing to ensure the application or website performs well across all desired platforms and use cases. Address any issues found during the testing phase. Once testing is completed, we will proceed with the launch.",
          HaveH: "Have any Project On Minds ?",
          Weare: "We are to work SEO optimization ?",
          formH: "Have any project on mind! feel free contact with us or",
          formHSpan: "say hello",
          locationLabel: "Location",
          locationCon: "Chennai,India",
          emailLabel: "Email Address",
          emailCon: "info@thetechhorse.com",
          phoneLabel: "Phone No",
          formHead: "Send us Message",
          fullNameHolder: "Full Name",
          emailHolder: "Email Address",
          subjectHolder: "Subject",
          messageHolder: "Write Message",
          sendmesBtn: "Send Messages",
          //Home end

          //Web Start
          webBannerH: "Web Design & Develop",
          webBannerSpan: "ment",
          webhome: "Home",
          webTitle: "Web Design & Development",
          webWhat: "What We Offer",
          webWhatH: "Exquisite Web Solutions Crafted to Excel",
          webWhatSpan:
            "Harness the power of years of expertise with The TechHorse, your partner in pioneering website design and development. Elevate your digital presence with our bespoke services tailored to your business needs.",
          learnMoreBtn: "Learn More",
          webTailor: "Tailored Web Solutions that Think Ahead",
          webTailorPara:
            "At The TechHorse, every website is a masterpiece, meticulously crafted with your customer in mind. Beyond aesthetics, we delve into the nuances of user experience, SEO efficiency, and technical precision to build not just websites, but robust digital experiences.",
          webOurExpertise: "Our Expertise Includes :",
          webListT1: "Responsive Website Design:",
          webListT1Para: "Fluid designs that adapt seamlessly to any device.",
          webListT2: "User Experience Design:",
          webListT2Para: "Intuitive interfaces that engage and delight.",
          webListT3: "CMS and eCommerce Integration:",
          webListT3Para:
            "Streamlined solutions that enhance functionality and manageability.",
          webListT4: "Website Content Strategy:",
          webListT4Para:
            "Strategic content frameworks that drive engagement and conversion.",
          webListT5: "Cross-Browser and Platform Testing:",
          webListT5Para:
            "Ensuring consistent performance across all user touchpoints.",
          webListT6: "Usability and Competition Analysis:",
          webListT6Para:
            "Insights that keep you ahead in a competitive landscape.",
          strategyH: "Strategy Creation",
          strategyPara:
            "Crafting your blueprint for success with thorough market research and strategic alignment.",
          webdegH: "Website Design",
          webdegPara:
            "Conceptualizing distinctive designs that encapsulate your brand’s essence.",
          webDevH: "Development",
          webDevPara:
            "Engineering your site with cutting-edge technology for optimal functionality.",
          projTestH: "Project Testing",
          projTestPara:
            "Rigorous testing by our experts to ensure flawless performance.",
          projLaunchH: "Project Launch",
          projLaunchPara:
            "Launching your site with precision to make a powerful market impact.",
          customwebSolH: "Custom Web Solutions",
          customwebSolPara:
            "Elevate your digital presence with uniquely crafted websites that meet your business needs. Leveraging WordPress and advanced HTML and CSS, we ensure your site is both easy to manage and optimally designed for search engines.",
          customwebli1: "Easy to edit & Search engine friendly",
          customwebli2: "Highly customizable",
          customwebli3: "Most popular CMS in the world",
          webTechFeatureH: "Technology Features",
          webTechFeatureSub: "Full Potential Modern Features",
          webResH: "Responsive Design",
          webResSub: "Optimized Web Design for Every Device",
          webResPara:
            "Our responsive design ensures your website delivers an exceptional user experience on every device. Your site will offer seamless functionality and aesthetic consistency, whether accessed via desktop, laptop, tablet, or smartphone.",
          webResli1: "Mobile-Friendly Interfaces",
          webResli2: "Universal Compatibility",
          webResli3: "Enhanced User Experience & SEO",
          webPostH:
            "We Will Do Free SEO Optimization on Your Website for Organic Traffic",
          webPostPara:
            "Organic traffic, driven by effective SEO, can lead to a 40% increase in revenue, as users trust organically ranked sites more than ads. Additionally, websites with strong organic presence see a customer retention rate 5 times higher than those relying solely on paid traffic.",
          createwebBtn: "Create Your Website",
          webSupportMarkH: "Support & Marketing",
          webSupportMarkSub: "Marketing to Support Your Web Design",
          webSupportMarkPara:
            "Fortunately, we aren’t just designers and developers here—we are writers, strategists, techs and creatives, all working towards the same end goal: our client’s success. As a full-service digital marketing agency",
          webSeo: "SEO Service",
          webcopywrite: "Copywriting",
          webppc: "PPC",
          webseoH: "SEO Services",
          webseoPara:
            "If you're looking to command market your online you need comprehensive SEO strategy",
          webcopyH: "Copywriting",
          webcopyPara:
            "Amplify your brand and control the conversation with a strategic content marketing strategy",
          webppcH: "Pay per click",
          webpccPara:
            "PPC management is all about delivering the right ad to your future customers at the exact",
          webcraftH: "Craft Your Vision with Precision",
          webcraftPara:
            "Are you ready to transform your digital presence? At The Tech Horser, we specialize in creating websites that stand out and resonate deeply with your audience. Let's discuss how we can bring your vision to life. Click below to get started.",
          webletstartBtn: "Let's Get Started",
          //Web End

          //Mobile Start
          mobileBannerH: "Mobile App Develop",
          mobileBannerSpan: "ment",
          mobilehome: "Home",
          mobileTitle: "Mobile App Development",
          mobilewhatH: "What We Offer",
          mobileWhatSUb: "Excellence in App Design & Development",
          mobileWhatPara:
            "Harnessing 20 years of pioneering expertise to craft exceptional digital experiences.",
          mobilerightPara:
            "Modern app development transcends beyond building functional apps. It's about creating an engaging user experience, optimizing performance, and incorporating cutting-edge technologies. Here’s what we specialize in:",
          mobileRightList1: "Responsive App Design",
          mobileRightList2: "User Experience Innovation",
          mobileRightList3: "App Integration Solutions",
          mobileRightList4: "App Content Strategy",
          mobileRightList5: "Comprehensive Testing",
          mobileRightList6: "Market and Usability Analysis",
          mobilestrategyH: "Strategy Formulation",
          mobilestrategyPara:
            "Crafting precise strategies to position your app for market success.",
          mobileappDesignH: "App Design",
          mobileappDesignPara:
            "Developing visually appealing and user-friendly design concepts.",
          mobileAppdevH: "App Development",
          mobileAppdevPara:
            "Creating robust and scalable app solutions with precision.",
          mobileApptestH: "App Testing",
          mobileApptestPara:
            "Ensuring peak performance through rigorous testing protocols.",
          mobileAppLaunchH: "App Launch",
          mobileAppLaunchPara:
            "Strategically deploying your app for optimal market penetration.",
          mobileflutterH:
            "Flutter , React native , Kotlin and Swift mobile app development",
          mobileflutterPara:
            "Flutter and React Native are frameworks for cross-platform mobile development, Kotlin and Swift are programming languages primarily used for Android and iOS development . Each has its strengths , use cases, and platform-specific features",
          mobileflutterList1: "Cross platform",
          mobileflutterList2: "High scale development",
          mobileflutterList3: "Efficiency",
          mobileflutterList4: "Conciseness",
          mobileResH: "Responsive Design",
          mobileResSub: "Design For Any Device Responsive App Design",
          mobileResPara:
            "Responsive app design allows your app to adapt to and provide an optimal experience on any device it is viewed. This means that your application will look great and function flawlessly for a user on any smartphone device.",
          mobileReslist1: "Mobile-friendly",
          mobileReslist2: "Design for every device",
          mobileReslist3: "Positive UX & Helps SEO",
          mobileBoostH:
            "Boost Your App's Visibility—Free SEO Services Included",
          mobileBoostPara:
            "Launch your app with a competitive edge. We offer exclusive, complimentary SEO services for your app on the Google Play Store and Apple App Store, ensuring it reaches its audience and ranks prominently.",
          mobileCreateAppBtn: "Create Your App",
          mobileSeoH: "Support & Marketing",
          mobileSEOSub: "Marketing to Support Your App Design",
          mobileSeoPara:
            "Fortunately, we aren’t just designers and developers here—we are writers, strategists, techs and creatives, all working towards the same end goal: our client’s success. As a full-service digital marketing agency",
          mobileSeoSerH: "SEO Service",
          mobileSeoCopyH: "Copywriting",
          mobilePPCH: "PPC",
          mobileSEOSERH: "SEO Services",
          mobileSEOSERPara:
            "If you're looking to command market your online you need comprehensive SEO strategy",
          mobileSeoCopy: "Copywriting",
          mobileSeoCopyPara:
            "Amplify your brand and control the conversation with a strategic content marketing strategy",
          mobileSeoPPC: "Pay per click",
          mobileSeoPPCPara:
            "PPC management is all about delivering the right ad to your future customers at the exact",
          mobilenextappHead: "Let’s Create Your Next App",
          mobilenextappPara:
            "Ready for an app that stands out and dominates the market? We're here to bring your vision to life. Click below to start transforming your ideas into digital success.",
          mobileLanuchBtn: "Launch Your Vision",
          //Mobile End

          //Software Start
          softBannerH: "Software Develop",
          softBannerSpan: "ment",
          softBannerHome: "Home",
          softBannerHomeTitle: "Software Development",
          softCompanyH: "Company Statistics",
          softCompanySub:
            "Empower Your Vision with Pioneering Software Solutions",
          softCompanyPara:
            "Transform your ideas into leading-edge software solutions. Our expert-driven development services promise seamless execution and superior results, readying you for success—no technical background needed.",
          softWorkH: "Working Process",
          softWorkSub: "How does we works",
          softwork1H: "Understand the Vision",
          softwork1Para:
            "We start by thoroughly understanding your goals and requirements, ensuring a solid foundation for your project",
          softwork2H: "Blueprint Your Success",
          softwork2Para:
            "Our strategists and developers collaborate to craft a detailed roadmap that aligns with your objectives",
          softwork3H: "Refine the Concept",
          softwork3Para:
            "We analyze and refine the design to meet your expectations and our high standards",
          softwork4H: "Execute with Excellence",
          softwork4Para:
            "After rigorous testing for flawless performance, your software is launched, ready to make an impact",
          softharnessH:
            "Harness Cutting-Edge Software to Propel Your Business Forward",
          softharnessPara:
            "Leverage our expertise to navigate your software development journey with precision. Our team excels in crafting strategies tailored to your unique needs, guiding you to achieve and surpass your business objectives.",
          softHarnessschBtn: "Schedule A Free Consultation",
          softHarright1H: "Drive Smarter Decisions",
          softHarright1Para:
            "Harness the power of Big Data to enhance decision-making and propel your business forward with actionable insights.",
          softHarright2H: "Future-Proof Your Business",
          softHarright2Para:
            "Adapt and thrive in an evolving marketplace with our comprehensive digital transformation strategies.",
          softHarright3H: "Streamline and Scale",
          softHarright3Para:
            "Accelerate your development cycle and boost efficiency with our robust cloud-based solutions.",
          softHarright4H: "Custom Solutions, Exceptional Results",
          softHarright4Para:
            "Create impactful and scalable web or mobile applications tailored to meet your business needs for exceptional user experiences.",
          softCurH1: "Curious whether",
          softCurSpan: "The TechHorse",
          softCurH2: "is the right fit for your project?",
          softCurSub: "Seeking Premier Software Development Services?",
          softCurPara1:
            "Let's understand your vision in detail. After reviewing your requirements, we can schedule a no-obligation free consultation to outline the scope and provide an estimated strategy to ensure your success.",
          softCurPara2:
            "Each project is unique, and so is our approach. That's why we invite you to a complimentary consultation with one of our seasoned software developers. This session is designed to tailor our strategies to your needs and show you exactly how The Tech Horser can bring your project to fruition.",
          softFormH: "Send us Message",
          softFullName: "Full Name",
          softemail: "Email Address",
          softSubject: "Subject",
          softMeg: "Write Message",
          softSendBtn: "Send Messages",
          softLetsH: "Let’s Design Your New Software",
          softLetsPara:
            "Do you want to have a software that stands out and impresses your clients? Then we are ready to help! Click the button below to contact us and discuss your ideas.",
          softLetsBtn: "Let’s Get Started",
          //Software end

          //Devops start
          devopsBannerH: "DevOps",
          devopsHome: "Home",
          devopsHomeNav: "DevOps",
          devopsYRSEXP: "Years of experience in DevOps solution",
          devopsSolH: "DevOps Solutions",
          devopsSolSub: "Elevate Your Operations with Elite DevOps Engineering",
          devopsSolPara:
            "At The TechHorse, we engineer excellence. Our DevOps services are designed to streamline your software delivery process, ensuring robust scalability and ironclad security for enterprises of every size. Partner with us to transform your operations with precision and efficiency.",
          devopsCliSta: "Clients Satisfactions",
          devopsMissH: "Mission and Vision",
          devopsMissSub: "We aim to deliver quality creative solution",
          devopsMissPara:
            "At The TechHorse, we craft uniquely innovative web solutions that set industry standards. Our team is at the forefront of technological advancement, embracing and integrating the latest innovations to elevate your digital presence.",
          devopsSerH: "Our Popular Services",
          devopsSerSub: "Amazing DevOps Service",
          devopsCloud1H: "Cloud Migration",
          devopsCloud1Para:
            "Focus provide beautiful layout client look make import",
          devopsCloud2H: "Microservices",
          devopsCloud2Para:
            "Focus provide beautiful layout client look make import",
          devopsCloud3H: "Monitoring and Logging",
          devopsCloud3Para:
            "Focus provide beautiful layout client look make import",
          devopsCloud4H: "Continuous Delivery",
          devopsCloud4Para:
            "Focus provide beautiful layout client look make import",
          devopswhyH: "Why Choose Us",
          devopswhySub: "DevOps Services You Can Rely On",
          devopslist1H: "Scalable Solutions",
          devopslist1Para:
            "Our DevOps strategies are designed for scalability, supporting growth without compromising performance. We ensure that your infrastructure evolves with your business needs.",
          devopslist2H: "Advanced Automation",
          devopslist2Para:
            "We prioritise automation to streamline your operations, from code deployment to infrastructure management, reducing errors and increasing efficiency.",
          devopslist3H:
            "Continuous Integration & Continuous Deployment (CI/CD)",
          devopslist3Para:
            "Embrace rapid delivery with our CI/CD solutions that ensure your software updates are seamless and secure, enabling faster time-to-market and robust service continuity.",
          devopslist4H: "Flexible Engagement Models",
          devopslist4Para:
            "With our client-centric engagement models, you can scale up or down based on project requirements, ensuring optimal control of your budgets and resources.",
          devopsSupportList1: "Continuous Integration",
          devopsSupportList2: "Configuration Management",
          devopsSupportList3: "Automated Testing",
          devopsSupportList4: "Collaboration Tools",
          devopsSupportList5: "Release Management",
          devopsSupportList6: "Version Control",
          devopsSupFeatureH: "Support & Features",
          devopsSupFeatureSub: "Amazing DevOps engineering features",
          devopsSupFeaturePara:
            "we offer comprehensive DevOps services enabling faster time-to-market, improved collaboration, and enhanced product quality. Our expert team leverages industry best practices and cutting-edge technologies to help you achieve your DevOps goals efficiently and effectively.",
          devopsLearnbtn: "Learn More About Us",
          devopsLifeH: "DevOps Lifecycle",
          devopslife1H: "Release",
          devopslife1Para:
            "Deploying new software or updates into production environments to ensure seamless availability for end-users. This crucial phase encompasses all necessary preparatory steps.",
          devopslife2H: "Deploy",
          devopslife2Para:
            "Installing software updates or new applications across production, staging, or testing environments to make new functionalities available for immediate use.",
          devopslife3H: "Operate",
          devopslife3Para:
            "Managing and maintaining software systems throughout their lifecycle. DevOps integrates development (Dev) and operations (Ops) to enhance collaboration and efficiency.",
          devopslife4H: "Monitor",
          devopslife4Para:
            "Continuous oversight of software performance and infrastructure to ensure optimal operation and reliability. Monitoring plays a vital role in proactive improvement and system integrity.",
          devopslife5H: "Project Launch",
          devopslife5Para:
            "Launching your site with precision to make a powerful market impact.",
          devopsEleH: "Elevate Your Operations with Expert DevOps",
          devopsElePara:
            "Do you need a seamless, efficient, secure DevOps strategy aligning with your business goals? We're ready to engineer your success. Click the button below to start optimising your operations with our expert team.",
          devopsEleBtn: "Let’s Get Started",
          //Devops End

          //Cloud Start
          cloudBannerH: "Cloud",
          cloudBannerSpan: "Computing",
          cloudBannerHome: "Home",
          cloudBannerHomeSpan: "Cloud Computing",
          cloudCompH: "Company Statistics",
          cloudCompSub:
            "Unleash Superior Performance with Elite Cloud Solutions",
          cloudCompPara:
            "Elevate your business with The TechHorse’s high-performance cloud computing. Our sophisticated network offers seamless integration of servers, databases, software, and more.",
          cloudSerH: "Our Services",
          cloudSerSub: "Amazing Cloud Computing Service",
          cloudSerli1H: "On-Demand Self Service",
          cloudSerli1Para:
            "This enables the client to continuously monitor server uptime, capabilities and allocated network storage.",
          cloudSerli2H: "Measured and Reporting Service",
          cloudSerli2Para:
            "It helps in monitoring billing and ensuring optimum utilization of resources.",
          cloudSerli3H: "Big Data Analysis",
          cloudSerli3Para:
            "Big data analytics refers to the methods and applications used to collect, process, and derive insights from varied, high-volume, high-velocity data sets.",
          cloudSerli4H: "Data Security & Scalability",
          cloudSerli4Para:
            "Many customers have workloads that can be run very cost-effectively due to the rapid scalability of cloud computing.",
          cloudwhyH: "Why Choose Us",
          cloudwhySub: "Exceptional Cloud Solutions You Can Trust",
          cloudwhyLi1H: "Scalable Architecture",
          cloudwhyLi1Para:
            "Adapt and grow without limits with our scalable cloud infrastructure that supports your business at every stage.",
          cloudwhyLi2H: "Secure and Reliable",
          cloudwhyLi2Para:
            "Protect your data with our top-tier security protocols and enjoy consistent uptime with our reliable cloud services.",
          cloudwhyLi3H: "Effortless Integration",
          cloudwhyLi3Para:
            "Seamlessly integrate our cloud solutions with your existing platforms for a smooth, efficient, and cohesive operation.",
          cloudEveH: "Elevate Your Digital Presence with Our Cloud Solutions",
          cloudEvePara:
            "Transform your digital strategy with our scalable, robust cloud infrastructure. Click below to start innovating.",
          cloudLetsbtn: "Let’s Get Started",
          //Cloud end

          //Digital Start
          digiBannerH: "Digital",
          digiBannerSpan: "Marketing",
          digiBannerHome: "Home",
          digiBannerHomeNav: "Digital Marketing",
          digiWhatH: "What We Do",
          digiWhatSub: "Elevate Your Brand with Expert Digital Marketing",
          digiseoLi1H: "SEO Services",
          digiseoLi1Para:
            "Maximise your visibility and rank top with our strategic SEO solutions designed for sustained growth.",
          digiseoLi2H: "Social Media Marketing",
          digiseoLi2Para:
            "Engage and expand your audience with customised social media campaigns that resonate and convert.",
          digiseoLi3H: "Google Ads",
          digiseoLi3Para:
            "Drive targeted traffic and achieve immediate results with expertly managed Google Ads campaigns.",
          digiseoLi4H: "Custom Website Design",
          digiseoLi4Para:
            "Craft a unique online presence with bespoke website designs that captivate and convert visitors.",
          digiseoLi5H: "Shopify Website Design",
          digiseoLi5Para:
            "Launch a stunning, sales-optimized Shopify store tailored to your brand’s needs.",
          digiseoLi6H: "Lead Generation",
          digiseoLi6Para:
            "Generate high-quality leads with strategies optimised for conversion and customer acquisition.",
          digiseoLi7H: "Content Marketing",
          digiseoLi7Para:
            "Position your brand as an industry leader with compelling content that informs and inspires.",
          digiseoLi8H: "Digital PR",
          digiseoLi8Para:
            "Enhance your reputation and reach with our comprehensive digital PR services that build and protect your brand.",
          digiseoLi9H: "Branding",
          digiseoLi9Para:
            "Create a memorable brand identity with our creative branding solutions that express your business’s ethos and values.",
          digiSata1H: "Strategically Crafted Campaigns to Enhance Visibility",
          digiSata1Para:
            "Unlock your brand's potential with cutting-edge digital marketing strategies tailored to increase your online presence and drive impactful results.",
          digiSata2H: "Drive Growth with Targeted Digital Advertising",
          digiSata2Para:
            "Leverage the power of precision-targeted Google Ads and content marketing to reach your ideal customers where they are most active and receptive.",
          digiSata3H:
            "Cultivate Your Brand with Comprehensive Digital Services",
          digiSata3Para:
            "From initial branding to digital PR, create a cohesive identity that speaks to your values and resonates with your target audience.",
          digiCoreH: "Core Features",
          digiCoreSub: "Amazing Core Features",
          digicore1H: "Data-Driven Strategy",
          digicore1Para:
            "Tailor your marketing efforts precisely using our analytics and data-driven approaches that ensure maximum ROI and effectiveness.",
          digicore2H: "Omnichannel Campaigns",
          digicore2Para:
            "Seamlessly integrate your marketing across all digital platforms, ensuring a unified brand experience that captivates and converts.",
          digicore3H: "Creative Content Creation",
          digicore3Para:
            "Leverage our innovative content services to craft compelling narratives that resonate with your audience and encourage engagement.",
          digicore4H: "Advanced SEO Techniques",
          digicore4Para:
            "Elevate your search engine presence with our cutting-edge SEO tactics to drive traffic and increase conversions.",
          digicore5H: "Targeted Advertising",
          digicore5Para:
            "Utilise sophisticated ad targeting to reach potential customers more effectively, enhancing lead generation and sales.",
          digicore6H: "Comprehensive Reporting",
          digicore6Para:
            "Stay informed with detailed reports that track campaign progress and metrics, enabling informed decisions and strategic adjustments.",
          digiChooseH: "Why Choose Us",
          digiChooseSub: "Reliable Digital Marketing Expertise",
          digiChoose1H: "Proven Results",
          digiChoose1Para:
            "Our track record speaks for itself. We deliver measurable improvements in traffic, engagement, and conversions, driving real business growth.",
          digiChoose2H: "Comprehensive Analytics",
          digiChoose2Para:
            "With our detailed analytics, you can gain insights like never before. They will help you understand your audience and optimise your marketing strategies effectively.",
          digiChoose3H: "Innovative Solutions",
          digiChoose3Para:
            "We stay ahead of the curve, implementing the latest digital marketing trends and technologies to keep your brand relevant and competitive.",
          digiunlockH:
            "Unlock Your Digital Potential: Free Consultation Available",
          digiunlockPara:
            "Ready to transform your online presence? Start with a free consultation! Click below to discuss your vision and discover tailored digital marketing strategies that make your website stand out and impress your clients.",
          digiunlockBtn: "Let’s Get Started",
          //Digital End

          //About Start
          abtBannerH: "About",
          abtBannerSpan: "Us",
          abtBannerHome: "Home",
          abtBannerHomeNav: "AboutUs",
          abtwhatH: "What We Do",
          abtwhatSub: "We are Professional IT Team",
          abtwhatPara:
            "We're a software development company that builds products for companies across the world. We've helped them to build better products, improve their business processes, and grow their businesses. We're different from other software development companies because we are passionate about what we do and how we do it. We love building products that can help our clients succeed and we love working with them every step of the way.",
          abtwhatLi1Para:
            "To be the world's best and most trusted human experience design company.",
          abtwhatLi2Para:
            "To enhance and elevate human interaction.To pursue excellence through dedicated teamwork.",
          abtcreateBtn: "Create Your Website",
          abtYearexp: "Years Of Experience",
          abtexpH:
            "20 years of experience in this world, being the first and foremost choice of business people.",
          abtexpLi1: "Web Development",
          abtexpLi2: "Software Development",
          abtexpLi3: "Cloud Computing",
          abtexpLi4: "Mobile App Development",
          abtexpLi5: "DevOps",
          abtexpLi6: "Digital Marketing",
          abtwebLi1H: "Website Design",
          abtwebLi1Para:
            "Conceptualizing distinctive designs that encapsulate your brand’s essence.",
          abtwebLi2H: "App Design",
          abtwebLi2Para:
            "Developing visually appealing and user-friendly design concepts.",
          abtwebLi3H: "Blueprint Your Success",
          abtwebLi3Para:
            "Our strategists and developers collaborate to craft a detailed roadmap that aligns with your objectives.",
          abtwebLi4H: "Optimize for search engines",
          abtwebLi4Para:
            "Maximise your visibility and rank top with our strategic SEO solutions designed for sustained growth.",
          abtLetH: "Let’s Design Your New Website",
          abtLetPara:
            "Do you want to have a website that stands out and impresses your clients? Then we are ready to help! Click the button below to contact us and discuss your ideas.",
          abtLetBtn: "Let’s Get Started",
          //About End

          //Contact start
          contactBannerH: "Contact",
          contactBannerHome: "Home",
          contactBannerHomeNav: "Contact",
          contactusH: "Contact Us",
          contactusSub:
            "Let’s Start New Project or work Together! Contact With us",
          contactusPara:
            "Contact us today to kickstart a new project or collaborate on existing initiatives. We're excited to hear from you!",
          contactfullnamelabel: "Full Name",
          contactfullnamePlace: "Enter Your Name",
          contactfullemaillabel: "Email Address",
          contactfullemailPlace: "Enter Your Email",
          contactfullphonelabel: "Phone Number",
          contactfullphonePlace: "Enter Your Phone Number",
          contactfullSubjectlabel: "Subject",
          contactfullSubjectPlace: "Subject",
          contactfullmeglabel: "Write Message",
          contactfullmegPlace: "Write Message",
          contactSendbtn: "Send Message",
          contactLocH: "LOCATION",
          contactLocPara: "Royapettah, Chennai, Tamil Nadu 600014",
          contactEmailH: "EMAIL ADDRESS",
          contactPhoneH: "PHONE NUMBER",
          contactFollowH: "Follow Us",
          contactUSAH: "USA",
          contactUSLOc: "Boca Raton, Fl 33428",
          contactAus: "Australia",
          contactAusLOc: " Melbourne Vic 3016",
          contactDub: "Dubai",
          contactDubLoc: "Al Rashidiya 3 7613",
          //Contact end

          //Project start
          projBannerH: "Few Of Our Prestigious Client's Project",
          projBannerHome: "Home",
          projBannerHomeNav: "Project",
          projwishCLientH: "Client",
          projwishCLientPara: "Wishiton",
          projwishDurH: "Duration",
          projwishDurPara: "4 Months",
          projwishDateH: "Date",
          projwishDatePara: "5 Nov 2023",
          projwishCateH: "Category",
          projwishCatePara: "Entertainment",
          prjKeyH: "Key Features",
          projWishLi1:
            "Signing up can be done with a phone number, gmail, and OTP verification for the mobile number.",
          projWishLi2: "Organizing birthday celebrations and send wishes.",
          projWishLi3:
            "Getting notified and reminding people before the date of the event.",
          projWishLi4:
            "Alexa voice assistant makes it easy to create events for upcoming birthdays.",
          projWishLi5: "Manage events with alexa voice assistant.",
          projWishLi6: "Send wishes through message, whatsapp and mail.",
          projWishLi7: "Send wishes along with exciting and custromized gifts.",
          projWishLi8: "Check gift shop availabilty using pincode.",
          projWishLi9: "Send wishes with your own custom videos.",
          projWishLi10:
            "Send invitation to group of people easily and in less time.",
          projWishLi11: "Get exciting offers using coupons.",
          projWishLi12: "Offer prize for all the products.",
          projWishLi13: "Pay easily through online by razorPay.",
          projDry0H: "Client",
          projDryoPara: "Dryo Laundry",
          projDryoDurH: "Duration",
          projDryoDurPara: "3 Months",
          projDryoDateH: "Date",
          projDryoDatePara: "In progress",
          projDryoCateH: "Category",
          projDryoCatePara: "Business Service",
          projDryoKeyLi1H: "Convenience:",
          projDryoKeyLi1Para:
            "Dryo brings convenience to the laundry process by allowing users to schedule laundry pickup and delivery at their preferred time and location. This eliminates the need for users to visit physical laundromats or spend time doing laundry themselves.",
          projDryoKeyLi2H: "Time-saving:",
          projDryoKeyLi2Para:
            "With Dryo, users can reclaim the time they would have spent on doing laundry and utilize it for other important tasks or leisure activities. This is especially beneficial for busy professionals, families, and individuals with hectic schedules.",
          projDryoKeyLi3H: "Customization:",
          projDryoKeyLi3Para:
            "The app offers customization options, allowing users to specify their laundry preferences such as detergent type, fabric softener, water temperature, and more. This ensures that each load of laundry is tailored to the user's preferences and needs.",
          projDryoKeyLi4H: "Quality service:",
          projDryoKeyLi4Para:
            "Dryo ensures high-quality laundry service by partnering with trusted and vetted laundry facilities. This guarantees that users receive clean and well-maintained laundry every time.",
          projDryoKeyLi5H: "Transparency:",
          projDryoKeyLi5Para:
            "The app provides transparency throughout the laundry process, allowing users to track the status of their laundry from pickup to delivery. This transparency instills trust and confidence in users regarding the safety and security of their clothes.",
          projDryoKeyLi6H: "Environmentally friendly:",
          projDryoKeyLi6Para:
            "Dryo promotes sustainability by offering eco-friendly laundry options, such as using environmentally safe detergents and energy-efficient washing machines. This appeals to environmentally conscious users who prioritize eco-friendly practices.",
          projDryoKeyLi7H: "Affordability:",
          projDryoKeyLi7Para:
            "Despite the convenience and quality of service, Dryo remains affordable, offering competitive pricing compared to traditional laundry services. This makes professional laundry service accessible to a wider range of users.",
          projDryoKeyLi8H: "User-friendly interface:",
          projDryoKeyLi8Para:
            "The app features a user-friendly interface that is easy to navigate, making the laundry booking process simple and intuitive for users of all ages and technological backgrounds.",
          projBaliH: "Client",
          projBaliPara: "Balitek",
          projBaliDurH: "Duration",
          projBaliDurPara: "2 Months",
          projBaliDateH: "Date",
          projBaliDatePara: "29 Feb 2024",
          projBaliCateH: "Category",
          projBaliCatePara: "IT Solutions",
          projBaliKeyLi1H: "Bussiness IT Consulting:",
          projBaliKeyLi1Para:
            "Leverage our strategic insights to align your IT infrastructure with your business objectives. Our consultants work collaboratively to optimize your technology strategy for maximum efficiency.",
          projBaliKeyLi2H: "Managed IT Services:",
          projBaliKeyLi2Para:
            "Entrust your IT operations to our experts. Our Managed IT Services ensure proactive monitoring, maintenance, and support, allowing you to focus on your core business functions.",
          projBaliKeyLi3H: "Cloud Solutions:",
          projBaliKeyLi3Para:
            "Embrace the flexibility and scalability of the cloud with our tailored solutions. From migration to optimization, we guide you through every step to harness the full potential of cloud technology.",
          projBaliKeyLi4H: "IT Security:",
          projBaliKeyLi4Para:
            "Safeguard your digital assets with our robust IT security measures. We employ cutting-edge technologies and industry best practices to protect your business from evolving cyber threats.",
          projBaliKeyLi5H: "Network Infrastructure Design and Implementation:",
          projBaliKeyLi5Para:
            "Build a robust and scalable network infrastructure tailored to your business needs. Our experts design and implement solutions that ensure seamless connectivity and optimal performance.",
          projBaliKeyLi6H: "Software Development:",
          projBaliKeyLi6Para:
            "Transform your ideas into reality with our custom software development services. We specialize in creating scalable, efficient, and user-friendly solutions to meet your unique requirements.",
          projBaliKeyLi7H: "Application Development:",
          projBaliKeyLi7Para:
            "Stay ahead in the digital landscape with our bespoke application development services. From mobile apps to enterprise solutions, we craft applications that drive innovation and enhance user experiences.",
          projBaliKeyLi8H: "Web Development:",
          projBaliKeyLi8Para:
            "Establish a strong online presence with our web development services. Our skilled developers create visually stunning and functional websites, ensuring a positive and engaging user experience.",
          projPayH: "Client",
          projPayPara: "Atlanta",
          projPayDUrH: "Duration",
          projPayDurPara: "6 Months",
          projPayDateH: "Date",
          projPayDatePara: "In Progress",
          projPayCateH: "Category",
          projPayCatePara: "Hr Payroll Management",
          projPayKeyLi1H: "Automated Calculations:",
          projPayKeyLi1Para:
            "Payroll applications automate complex calculations related to employee salaries, taxes, deductions, and benefits. This reduces the likelihood of human error and ensures accurate payment to employees.",
          projPayKeyLi2H: "Time Efficiency:",
          projPayKeyLi2Para:
            "By automating payroll processes, the application saves time for HR and finance departments, allowing them to focus on other strategic tasks rather than spending hours manually processing payroll each pay period.",
          projPayKeyLi3H: "Tax Compliance:",
          projPayKeyLi3Para:
            "Payroll applications often integrate tax tables and regulations, ensuring that payroll taxes are calculated accurately and that the company remains compliant with tax laws at all levels (local, state, federal).",
          projPayKeyLi4H: "Direct Deposit:",
          projPayKeyLi4Para:
            "Many payroll applications facilitate direct deposit, allowing employees to receive their salaries directly into their bank accounts. This eliminates the need for paper checks and reduces administrative overhead.",
          projPayKeyLi5H: "Employee Self-Service:",
          projPayKeyLi5Para:
            "Modern payroll applications often include self-service portals where employees can access their pay stubs, tax forms, and update personal information like address or bank account details, reducing the burden on HR staff.",
          projPayKeyLi6H: "Customizable Reporting:",
          projPayKeyLi6Para:
            "Payroll applications offer customizable reporting features, allowing businesses to generate various reports such as payroll summaries, tax reports, and employee earnings statements tailored to their specific needs.",
          projPayKeyLi7H: "Integration Capabilities:",
          projPayKeyLi7Para:
            "These applications can integrate with other systems such as accounting software, time and attendance systems, and human resource management systems, streamlining data flow and eliminating redundant data entry.",
          projPayKeyLi8H: "Comprehensive Benefits Management:",
          projPayKeyLi8Para:
            "Some payroll applications include features for managing employee benefits such as healthcare, retirement plans, and paid time off. This ensures that all aspects of employee compensation are handled efficiently.",
          projPayKeyLi9H: "Compliance Alerts:",
          projPayKeyLi9Para:
            "Payroll applications often include compliance alerts and reminders to notify administrators of upcoming deadlines, changes in regulations, or any actions required to maintain compliance with labor laws and regulations.",
          projPayKeyLi10H: "Scalability:",
          projPayKeyLi10Para:
            "Payroll applications are designed to accommodate the growth of businesses. Whether a company is small or large, the application can scale to handle an increasing number of employees and payroll complexities without sacrificing performance or efficiency.",
          projCabsH: "Client",
          projCabsPara: "Kerala Cabs",
          projCabsDurH: "Duration",
          projCabsDurPara: "On Going",
          projCabsDateH: "Date",
          projCabsDataPara: "In Progress",
          projCabsCateH: "Category",
          projCabsCatePara: "Taxi Service",
          projCabsKeyLi1H: "Regional Integration:",
          projCabsKeyLi1Para:
            "Tailored specifically for the Kerala region, ensuring seamless navigation through urban and rural areas alike.",
          projCabsKeyLi2H: "Customized Booking Options:",
          projCabsKeyLi2Para:
            "Provides various booking options such as point-to-point, hourly rentals, and outstation trips, accommodating the different travel needs of users.",
          projCabsKeyLi3H: "Driver Verification:",
          projCabsKeyLi3Para:
            "Ensures thorough background checks and verification of drivers to guarantee safety and reliability for passengers.",
          projCabsKeyLi4H: "Real-time Tracking:",
          projCabsKeyLi4Para:
            "Allows users to track their cab in real-time on the app, providing transparency and peace of mind throughout the journey.",
          projCabsKeyLi5H: "Driver Ratings and Reviews:",
          projCabsKeyLi5Para:
            "Enables passengers to rate and review drivers after the trip, fostering accountability and maintaining service quality standards.",
          projCabsKeyLi6H: "Integration with Local Attractions:",
          projCabsKeyLi6Para:
            "Offers recommendations and integrated booking services for local tourist attractions, enhancing the overall travel experience for visitors to Kerala.",
          projCabsKeyLi7H: "Fare Transparency:",
          projCabsKeyLi7Para:
            "Displays upfront fare estimates based on distance and time, ensuring transparency and avoiding surprises for passengers.",
          projCabsKeyLi8H: "Promotions and Discounts:",
          projCabsKeyLi8Para:
            "Provides regular promotions and discounts to users, making rides more affordable and encouraging customer loyalty.",
          projCabsKeyLi9H: "Cashless Payments:",
          projCabsKeyLi9Para:
            "Offers convenient cashless payment options such as credit/debit cards, digital wallets, and UPI, reducing the hassle of carrying cash and promoting contactless transactions.",
          projLogiH: "Client",
          projLogiPara: "Al Razaa Logistics",
          projLogiDurH: "Duration",
          projLogiDurPara: "2 Months",
          projLogiDateH: "Date",
          projLogiDatePara: "In Progress",
          projLogiCateH: "Category",
          projLogiCatePara: "Logistics",
          projLogiKeyLi1H: "User-Friendly Interface:",
          projLogiKeyLi1Para:
            "Easy-to-use platform for efficient shipment management.",
          projLogiKeyLi2H: "Quick Shipment Booking:",
          projLogiKeyLi2Para:
            "Streamlined process for fast and hassle-free booking.",
          projLogiKeyLi3H: "Real-Time Tracking:",
          projLogiKeyLi3Para:
            "Advanced tracking systems for live shipment monitoring.",
          projLogiKeyLi4H: "Customized Solutions:",
          projLogiKeyLi4Para:
            "Tailored logistics options for diverse business needs.",
          projLogiKeyLi5H: "Transparent Pricing",
          projLogiKeyLi5Para:
            "Clear pricing structures and upfront cost estimates.",
          projLogiKeyLi6H: "24/7 Customer Support:",
          projLogiKeyLi6Para:
            "Round-the-clock assistance for user inquiries and issues.",
          projLogiKeyLi7H: "Supply Chain Visibility:",
          projLogiKeyLi7Para:
            "Enhanced visibility for improved collaboration and planning.",
          projLogiKeyLi8H: "Risk Management:",
          projLogiKeyLi8Para:
            "Robust measures to safeguard shipments and manage risks.",
          projLogiKeyLi9H: "Environmental Sustainability:",
          projLogiKeyLi9Para:
            "Commitment to eco-friendly practices and solutions.",
          projLogiKeyLi10H: "Continuous Improvement:",
          projLogiKeyLi10Para:
            "Feedback-driven enhancements for better service and experience.",
          projOvanH: "Client",
          projOvanPara: "Ovantica",
          projOvanDurH: "Duration",
          projOvanDurPara: "6 Months",
          projOvanDateH: "Date",
          projOvanDatePara: "20 Apr 2017",
          projOvanCateH: "Category",
          projOvanCatePara: "Ecommerce",
          projOvanKeyLi1H: "User-Friendly Interface:",
          projOvanKeyLi1Para:
            "Intuitive website & app design for easy navigation and seamless user experience.",
          projOvanKeyLi2H: "Product Listings:",
          projOvanKeyLi2Para:
            "Comprehensive listings of secondhand electronics with detailed descriptions, including specifications, condition, and pricing.",
          projOvanKeyLi3H: "Search and Filter Options:",
          projOvanKeyLi3Para:
            "Advanced search and filter functionality to help users find specific electronics models, brands, conditions, etc., quickly.",
          projOvanKeyLi4H: "Warranty Option:",
          projOvanKeyLi4Para:
            "Offer warranty options for secondhand phones to instill confidence in buyers and provide assurance of product quality.",
          projOvanKeyLi5H: "Sell your old electronics feature:",
          projOvanKeyLi5Para:
            "A dedicated section where users can easily list their old electronics for sale, including options to describe the condition, upload photos, and set a price.",
          projOvanKeyLi6H: "Secure Transactions:",
          projOvanKeyLi6Para:
            "Implement secure payment gateways and ensure data encryption to protect users' financial information during transactions.",
          projOvanKeyLi7H: "Quality Assurance:",
          projOvanKeyLi7Para:
            "Implement rigorous quality checks and inspections for all secondhand electronics before listing them on the platform to ensure they meet quality standards.",
          projOvanKeyLi8H: "Customer Support:",
          projOvanKeyLi8Para:
            "Offer responsive customer support channels, such as live chat, email, or phone support, to assist users with any inquiries or issues they may have.",
          projOvanKeyLi9H: "Promotions and Discounts:",
          projOvanKeyLi9Para:
            "Offer promotional deals, discounts, and loyalty rewards to attract customers and encourage repeat purchases.",
          projPureH: "Client",
          projPurePara: "Rice Purity Club",
          projPureDurH: "Duration",
          projPureDurPara: "1 Months",
          projPureDateH: "Date",
          projPureDatePara: "15 Apr 2024",
          projPureCateH: "Category",
          projPureCatePara: "Entertainment",
          projPureKeyLi1H: "Questionnaire Format:",
          projPureKeyLi1Para:
            "The test is presented as a questionnaire with a series of multiple-choice questions or statements.",
          projPureKeyLi2H: "Large Question Bank:",
          projPureKeyLi2Para:
            "It typically contains a large number of questions covering a wide range of topics such as sexuality, drugs, relationships, and personal behavior.",
          projPureKeyLi3H: "Self-Grading System:",
          projPureKeyLi3Para:
            "After completing the questionnaire, respondents can submit their answers and receive an instant purity score based on their responses.",
          projPureKeyLi4H: "Privacy Options:",
          projPureKeyLi4Para:
            "Some websites offer privacy options, allowing users to take the test anonymously without sharing their results publicly.",
          projLetsH: "Let’s Make Your Dream Project",
          projLetsPara:
            "Do you want to have a website that stands out and impresses your clients? Then we are ready to help! Click the button below to contact us and discuss your ideas.",
          projLetsBtn: "Let’s Get Started",

          projSpeedH: "Client",
          projSpeedPara: "SPEED-P",
          projSpeedDurH: "Duration",
          projSpeedDurPara: "2 Months",
          projSpeedDateH: "Date",
          projSpeedDatePara: "15 Apr 2024",
          projSpeedCateH: "Category",
          projSpeedCatePara: "Sales",
          projSpeedKeyLi1H: "Comprehensive Analytics:",
          projSpeedKeyLi1Para:
            "Provides detailed insights and reports across various business functions, enabling data-driven decision-making.",
          projSpeedKeyLi2H: "Advanced Planning & Scheduling:",
          projSpeedKeyLi2Para:
            "Optimizes resource allocation and project timelines to enhance operational efficiency and productivity.",
          projSpeedKeyLi3H: "Enterprise Asset Management:",
          projSpeedKeyLi3Para:
            "Tracks and manages physical assets, ensuring their optimal utilization and maintenance.",
          projSpeedKeyLi4H: "Customer Relationship Management (CRM):",
          projSpeedKeyLi4Para:
            "Manages customer interactions, sales processes, and customer service to improve customer satisfaction and retention.",
          projSpeedKeyLi5H: "Supply Chain Management:",
          projSpeedKeyLi5Para:
            "Streamlines supply chain operations, including procurement, inventory management, and logistics, to reduce costs and improve efficiency.",
          projSpeedKeyLi6H: "Human Capital Management (HCM):",
          projSpeedKeyLi6Para:
            "Manages employee lifecycle processes, from recruitment and onboarding to performance management and payroll.",
          projSpeedKeyLi7H: "Manufacturing Management:",
          projSpeedKeyLi7Para:
            "Facilitates production planning, execution, and monitoring to ensure manufacturing processes are efficient and meet quality standards.",
          projSpeedKeyLi8H: "Financial Management:",
          projSpeedKeyLi8Para:
            "Handles financial operations, including accounting, budgeting, and financial reporting, to maintain financial health and compliance.",
          //Meatmarket
          projMMH: "Client",
          projMMPara: "Meat Market",
          projMMDurH: "Duration",
          projMMDurPara: "2 Months",
          projMMDateH: "Date",
          projMMDatePara: "10 Aug 2024",
          projMMCateH: "Category",
          projMMCatePara: "Ecommerce",
          projMMKeyLi1H: "Customizable Delivery Schedules:",
          projMMKeyLi1Para:
            "Allow customers to select specific delivery slots to suit their convenience, ensuring timely arrival of fresh meat.",
          projMMKeyLi2H: "Real-time Order Tracking:",
          projMMKeyLi2Para:
            "Provide live tracking of orders from preparation to doorstep delivery, keeping customers informed and engaged.",
          projMMKeyLi3H: "Exclusive Member Discounts:",
          projMMKeyLi3Para:
            "Create a loyalty program where frequent buyers get access to special deals and discounts on their favorite products.",
          projMMKeyLi4H: "Freshness Guarantee:",
          projMMKeyLi4Para:
            "Promise the highest quality and freshest meat with a clear return policy if standards are not met.",
          projMMKeyLi5H: "Bulk Order Discounts:",
          projMMKeyLi5Para:
            "Offer special pricing for larger orders, catering to families or bulk buyers, ensuring they save more.",
          projMMKeyLi6H: "Flexible Payment Options:",
          projMMKeyLi6Para:
            "Support a variety of payment methods, including cash on delivery, credit/debit cards, and digital wallets, for added convenience.",
          //RG
          projrgH: "Client",
          projrgPara: "RG Designs",
          projrgDurH: "Duration",
          projrgDurPara: "2 Months",
          projrgDateH: "Date",
          projrgDatePara: "10 Sep 2024",
          projrgCateH: "Category",
          projrgCatePara: "Ecommerce",
          projrgKeyLi1H: "Custom Embroidery Digitization:",
          projrgKeyLi1Para:
            "RG Designs offers personalized digitization services for transforming any logo, image, or graphic into a machine-ready embroidery file. This ensures perfect stitching quality, precision, and scalability across multiple formats.",
          projrgKeyLi2H: "Diverse Embroidery Patterns:",
          projrgKeyLi2Para:
            "The platform boasts a vast library of pre-made embroidery designs that cater to different themes, including holidays, nature, and special events. These designs are optimized for various machine types, ensuring compatibility and ease of use.",
          projrgKeyLi3H: "Advanced Editing Tools:",
          projrgKeyLi3Para:
            "RG Designs provides powerful online tools to customize and edit designs, enabling customers to adjust stitch types, colors, and patterns to match their specific project needs.",
          projrgKeyLi4H: "High-Resolution Stitching:",
          projrgKeyLi4Para:
            "All embroidery designs are crafted with high-resolution stitch patterns, ensuring clean and professional results for any material, from fabrics to more complex textiles.",
          projrgKeyLi5H: "Bulk Design Options:",
          projrgKeyLi5Para:
            "For large-scale projects, RG Designs offers bulk design options, allowing customers to purchase collections at competitive rates, which is ideal for businesses or professional embroiderers.",
          projrgKeyLi6H: "Fast Turnaround Time:",
          projrgKeyLi6Para:
            " RG Designs prides itself on offering quick and reliable digitization services, ensuring that custom orders are processed and delivered in a timely manner, meeting tight project deadlines.",

          //Project end

          //Header Start
          navHome: "Home",
          navServices: "Services",
          navServicesSub1: "Web Development",
          navServicesSub2: "Mobile App Development",
          navServicesSub3: "Software Development",
          navServicesSub4: "DevOps",
          navServicesSub5: "Cloud Computing",
          navServicesSub6: "Digital Marketing",
          navProject: "Project",
          navAbout: "About Us",
          navContact: "Contact",
          navEnquiry: "For Enquiry",
        },
      },
      az: {
        translation: {
          //Footer Start
          footerpara:
            "Biz bütün dünyada şirkətlər üçün məhsullar inşa edən proqram inkişaf şirkəti. Biz onlara daha yaxşı məhsul istehsal etməyə, biznes proseslərini yaxşılaşdırmağa və bizneslərini inkişaf etdirməyə kömək etmişik.",
          quickLinkHead: "Cəld Linklər",
          quickLink1: "Veb-sayt inkişafı",
          quickLink2: "Mobil App inkişaf",
          quickLink3: "Proqram təminatı inkişafı",
          quickLink4: "Bulud Hesablama",
          quickLink5: "Rəqəmsal Marketinq",
          quickLink6: "Şirkət haqqında",
          quickLink7: "Layihələr",
          quickLink8: "DevOps",
          quickLink9: "Bağlan",
          newsLetterHead: "Xəbər bülleteni",
          newspara: "Hər yenilənməni daha çox əldə etmək üçün oxuyun",
          newsletterplaceHolder: "E-poçt daxil edin",
          newsletterBtn: "İndi Abunə Ol",
          copyRights:
            "© 2024-cü ildə The TechHorse (2014). Bütün yaxşı qorunur",

          //Footer End
          //Home Start
          bannerSpan1: "Veb dizayn həlləri sahəsində 20 illik təcrübə",
          bannerH1: "Bu gün 'Sabah'ın rəqəmsal həllərinin tikintisi",
          getStartBtn: "Bizi işə salım",
          bannerimgSpan1: "Veb Dizayn",
          bannerSpan2: "Rəqəmsal həllər sahəsində 20 illik təcrübə",
          bannerH2: "Texnologiyanız Rəqəmsal Dünyada Tərəfdaşlar",
          bannerimgSpan2: "İnkişaf",
          coreFeatureH: "Əsas xüsusiyyətlər",
          coreFeatureSub: "Təklif etdiyimiz xidmətlər",
          coreCards1: "Responsive müasir dizayn",
          coreCards2: "Güclü Özəlləşdirmə",
          coreCards3: "Cool &müasir animasiyalar",
          coreCards4: "SEO Dostu Kodlaşdırma",
          coreCards5: "Ən yaxşı Texniki dəstəklər",
          coreCards6: "Müxtəlif Layklar &parallax",
          AllaboutH: "The TechHorse haqqında hər şey",
          AllaboutSub: "İnkişaf üçün Ən Yaxşı Rəqəmsal Agentlik Həlləri",
          missionH: "Mission:",
          missionPara:
            "Bizim missiyamız sənayedə inqilab etmək, insan təcrübələrini təkmilləşdirmək və daha yaxşı gələcək qurmaq üçün qabaqcıl süni intellekt texnologiyasından istifadə etməkdir. Həm klassik, həm də müasir İt həllərində və beynəlxalq biznesdə dərin köklərə malik olan bacarıqlı komandamız dünya miqyasında yüksək səviyyəli xidmətlərin göstərilməsinə həsr olunub. Biz innovativ texnologiya və qeyri-adi komanda işi vasitəsilə insan qarşılıqlı əlaqələrin artırılmasında ən etibarlı lider olmağı hədəfləyirik.",
          visionH: "Vizyon:",
          visionPara:
            "Aİ-nin təşəbbüsü ilə həyata keçirilən İt həllər üzrə lider olmaq, yaradıcılıq mədəniyyətini, komanda işini təbliğ etmək və davamlı öyrənmək. Biz özümüzü effektiv həllər hazırlamaq və müştərilərimizin inkişafını hər addımda dəstəkləmək həvəsi ilə fərq edirik.",
          philosophyH: "Fəlsəfə:",
          philosophyPara:
            "Bizim fəlsəfəmiz insan imkanlarını artıran və istifadəçi təcrübələrini təkmilləşdirən Aİ həlləri yaratmaqdır. Biz aİ-nin nəzəri praktikasına, məlumatların məxfiliyinə, ətraf mühitə davamlılığına üstünlük veririk. Bütün bunlar Aİ-nin tədqiqat və innovasiyalarının kəsici kənarında qalır. Mükəmməlliyə və komanda işlərinə sadiqliyimiz şirkət mədəniyyətimizin əsasını təşkil edir, qərarlarımıza təsir edir və transformativ Aİ həllərinin inkişafına təkan verir.",
          coredesignH: "Əsas Dizayn Xüsusiyyəti",
          corededignSub: "Biznes üçün nə təmin edirik",
          core1: "Veb Dizayn &İnkişaf",
          core2: "Mobil App inkişaf",
          core3: "Proqram təminatı inkişafı",
          core4: "DevOps",
          core5: "Rəqəmsal Marketinq",
          core6: "Bulud Hesablama",
          wishitonResp: "Cavab verən dizayn",
          recentProjH: "Son Layihələr",
          recentProjSub: "Son əsərlər qalereyasına bax",
          wishitonH: "WishItOn Veb",
          speedpH: "Speed-P Veb",
          wishitonMobH: "WishItOn Mobil Proqram",
          workingProcessH: "gedişat",
          workingProcessSub: "Necə işləyir,",
          infoGatherH: "İnformasiya Toplama",
          infoGatherPara:
            "Biz hər bir layihəni müştərinin ehtiyaclarını, məqsədlərini və gözləntilərini ətraflı başa düşməklə başlayırıq.",
          PlanningH: "Planlaşdırma &analiz",
          PlanningPara:
            "Sonra razılaşdırılmış vaxt və büdcə çərçivəsində layihənin tamamlanmasına istiqamət vermək üçün strukturlaşdırılmış plan hazırlayırıq. Bu kritik mərhələdə, toplanmış məlumatları təhlil edərək potensial çətinlikləri və imkanları müəyyənləşdirin.",
          developH: "İnkişaf",
          developPara:
            "Kodlaşdırma və dizaynda ən yaxşı praktikalardan istifadə edərək veb-sayt və ya proqramı yaratmağa başlayın.",
          testingH: "Sınaq İmtahanı & Başlatma",
          testingPara:
            "Tətbiqin və ya veb-saytın bütün arzu olunan platformalarda yaxşı yerinə yetirilməsini təmin etmək və hallardan istifadə etmək üçün hərtərəfli sınaqlar keçirin. Sınaq mərhələsində tapılan hər bir məsələni həll etmək. Sınaq imtahanları başa çatdıqdan sonra işə salınmasına davam edəcəyik.",
          HaveH: "Hər hansı bir layihə ilə bağlı hər hansı bir layihə var ?",
          Weare: "Biz SEO optimizasyonu işləmək üçün ?",
          formH:
            "Hər hansı bir layihəni yadda saxla! bizimlə sərbəst əlaqə hiss et və ya",
          formHSpan: "salam deyin",
          locationLabel: "Yer",
          locationCon: "Chennai,India",
          emailLabel: "E-Poçt Ünvanı",
          emailCon: "info@thetechhorse.com",
          phoneLabel: "Telefon No",
          formHead: "İsmarış yolla",
          fullNameHolder: "Tam Adı",
          emailHolder: "E-Poçt Ünvanı",
          subjectHolder: "Mövzu",
          messageHolder: "İsmarış yaz",
          sendmesBtn: "İsmarış yolla", //Home end
          //Web Start
          webBannerH: "Web Dizayn &İnkişaf etdir",
          webBannerSpan: "ment",
          webhome: "Ana səhifə",
          webTitle: "Veb Dizayn &İnkişaf",
          webWhat: "Təqdim etdiklərimiz",
          webWhatH: "Excel-ə hazırlanmış mükəmməl Web Həllər",
          webWhatSpan:
            "Pioner veb-sayt dizaynı və inkişafı üzrə tərəfdaşınız olan The TechHorse ilə illərin təcrübəsinin gücünü istifadə edin. Biznes ehtiyaclarınıza uyğun bespoke xidmətlərimizlə rəqəmsal varlığınızı yüksəldirin.",
          learnMoreBtn: "Daha çox öyrənin",
          webTailor: "Qabaqcadan fikirləşən uyğunlaşdırılmış veb-həllər",
          webTailorPara:
            "The TechHorse-də hər bir veb-sayt şah əsəridir, müştərinizi nəzərə alaraq diqqətlə hazırlanmış. Estetikadan ötəri, sadəcə veb saytlarını deyil, möhkəm rəqəmsal təcrübələri qurmaq üçün istifadəçi təcrübəsinin nüanslarını, SEO effektivliyini və texniki dəqiqliyi dərindən araşdırmağa çalışırıq.",
          webOurExpertise: "Bizim təcrübə daxildir :",
          webListT1: "Responsive Veb sayt dizaynı:",
          webListT1Para:
            "Hər hansı bir cihaza bir-birilə sıx şəkildə uyğunlaşan maye dizaynlar.",
          webListT2: "İstifadəçi Təcrübəsi Dizayn:",
          webListT2Para: "Məşğul və zövq verən intuitiv interfeyslər.",
          webListT3: "CMS və eCommerce İnteqrasiya:",
          webListT3Para:
            "Funksionallığı və idarəolunalılığı artıran streamlined həllər.",
          webListT4: "Vebsaytın Məzmun Strategiyası:",
          webListT4Para:
            "Məşğulluq və konvertasiyanı hərəkətə gətirən strateji məzmun çərçivələri.",
          webListT5: "Kross-brauzer və platforma sınaqları:",
          webListT5Para:
            "Bütün istifadəçi toxunuş nöqtələri boyunca ardıcıl performansın təmin edilməsi.",
          webListT6: "Istifadə və Rəqabət Analizi:",
          webListT6Para: "Rəqabət şəraitində sizi qabaqlayan anlayışlar.",
          strategyH: "Strategiyanın yaradılması",
          strategyPara:
            "Bazarda aparılan hərtərəfli araşdırmalar və strateji nizamlama ilə uğur üçün çərçivənizi hazırlayır.",
          webdegH: "Veb sayt dizaynı",
          webdegPara:
            "Markanızın mahiyyətini əhatə edən səciyyəvi dizaynların konseptuallaşdırılması.",
          webDevH: "İnkişaf",
          webDevPara:
            "Optimal funksionallıq üçün cutting-edge texnologiyası ilə saytınızı mühəndis edin.",
          projTestH: "Layihə Sınaqları",
          projTestPara:
            "Qüsursuz icrası təmin etmək üçün mütəxəssislərimiz tərəfindən ciddi sınaq.",
          projLaunchH: "Layihə Buraxılışı",
          projLaunchPara:
            "Güclü bir bazara təsir etmək üçün saytınızı dəqiqliklə işə salmaq.",
          customwebSolH: "Xüsusi Veb Həlləri",
          customwebSolPara:
            "Biznes ehtiyaclarınıza cavab verən unikal hazırlanmış veb-saytlarla rəqəmsal varlığınızı yüksəldirin. WordPress və qabaqcıl HTML və CSS-dən istifadə edərək, saytınızın həm asan idarə olunmasını, həm də axtarış sistemləri üçün optimal tərtib olunmasını təmin edirik.",
          customwebli1: "Asan redaktə etmək üçün ... Axtarış motoru dost",
          customwebli2: "Yüksək dərəcədə özəlləşdirilə bilən",
          customwebli3: "Dünyada ən populyar CMS",
          webTechFeatureH: "Texnologiya xüsusiyyətləri",
          webTechFeatureSub: "Tam potensial müasir xüsusiyyətləri",
          webResH: "Həssas Dizayn",
          webResSub: "Hər Bir Cihaz üçün Optimize Edilmiş Veb Dizayn",
          webResPara:
            "Bizim responsive dizayn sizin veb-saytı hər bir cihazda qeyri-adi istifadəçi təcrübəsi təqdim edir təmin edir. Saytınız istər masaüstü, istər laptop, istər planşet, istərsə də smartfon vasitəsilə daxil olan tikişsiz funksionallıq və estetik ardıcıllıq təklif edəcək.",
          webResli1: "Mobil-Dost İnterfayslar",
          webResli2: "Universal Uyğunluq",
          webResli3: "Genişləndirilmiş İstifadəçi Təcrübəsi &SEO",
          webPostH:
            "Biz Üzvi Trafik üçün Veb-Saytınızda Pulsuz SEO Optimizasyonu edəcəyik",
          webPostPara:
            "Effektiv SEO tərəfindən idarə olunan orqanik trafik gəlirin 40% artmasına səbəb ola bilər, belə ki, istifadəçilər reklamlardan daha çox orqanik sıralanmış saytlara etibar edirlər. Bundan əlavə, güclü üzvi varlığa malik saytlarda müştəri saxlama dərəcəsi yalnız ödənişli trafikə güvənənlərdən 5 dəfə çox müşahidə olunur.",
          createwebBtn: "Veb Saytınızı Yarat",
          webSupportMarkH: "Dəstək &marketing",
          webSupportMarkSub: "Web Dizaynınızı Dəstəkləmək üçün Marketinq",
          webSupportMarkPara:
            "Xoşbəxtlikdən, biz burada sadəcə dizayner və developer deyilik – biz yazıçı, strateq, texnologiya və yaradıcıyıq, hamısı eyni son məqsədə doğru çalışırıq: müştərimizin uğuru. Tam xidmətli rəqəmsal marketinq agentliyi kimi",
          webSeo: "SEO Xidməti",
          webcopywrite: "Köçür",
          webppc: "PPC",
          webseoH: "SEO Xidmətləri",
          webseoPara:
            "Siz bazar əmr etmək istəyirsinizsə, sizin online siz hərtərəfli SEO strategiya lazımdır",
          webcopyH: "Köçür",
          webcopyPara:
            "Markanızı gücləndir və söhbəti strateji məzmun marketinq strategiyası ilə idarə edin",
          webppcH: "Hər klik üçün ödə",
          webpccPara:
            "PPC idarə bütün doğru reklam sizin gələcək müştərilər dəqiq çatdırmaq",
          webcraftH: "Vizyonunuzu dəqiqliklə edin",
          webcraftPara:
            "Rəqəmsal varlığınızı dəyişməyə hazırsınızmı? The Tech Horser-da biz sizin auditoriyanızla dərindən əks olunan və dərin rezonans yaradan veb-saytlar yaratmaqda ixtisaslaşırıq. Gəlin görək, biz sizin görmə qabiliyyətinizi necə həyata qaytara bilərik. Başlamaq üçün aşağıya basın.",
          webletstartBtn: "Başlayaq",
          //Web End

          //Mobile Start
          mobileBannerH: "Mobil Proqram inkişaf etdir",
          mobileBannerSpan: "ment",
          mobilehome: "Ana səhifə",
          mobileTitle: "Mobil App inkişaf",
          mobilewhatH: "Təqdim etdiklərimiz",
          mobileWhatSUb: "App Dizaynda Mükəmməllik & İnkişaf",
          mobileWhatPara:
            "Qeyri-adi rəqəmsal təcrübələr əldə etmək üçün 20 illik pioner təcrübəsindən istifadə etmək.",
          mobilerightPara:
            "Müasir proqram inkişafı funksional proqramları qurmaqdan ötəri aşmışdır. Maraqlı istifadəçi təcrübəsinin yaradılması, performansın optimallaşdırılması və kəsici texnologiyaların daxil edilməsi ilə bağlıdır. Burada xüsusi olaraq qeyd etdiyimiz:",
          mobileRightList1: "Responsive App Dizayn",
          mobileRightList2: "İstifadəçi Təcrübə Yenilikləri",
          mobileRightList3: "App İnteqrasiya Həlləri",
          mobileRightList4: "Proqram Məzmunu Strategiyası",
          mobileRightList5: "Kompleks Sınaq İmtahanı",
          mobileRightList6: "Bazar və istifadə analizi",
          mobilestrategyH: "Strateji Formul",
          mobilestrategyPara:
            "Bazarda uğur qazanmaq üçün proqramınızı yer tutmaq üçün dəqiq strategiyaların hazırlanması.",
          mobileappDesignH: "Proqram Dizaynı",
          mobileappDesignPara:
            "Vizual cəlbedici və istifadəçi dostu dizayn anlayışlarının hazırlanması.",
          mobileAppdevH: "Proqram inkişafı",
          mobileAppdevPara:
            "Dəqiqliklə möhkəm və ölçeklenebilir app həllərinin yaradılması.",
          mobileApptestH: "Proqram Testi",
          mobileApptestPara:
            "Ciddi sınaq protokolları vasitəsilə ən yüksək performansın təmin edilməsi.",
          mobileAppLaunchH: "Proqram Buraxılışı",
          mobileAppLaunchPara:
            "Optimal bazara nüfuz etmək üçün proqramınızı strateji olaraq yerləşdirmək.",
          mobileflutterH:
            "Flutter , React native , Kotlin və Swift mobil app inkişaf",
          mobileflutterPara:
            "Flutter və React Native kross-platform mobil inkişaf üçün çərçivələrdir, Kotlin və Swift proqramlaşdırma dilləri əsasən Android və iOS inkişafı üçün istifadə olunur . Hər birinin öz güclü cəhətləri var , hallardan istifadə və platformaya xas xüsusiyyətlər",
          mobileflutterList1: "Xaç platforması",
          mobileflutterList2: "Yüksək miqyaslı inkişaf",
          mobileflutterList3: "Effektivlik",
          mobileflutterList4: "Sıxlıq",
          mobileResH: "Həssas Dizayn",
          mobileResSub: "Hər hansı bir cihaz responsive app dizayn üçün dizayn",
          mobileResPara:
            "Responsive app dizayn sizin proqram uyğunlaşdırmaq və ona baxılan hər hansı bir cihaz üzərində optimal təcrübə təmin imkan verir. Bu o deməkdir ki, sizin tətbiqiniz istənilən smartfon cihazı üzrə istifadəçi üçün böyük görünəcək və qüsursuz fəaliyyət göstərəcək.",
          mobileReslist1: "Mobil-dost",
          mobileReslist2: "Hər bir cihaz üçün dizayn",
          mobileReslist3: "Positive UX &SEO-ya Kömək Edir",
          mobileBoostH:
            "Proqramınızın görünüşünü artırın – Pulsuz SEO Xidmətləri Daxil",
          mobileBoostPara:
            "Proqramınızı rəqabətli kənarla işə salın. Google Play Store və Apple App Store-da proqramınız üçün eksklüziv, tərifli SEO xidmətləri təklif edirik. Bu xidmət onun auditoriyasına çatmasını təmin edir və tanınmış sıraları.",
          mobileCreateAppBtn: "Proqramınızı Yarat",
          mobileSeoH: "Dəstək &marketing",
          mobileSEOSub: "Sizin App Dizayn Dəstək üçün Marketinq",
          mobileSeoPara:
            "Xoşbəxtlikdən, biz burada sadəcə dizayner və developer deyilik – biz yazıçı, strateq, texnologiya və yaradıcıyıq, hamısı eyni son məqsədə doğru çalışırıq: müştərimizin uğuru. Tam xidmətli rəqəmsal marketinq agentliyi kimi",
          mobileSeoSerH: "SEO Xidməti",
          mobileSeoCopyH: "Köçür",
          mobilePPCH: "PPC",
          mobileSEOSERH: "SEO Xidmətləri",
          mobileSEOSERPara:
            "Siz bazar əmr etmək istəyirsinizsə, sizin online siz hərtərəfli SEO strategiya lazımdır",
          mobileSeoCopy: "Copywriting",
          mobileSeoCopyPara:
            "Markanızı gücləndir və söhbəti strateji məzmun marketinq strategiyası ilə idarə edin",
          mobileSeoPPC: "Hər klik üçün ödə",
          mobileSeoPPCPara:
            "PPC idarə bütün doğru reklam sizin gələcək müştərilər dəqiq çatdırmaq",
          mobilenextappHead: "Növbəti Proqramınızı Yaradaq",
          mobilenextappPara:
            "Bazarda üstünlük təşkil edən və üstünlük təşkil edən proqrama hazır olmaq? Biz sənin görməni cana gətirmək üçün gəlmişik. Fikirlərinizi rəqəmsal uğura çevirməyə başlamaq üçün aşağıya basın.",
          mobileLanuchBtn: "Görmə qabiliyyətinizi işə salın", //Mobile End
          //Software Start
          softBannerH: "Proqram təminatı Inkişaf etdir",
          softBannerSpan: "ment",
          softBannerHome: "Ana səhifə",
          softBannerHomeTitle: "Proqram təminatı inkişafı",
          softCompanyH: "Şirkət Statistikası",
          softCompanySub:
            "Pioneering Software Solutions ilə vizyonunuzu gücləndir",
          softCompanyPara:
            "Fikirlərinizi aparıcı proqram həllərinə çevirin. Bizim ekspert tərəfindən idarə olunan inkişaf xidmətlərimiz tikişsiz icra və üstün nəticələr vəd edir, sizi uğura hazırlayır — heç bir texniki fon lazım deyil.",
          softWorkH: "gedişat",
          softWorkSub: "Necə işləyir,",
          softwork1H: "Rö'yanı başa düşün",
          softwork1Para:
            "Biz sizin məqsəd və tələblərinizi hərtərəfli dərk etməklə, layihənizin möhkəm təməlini təmin etməklə başlayırıq",
          softwork2H: "Müvəffəqiyyətinizi çərçivəyə edin",
          softwork2Para:
            "Strategiyalarımız və developerlərimiz sizin məqsədlərinizə uyğun detallı yol xəritəsinin hazırlanması üçün əməkdaşlıq edirlər",
          softwork3H: "Konsepsiyanı təkmilləşdir",
          softwork3Para:
            "Biz sizin gözləntilərinizi və yüksək standartlarımızı yerinə yetirmək üçün dizaynı təhlil və təkmilləşdiririk",
          softwork4H: "Mükəmməlliklə icra et",
          softwork4Para:
            "Qüsursuz performans üçün ciddi sınaqdan sonra, sizin proqram işə, təsir etmək üçün hazır",
          softharnessH:
            "Biznesinizi irəli aparmaq üçün Harness Cutting-Edge Software",
          softharnessPara:
            "Sizin proqram inkişaf səyahət dəqiqlik ilə naviqasiya etmək üçün bizim təcrübə istifadə edin. Bizim komanda sizin unikal ehtiyaclarınıza uyğun hazırlanmış strategiyaların hazırlanması üzrə excels edir, sizi biznes məqsədlərinizə nail olmağa və üstələməyə yönəldir.",
          softHarnessschBtn: "Pulsuz konsultasiyanı planlaşdırın",
          softHarright1H: "Sürücü Daha Ağıllı Qərarlar",
          softHarright1Para:
            "Qərar qəbuletməni artırmaq və biznesinizi hərəkətli anlayışlarla irəli aparmaq üçün Big Data-nın gücündən istifadə edin.",
          softHarright2H: "Gələcək-Sübut Sizin Biznes",
          softHarright2Para:
            "Hərtərəfli rəqəmsal transformasiya strategiyalarımızla inkişaf edən bazarda uyğunlaşma və çiçəkləmə.",
          softHarright3H: "Genişlə və Genişlə",
          softHarright3Para:
            "İnkişaf dövriyyənizi sürətləndirin və möhkəm bulud əsaslı həllərimizlə effektivliyi artırın.",
          softHarright4H: "Fərdi həllər, müstəsna nəticələr",
          softHarright4Para:
            "Qeyri-adi istifadəçi təcrübələri üçün biznes ehtiyaclarınıza cavab vermək üçün uyğunlaşdırılmış təsirli və ölçeklenebilir veb və ya mobil tətbiqlər yaradın.",
          softCurH1: "Maraqlıdır,",
          softCurSpan: "The TechHorse",
          softCurH2: "layihəniz üçün uyğun?",
          softCurSub: "Premier Software Development Services axtarır?",
          softCurPara1:
            "Gəlin, görmə qabiliyyətinizi ətraflı anlayaq. Sizin tələblərinizi nəzərdən keçirdikdən sonra, biz əhatə dairəsini təsvir etmək üçün öhdəliksiz pulsuz konsultasiyanı planlaşdıra və uğurunuzu təmin etmək üçün təxmini strategiya təqdim edə bilərik.",
          softCurPara2:
            "Hər bir layihə özünəməxsusdur və bizim yanaşmamız da belədir. Məhz buna görə də sizi bizim inkişaf etmiş proqram tərtibatçılarımızdan biri ilə kompliment konsultasiyaya dəvət edirik. Bu iclas bizim strategiyalarımızı sizin ehtiyaclarınıza uyğunlaşdırmaq və sizə tam olaraq 'The Tech Horser' in layihənizi necə səmərəli şəkildə gətirə biləcəyini göstərmək üçün nəzərdə tutulub.",
          softFormH: "İsmarış yolla",
          softFullName: "Tam Adı",
          softemail: "E-Poçt Ünvanı",
          softSubject: "Mövzu",
          softMeg: "İsmarış yaz",
          softSendBtn: "İsmarış yolla",
          softLetsH: "Yeni Proqramlarınızı Dizayn edək",
          softLetsPara:
            "Müştərilərinizi heyrətləndirən və təsirləndirən proqram təminatına sahib olmaq istəyirsinizmi? Onda biz kömək etməyə hazırıq! Bizimlə əlaqə saxlamaq və fikirlərinizi müzakirə etmək üçün aşağıdakı düyməni basın.",
          softLetsBtn: "Başlayaq", //Software end
          //Devops start
          devopsBannerH: "DevOps",
          devopsHome: "Ana səhifə",
          devopsHomeNav: "DevOps",
          devopsYRSEXP: "DevOps həlli üzrə illərin təcrübəsi",
          devopsSolH: "DevOps Həll Yolları",
          devopsSolSub:
            "Elite DevOps Mühəndisliyi ilə Əməliyyatlarınızı Yüksəlt",
          devopsSolPara:
            "The TechHorse-də biz mükəmməlliyi mühəndis edirik. Bizim DevOps xidmətləri proqram çatdırılma prosesinizi səliqəyə salmaq üçün nəzərdə tutulmuşdur. Bu, hər ölçüdə olan müəssisələr üçün möhkəm ölçeklenebilirlik və ironclad təhlükəsizliyini təmin edir. Əməliyyatlarınızı dəqiq və effektivliklə transformasiya etmək üçün bizimlə əməkdaşlıq edin.",
          devopsCliSta: "Müştəri Məmnuniyyəti",
          devopsMissH: "Missiya və Vision",
          devopsMissSub: "Biz keyfiyyətli yaradıcı həlli çatdırmaq məqsədi ilə",
          devopsMissPara:
            "The TechHorse-də biz sənaye standartlarını təyin edən unikal innovativ veb həllər istehsal edirik. Bizim komandamız rəqəmsal varlığınızı yüksəltmək üçün texnoloji tərəqqi, ən son yenilikləri qəbul və inteqrasiya etmək üçün ön sıralardadır.",
          devopsSerH: "Populyar Xidmətlərimiz",
          devopsSerSub: "Həvəskar DevOps Xidməti",
          devopsCloud1H: "Bulud Miqrasiyası",
          devopsCloud1Para: "Focus təmin gözəl layout client baxış make import",
          devopsCloud2H: "Mikroservislər",
          devopsCloud2Para: "Focus təmin gözəl layout client baxış make import",
          devopsCloud3H: "Monitorinq və Logging",
          devopsCloud3Para: "Focus təmin gözəl layout client baxış make import",
          devopsCloud4H: "Davamlı Çatdırılma",
          devopsCloud4Para: "Focus təmin gözəl layout client baxış make import",
          devopswhyH: "Nəyə görə bizi seçməliyik?",
          devopswhySub: "Güvənə biləcəyiniz DevOps Xidmətləri",
          devopslist1H: "Həll edilə bilənlər",
          devopslist1Para:
            "Bizim DevOps strategiyalarımız skalability üçün nəzərdə tutulmuşdur, performansa güzəştə getmədən inkişafı dəstəkləyir. Biz sizin infrastrukturunuzun biznes ehtiyaclarınızla təkamül keçməsini təmin edirik.",
          devopslist2H: "İnkişaf etdir",
          devopslist2Para:
            "Əməliyyatlarınızı səliqəyə salmaq üçün avtomatlaşdırmaya üstünlük veririk. Kod yerləşdirilməsindən infrastrukturun idarə edilməsinə, səhvlərin azaldılmasına və effektivliyin artırılmasına qədər.",
          devopslist3H: "Davamlı Qoşulma &n Davamlı Göndərmə (CI/CD)",
          devopslist3Para:
            "Bizim CI/CD həlləri ilə sürətli çatdırılması qəbul edin ki, sizin proqram yeniləmələr sorunsuz və təhlükəsiz, daha sürətli vaxt-to-market və möhkəm xidmət davam imkan verir.",
          devopslist4H: "Güzəştli Nişan Modelləri",
          devopslist4Para:
            "Bizim müştəri mərkəzli nişanlama modellərimiz ilə layihə tələblərinə əsaslanaraq, büdcənizə və resurslarınıza optimal nəzarəti təmin edərək, yuxarı və ya aşağı endirə bilərsiniz.",
          devopsSupportList1: "Davamlı İnteqrasiya",
          devopsSupportList2: "Quraşdırma İdarəsi",
          devopsSupportList3: "Avtomatlaşdırılmış Sınaq İmtahanı",
          devopsSupportList4: "Əməkdaşlıq Alətləri",
          devopsSupportList5: "Buraxılış İdarəsi",
          devopsSupportList6: "Versiyon Nəzarəti",
          devopsSupFeatureH: "Dəstək & Xüsusiyyətlər",
          devopsSupFeatureSub: "Amazing DevOps mühəndislik xüsusiyyətləri",
          devopsSupFeaturePara:
            "biz daha sürətli vaxt-bazar, əməkdaşlıq, və məhsulların keyfiyyətinin artırılması imkan geniş DevOps xidmətləri təklif edirik. Bizim ekspert heyəti DevOps məqsədlərinizə səmərəli və effektiv şəkildə nail olmaq üçün sənayenin ən yaxşı praktikalarından və kəsici texnologiyalardan istifadə edir.",
          devopsLearnbtn: "Haqqımızda daha çox öyrənin",
          devopsLifeH: "DevOps Ömrü",
          devopslife1H: "Burax",
          devopslife1Para:
            "Son istifadəçilər üçün tikişsiz əlçatanlığı təmin etmək üçün istehsal mühitlərinə yeni proqram və ya yeniləmələrin yerləşdirilməsi. Bu həlledici mərhələ bütün zəruri hazırlıq addımlarını əhatə edir.",
          devopslife2H: "Cilala",
          devopslife2Para:
            "Yeni funksiyaların dərhal istifadə üçün əlçatan olması üçün istehsal, stasionar və ya sınaq mühitləri boyunca proqram yeniləmələrinin və ya yeni proqramların quraşdırılması.",
          devopslife3H: "İşlə",
          devopslife3Para:
            "Bütün həyat boyu proqram sistemlərinin idarə edilməsi və qorunması. DevOps əməkdaşlığı və effektivliyi artırmaq üçün inkişaf (Dev) və əməliyyatları (Ops) inteqrasiya edir.",
          devopslife4H: "Monitor",
          devopslife4Para:
            "Optimal fəaliyyəti və etibarlılığı təmin etmək üçün proqram təminatının icrasına və infrastrukturuna daim nəzarət etmək. Monitorinqlərin aparılması proaktiv təkmilləşmə və sistem bütövlüyü üçün mühüm rol oynayır.",
          devopslife5H: "Layihə Buraxılışı",
          devopslife5Para:
            "Güclü bazara təsir etmək üçün saytınızı dəqiqliklə işə salmaq.",
          devopsEleH: "Əməliyyatlarınızı Mütəxəssis DevOps ilə Yüksəlt",
          devopsElePara:
            "İş məqsədlərinizə uyğun olan tikişsiz, effektiv, təhlükəsiz DevOps strategiyasına ehtiyacınız varmı? Uğurunuzu mühəndis etməyə hazırıq. Bizim ekspert heyəti ilə əməliyyatlarınızı optimallaşdırmağa başlamaq üçün aşağıdakı düyməni basın.",
          devopsEleBtn: "Başlayaq",
          //Devops End

          //Cloud Start
          cloudBannerH: "Bulud",
          cloudBannerSpan: "Hesablama",
          cloudBannerHome: "Ana səhifə",
          cloudBannerHomeSpan: "Bulud Hesablama",
          cloudCompH: "Şirkət Statistikası",
          cloudCompSub: "Elite Cloud Solutions ilə Üstün Performans Unleash",
          cloudCompPara:
            "The TechHorse-in yüksək performanslı bulud hesablaması ilə biznesinizi yüksəldirin. Bizim mürəkkəb şəbəkə serverlərin, məlumat bazalarının, proqram təminatının və daha bir çox şeylərin sıx birləşdirilməsini təklif edir.",
          cloudSerH: "Xidmətlərimiz",
          cloudSerSub: "Heyrətamiz Bulud Hesablama Xidməti",
          cloudSerli1H: "On-Demand Self Service",
          cloudSerli1Para:
            "Bu, klient server uptime, bacarıqları və ayrılmış şəbəkə storage daim nəzarət etmək üçün imkan verir.",
          cloudSerli2H: "Ölçü və hesabat xidməti",
          cloudSerli2Para:
            "Hesabların monitorinqinə və resursların optimal istifadəsinin təmin edilməsinə kömək edir.",
          cloudSerli3H: "Böyük Verilənlər Analizi",
          cloudSerli3Para:
            "Big data analitika müxtəlif, yüksək həcmli, yüksək velocity məlumat dəstlərindən anlayışları toplamaq, emal etmək və derasiya etmək üçün istifadə olunan üsul və tətbiqlərə aiddir.",
          cloudSerli4H: "Data Security &Scalability",
          cloudSerli4Para:
            "Bir çox müştərilərin bulud hesablamasının sürətli ölçeklenebilirliyinə görə çox sərfəli işlənə bilən iş yükləri var.",
          cloudwhyH: "Nəyə görə bizi seçməliyik?",
          cloudwhySub: "Güvənə biləcəyiniz müstəsna bulud həlləri",
          cloudwhyLi1H: "Scalable Arxitekturası",
          cloudwhyLi1Para:
            "Hər mərhələdə biznesinizi dəstəkləyən ölçeklenebilir bulud infrastrukturumuzla limitsiz uyğunlaşıb inkişaf.",
          cloudwhyLi2H: "Təhlükəsiz və Etibarlı",
          cloudwhyLi2Para:
            "Məlumatlarınızı üst-üstə səviyyəli təhlükəsizlik protokollarımızla qoruyun və etibarlı bulud xidmətlərimizlə ardıcıl uptime zövq alın.",
          cloudwhyLi3H: "Səy göstərmədən İnteqrasiya",
          cloudwhyLi3Para:
            "Bulud həllərimizi hamar, effektiv və bir-biri ilə uyğun əməliyyat üçün mövcud platformalarınızla sıx birləşdirin.",
          cloudEveH: "Bulud Həllərimizlə Rəqəmsal Varlığınızı Yüksəlt",
          cloudEvePara:
            "Rəqəmsal strategiyanızı bizim ölçeklenebilir, möhkəm bulud infrastrukturumuzla çevirin. Yenilik etməyə başlamaq üçün aşağıdakı klikləyin.",
          cloudLetsbtn: "Başlayaq",
          //Cloud end

          //Digital Start
          digiBannerH: "Rəqəmsal",
          digiBannerSpan: "Marketinq",
          digiBannerHome: "Ana səhifə",
          digiBannerHomeNav: "Rəqəmsal Marketinq",
          digiWhatH: "Biz nə edirik?",
          digiWhatSub: "Müəssisə Digital Marketinq ilə Markanızı Yüksəlt",
          digiseoLi1H: "SEO Xidmətləri",
          digiseoLi1Para:
            "Davamlı inkişaf üçün nəzərdə tutulmuş strateji SEO həllərimizlə görünüşünüzü və sıranızı üst-üstə maksimal edin.",
          digiseoLi2H: "Sosial Media Marketinq",
          digiseoLi2Para:
            "Özəlləşdirilmiş sosial media kampaniyaları ilə dinləyicilərinizi cəlb edin və genişləndirin. Bu kampaniyalar rezonans və konvertasiya.",
          digiseoLi3H: "Google Elanlar",
          digiseoLi3Para:
            "Məqsədli trafiki sürün və mütəxəssis idarə olunan Google Ads kampaniyaları ilə dərhal nəticələrə nail olmaq.",
          digiseoLi4H: "Xüsusi Veb Sayt Dizaynı",
          digiseoLi4Para:
            "Ziyarətçiləri əsir və konvertasiya edən bespoke veb-sayt dizaynları ilə unikal online var olmaq.",
          digiseoLi5H: "Veb-sayt dizaynı",
          digiseoLi5Para:
            "Sizin markanın ehtiyaclarına uyğun olan çarpıcı, satış optimallaşdırılmış Shopify mağazasını işə salın.",
          digiseoLi6H: "Qurğuşun Nəsli",
          digiseoLi6Para:
            "Konvertasiya və müştərilərin alınması üçün optimizasiya edilmiş strategiyalarla yüksək keyfiyyətli liderlik qazanın.",
          digiseoLi7H: "Məzmun Marketinqi",
          digiseoLi7Para:
            "Məlumat verən və ilhamlandıran inandırıcı məzmunla markanızı sənaye lideri kimi mövqe tutmaq.",
          digiseoLi8H: "Rəqəmsal PR",
          digiseoLi8Para:
            "Markanızı quran və qoruyan hərtərəfli rəqəmsal PR xidmətlərimizlə şöhrətinizi artırın və çata.",
          digiseoLi9H: "Markalama",
          digiseoLi9Para:
            "Biznesinizin ethos və dəyərlərini ifadə edən kreativ marka həllərimizlə unudulmaz marka kimliyi yaradın.",
          digiSata1H:
            "Görmə qabiliyyətini artırmaq üçün strateji hazırlanmış kampaniyalar",
          digiSata1Para:
            "Onlayn iştirakınızı artırmaq və təsirli nəticələr sürmək üçün uyğunlaşdırılmış kəsici rəqəmsal marketinq strategiyaları ilə markanızın potensialının kilidini açın.",
          digiSata2H: "Hədəflənmiş Rəqəmsal Reklam ilə Artım Sür",
          digiSata2Para:
            "Ən aktiv və qəbuledici olduğu ideal müştərilərinizə çatmaq üçün dəqiq hədəfli Google Reklamları və məzmun marketinqinin gücündən istifadə edin.",
          digiSata3H:
            "Hərtərəfli Rəqəmsal Xidmətlər ilə Markanızı Inkişaf Etdir",
          digiSata3Para:
            "İlk markalamadan rəqəmsal PR-a qədər dəyərlərinizlə danışan və hədəf auditoriyanızla rezonans yaradan bir-birinə uyğun şəxsiyyət yaradın.",
          digiCoreH: "Əsas xüsusiyyətlər",
          digiCoreSub: "Heyranedici Əsas Xüsusiyyətləri",
          digicore1H: "Data-Driven Strategiyası",
          digicore1Para:
            "Marketinq səylərinizi maksimum ROI və effektivliyi təmin edən analitikamız və data-driven yanaşmalarımızdan istifadə edərək dəqiq şəkildə uyğunlaşdırın.",
          digicore2H: "Omnichannel Kampaniyaları",
          digicore2Para:
            "Seamlessly sizin marketinq bütün digital platformalar boyunca inteqrasiya, əsir və konvertasiya edən vahid marka təcrübəsi təmin edir.",
          digicore3H: "Yaradıcılıq Məzmunu Yaradılış",
          digicore3Para:
            "İnnovativ məzmun xidmətlərimizdən istifadə edərək dinləyicilərinizlə resonasiya edən və nişanlanmanı təşviq edən inandırıcı əhvalatlar.",
          digicore4H: "İnkişaf etmiş SEO Texnikaları",
          digicore4Para:
            "Trafiki sürmək və dönüşümləri artırmaq üçün bizim kəsici SEO taktikası ilə axtarış motorunuzun mövcudluğunu yüksəlt.",
          digicore5H: "Məqsədli Reklam",
          digicore5Para:
            "Potensial müştərilərə daha effektiv çatmaq üçün mürəkkəb reklam hədəfləmə, qurğuşun nəslini və satışını artırmaq üçün istifadə edin.",
          digicore6H: "Hərtərəfli Hesabat",
          digicore6Para:
            "Kampaniyanın gedişini və metrikanı izləyən, məlumatlı qərarlar və strateji düzəlişlərə imkan yaradan ətraflı hesabatlarla məlumatlı qalın.",
          digiChooseH: "Nəyə görə bizi seçməliyik?",
          digiChooseSub: "Etibarlı Rəqəmsal Marketinq Ekspertizası",
          digiChoose1H: "Sübut olunmuş Nəticələr",
          digiChoose1Para:
            "Bizim track rekord öz-özünə danışır. Biz nəqliyyat, nişan, və dönüşüm sahəsində ölçülü yaxşılaşdırılması, real biznes inkişaf təkan verir.",
          digiChoose2H: "Hərtərəfli Analitika",
          digiChoose2Para:
            "Ətraflı analitikamız ilə siz əvvəlki kimi anlayışlar əldə edə bilərsiniz. Onlar sizə auditoriyanızı başa düşməyə və marketinq strategiyalarınızı effektiv şəkildə optimal şəkildə həyata silməsinə kömək edəcək.",
          digiChoose3H: "İnnovativ həllər",
          digiChoose3Para:
            "Biz döngədən qabaq qalırıq. Markanızı aktual və rəqabətli saxlamaq üçün ən son rəqəmsal marketinq trendlərini və texnologiyalarını tətbiq edirik.",
          digiunlockH:
            "Rəqəmsal Potensialınızın Kilidini Açın: Pulsuz Konsultasiya Mövcuddur",
          digiunlockPara:
            "Onlayn iştirakınızı transformasiya etməyə hazır olun? Pulsuz konsultasiya ilə başlayın! Görmə qabiliyyətinizi müzakirə etmək və veb-saytınızı diqqətə və müştərilərinizə heyran edən uyğunlaşdırılmış rəqəmsal marketinq strategiyalarını kəşf etmək üçün aşağıda tıklayın.",
          digiunlockBtn: "Başlayaq",
          //Digital End

          //About Start
          abtBannerH: "Haqqında",
          abtBannerSpan: "Bizik",
          abtBannerHome: "Ana səhifə",
          abtBannerHomeNav: "Haqqımızda",
          abtwhatH: "Biz nə edirik?",
          abtwhatSub: "Biz Peşəkar İt Komandasıyıq",
          abtwhatPara:
            "Biz bütün dünyada şirkətlər üçün məhsul inşa edən proqram inkişaf şirkəti. Biz onlara daha yaxşı məhsul istehsal etməyə, biznes proseslərini yaxşılaşdırmağa və bizneslərini inkişaf etdirməyə kömək etmişik. Biz digər proqram inkişaf şirkətlərindən fərqlənirik, çünki biz nə etdiyimizə və necə etdiyimizə həvəslə yanaşırıq. Müştərilərimizin uğur qazanmasına kömək edə biləcək məhsullar tikməyi sevirik və hər addımda onlarla işləməyi sevirik.",
          abtwhatLi1Para:
            "Dünyanın ən yaxşı və etibarlı insan təcrübəsi dizayn şirkəti olmaq üçün.",
          abtwhatLi2Para:
            "İnsanların qarşılıqlı əlaqəsini artırmaq və yüksəltmək üçün. Həsr olunmuş komanda işi vasitəsilə mükəmməlliyə can atmaq üçün.",
          abtcreateBtn: "Veb Saytınızı Yarat",
          abtYearexp: "İllər üzrə təcrübə",
          abtexpH:
            "Bu dünyada 20 illik təcrübə, iş adamlarının ilk və ən başlıca seçimi olmaq.",
          abtexpLi1: "Veb-sayt inkişafı",
          abtexpLi2: "Proqram təminatı inkişafı",
          abtexpLi3: "Bulud Hesablama",
          abtexpLi4: "Mobil App inkişaf",
          abtexpLi5: "DevOps",
          abtexpLi6: "Rəqəmsal Marketinq",
          abtwebLi1H: "Veb sayt dizaynı",
          abtwebLi1Para:
            "Markanızın mahiyyətini əhatə edən səciyyəvi dizaynların konseptuallaşdırılması.",
          abtwebLi2H: "Proqram Dizaynı",
          abtwebLi2Para:
            "Vizual cəlbedici və istifadəçi dostu dizayn anlayışlarının hazırlanması.",
          abtwebLi3H: "Müvəffəqiyyətinizi çərçivəyə edin",
          abtwebLi3Para:
            "Strategiyalarımız və developerlərimiz sizin məqsədlərinizə uyğun detallı yol xəritəsinin hazırlanması üçün əməkdaşlıq edirlər.",
          abtwebLi4H: "Axtarış sistemləri üçün optimallaşdırma",
          abtwebLi4Para:
            "Davamlı inkişaf üçün nəzərdə tutulmuş strateji SEO həllərimizlə görünüşünüzü və sıranızı üst-üstə maksimum edin.",
          abtLetH: "Gəlin yeni veb-saytınızı tərtib edək",
          abtLetPara:
            "Müştərilərinizi heyran edən və fərqlənən bir veb-saytın olmasını istəyirsiniz? Onda biz kömək etməyə hazırıq! Bizimlə əlaqə saxlamaq və fikirlərinizi müzakirə etmək üçün aşağıdakı düyməni basın.",
          abtLetBtn: "Başlayaq",
          //About End

          //Contact start
          contactBannerH: "Bağlan",
          contactBannerHome: "Ana səhifə",
          contactBannerHomeNav: "Bağlan",
          contactusH: "Bizimlə əlaqə saxlayın",
          contactusSub:
            "Gəlin yeni layihəyə başlayaq və ya birlikdə işləyək! Bizimlə əlaqə",
          contactusPara:
            "Yeni layihəyə start vermək və ya mövcud təşəbbüslərlə əməkdaşlıq etmək üçün bu gün bizimlə əlaqə saxlayın. Səndən eşitmək bizi çox sevindirir!",
          contactfullnamelabel: "Tam Adı",
          contactfullnamePlace: "Adınızı daxil edin",
          contactfullemaillabel: "E-Poçt Ünvanı",
          contactfullemailPlace: "E-Poçtunuzu daxil edin",
          contactfullphonelabel: "Telefon Nömrəsi",
          contactfullphonePlace: "Telefon nömrənizi daxil edin",
          contactfullSubjectlabel: "Mövzu",
          contactfullSubjectPlace: "Mövzu",
          contactfullmeglabel: "İsmarış yaz",
          contactfullmegPlace: "İsmarış yaz",
          contactSendbtn: "İsmarış yolla",
          contactLocH: "YER",
          contactLocPara: "Royapettah, Chennai, Tamil Nadu 600014",
          contactEmailH: "E-POÇT ÜNVANı",
          contactPhoneH: "TELEFON NÖMRƏSI",
          contactFollowH: "Bizi izləyin",
          contactUSAH: "USA",
          contactUSLOc: "Boca Raton, Fl 33428",
          contactAus: "Australia",
          contactAusLOc: " Melbourne Vic 3016",
          contactDub: "Dubai",
          contactDubLoc: "Al Rashidiya 3 7613",
          //Contact end

          //Project start
          projBannerH: "Nüfuzlu Müştərimizin Layihəsindən Az",
          projBannerHome: "Ana səhifə",
          projBannerHomeNav: "Layihə",
          projwishCLientH: "Müştəri",
          projwishCLientPara: "Wishiton",
          projwishDurH: "Davametmə müddəti",
          projwishDurPara: "4 ay",
          projwishDateH: "Tarix",
          projwishDatePara: "5 Nov 2023",
          projwishCateH: "Kateqoriya",
          projwishCatePara: "Əyləncə",
          prjKeyH: "Əsas xüsusiyyətlər",
          projWishLi1:
            "Mobil nömrə üçün telefon nömrəsi, gmail və OTP verifikasiyası ilə imzanı yerinə yetirə bilər.",
          projWishLi2:
            "Ad günü şənliklərinin təşkil edilməsi və istəklərin göndərilməsi.",
          projWishLi3:
            "Tədbirin tarixindən əvvəl xəbər almaq və insanlara xatırlatmaq.",
          projWishLi4:
            "Alexa səs köməkçisi qarşıdan gələn ad günləri üçün tədbirlər yaratmağı asanlaşdırır.",
          projWishLi5: "Alexa səs köməkçisi ilə hadisələri idarə et.",
          projWishLi6:
            "İsmarış, whatsapp və poçt vasitəsilə istəkləri göndərin.",
          projWishLi7:
            "Maraqlı və custromized hədiyyələr ilə yanaşı, istəkləri göndər.",
          projWishLi8:
            "Pin koddan istifadə edərək hədiyyə mağazasının mövcudluğunu yoxlayın.",
          projWishLi9: "İstəkləri öz özəl videolarınızla göndərin.",
          projWishLi10:
            "Dəvətnaməni asan və az vaxt ərzində qrup insanlara göndərin.",
          projWishLi11:
            "Kuponlardan istifadə edərək maraqlı təkliflər əldə edin.",
          projWishLi12: "Bütün məhsullar üçün mükafat təklif.",
          projWishLi13: "ülgücPay vasitəsilə asanlıqla online ödə.",
          projDry0H: "Müştəri",
          projDryoPara: "Dryo Laundry",
          projDryoDurH: "Davametmə müddəti",
          projDryoDurPara: "3 Ay",
          projDryoDateH: "Tarix",
          projDryoDatePara: "Gedişlər",
          projDryoCateH: "Kateqoriya",
          projDryoCatePara: "Biznes Xidməti",
          projDryoKeyLi1H: "Rahatlıq:",
          projDryoKeyLi1Para:
            "Dryo, istifadəçilərə camaşırxana pikapı və çatdırılmasını öz vaxtlarında və məkanında planlaşdırmağa imkan verərək, camaşırxana prosesinə rahatlıq gətirir. Bu, istifadəçilərin fiziki camaşırxanalara baş çəkməsi və ya özləri camaşırxana ilə məşğul olmağa vaxt ayırması zərurətini aradan qaldırır.",
          projDryoKeyLi2H: "Vaxt qənaəti:",
          projDryoKeyLi2Para:
            "Dryo ilə istifadəçilər camaşırxana işlərinə sərf edəcəkləri vaxtı geri ala və ondan digər vacib işlər və ya istirahət üçün istifadə edə bilərlər. Bu xüsusilə məşğul peşə sahibləri, ailələr və het-tik qrafiki olan şəxslər üçün faydalıdır.",
          projDryoKeyLi3H: "Özəlləşdirmə:",
          projDryoKeyLi3Para:
            "Bu proqram özəlləşdirmə variantları təklif edir, istifadəçilərə detergent növü, parça yumşaldıcı, su temperaturu və daha çox kimi camaşırxana üstünlüklərini müəyyən etməyə imkan verir. Bu, camaşırxananın hər bir yükünün istifadəçinin üstünlük və ehtiyaclarına uyğun olmasını təmin edir.",
          projDryoKeyLi4H: "Keyfiyyətli xidmət:",
          projDryoKeyLi4Para:
            "Dryo etibarlı və yoxlanılmış camaşırxana müəssisələri ilə əməkdaşlıq edərək yüksək keyfiyyətli camaşırxana xidmətini təmin edir. Bu, istifadəçilərin hər dəfə təmiz və yaxşı qulluq edilən camaşırxana almasına zəmanət verir.",
          projDryoKeyLi5H: "Şəffaflıq:",
          projDryoKeyLi5Para:
            "To app camaşırxana prosesi boyunca şəffaflığı təmin edir, istifadəçilərə pikapdan çatdırılmaya qədər camaşırxanalarının vəziyyətini izləməyə imkan verir. Bu şəffaflıq istifadəçilərə geyimlərinin təhlükəsizliyi və təhlükəsizliyi ilə bağlı etibar və inam aşılayır.",
          projDryoKeyLi6H: "Ətraf mühitə münasib:",
          projDryoKeyLi6Para:
            "Dryo ətraf mühitin təhlükəsiz detergentlərindən və enerjiyə qənaət edən yuyucu vasitələrdən istifadə etmək kimi eko-dost camaşırxana variantları təklif etməklə davamlılığı təmin edir. Bu, ətraf mühitə həssas olan, ekoloji cəhətdən faydalı məşğuliyyətlərə üstünlük verən istifadəçilərə müraciət edir.",
          projDryoKeyLi7H: "Ucuzluq:",
          projDryoKeyLi7Para:
            "Xidmətin rahatlığına və keyfiyyətinə baxmayaraq, Dryo ucuz olaraq qalır, ənənəvi camaşırxana xidmətləri ilə müqayisədə rəqabətli qiymətlər təklif edir. Bu isə peşəkar camaşırxana xidmətini daha geniş istifadəçilər üçün əlçatan edir.",
          projDryoKeyLi8H: "İstifadəçi dostu interfeys:",
          projDryoKeyLi8Para:
            "Bu proqramda naviqasiya asan olan istifadəçi dostu interfeysi mövcuddur. Camaşırxanaların rezervasiyası prosesi sadə və hər yaşdan və texnoloji mənşəli istifadəçilər üçün intuitivdir.",
          projBaliH: "Müştəri",
          projBaliPara: "Balitek",
          projBaliDurH: "Davametmə müddəti",
          projBaliDurPara: "2 Aylar",
          projBaliDateH: "Tarix",
          projBaliDatePara: "29 Fev 2024",
          projBaliCateH: "Kateqoriya",
          projBaliCatePara: "İt həllər",
          projBaliKeyLi1H: "Bussiness IT Consulting:",
          projBaliKeyLi1Para:
            "İt infrastrukturunuzu biznes məqsədlərinizlə eyniləşdirmək üçün strateji anlayışlarımızdan istifadə edin. Bizim məsləhətçilərimiz sizin texnologiya strategiyanızı maksimum effektivlik üçün optimallaşdırmaq üçün əməkdaşlıq edirlər.",
          projBaliKeyLi2H: "İdarə olunan İt Xidmətlər:",
          projBaliKeyLi2Para:
            "İt əməliyyatlarınızı mütəxəssislərimizə həvalə edin. Bizim Managed IT Services proaktiv monitorinq, təmir və dəstək təmin edir. Bu, sizin əsas biznes funksiyalarına diqqət yetirmək imkanı verir.",
          projBaliKeyLi3H: "Bulud Həlləri:",
          projBaliKeyLi3Para:
            "Buludun elastikliyini və ölçeklenebilirliyini uyğunlaşdırılmış həllərimizlə qucaqla. Miqrasiyadan optimallaşdırmaya qədər bulud texnologiyasının tam potensialından istifadə etmək üçün hər addımda sizə yol göstərək.",
          projBaliKeyLi4H: "İt Təhlükəsizliyi:",
          projBaliKeyLi4Para:
            "Bizim möhkəm İt təhlükəsizlik tədbirlərimizlə rəqəmsal aktivlərinizi qoruyun. Biznesinizi inkişaf edən kiber təhlükələrdən qorumaq üçün kəsici texnologiyaları və sənayenin ən yaxşı üsullarını işə götürürük.",
          projBaliKeyLi5H:
            "Şəbəkə İnfrastrukturun Layihələndirilməsi və Həyata Keçirilməsi:",
          projBaliKeyLi5Para:
            "Biznes ehtiyaclarınıza uyğun möhkəm və ölçeklenebilir bir şəbəkə infrastrukturu inşa edin. Mütəxəssislərimiz tikişsiz əlaqəni və optimal performansı təmin edən həlləri layihə və tətbiq edir.",
          projBaliKeyLi6H: "Proqram təminatı inkişafı:",
          projBaliKeyLi6Para:
            "Özəl proqram təminatının hazırlanması xidmətlərimizlə ideyalarınızı reallığa çevirin. Biz sizin unikal tələblərinizə cavab vermək üçün ölçülü, keyfiyyətli və istifadəçi dostu həllər yaratmaqda ixtisaslaşırıq.",
          projBaliKeyLi7H: "Tətbiqi inkişaf:",
          projBaliKeyLi7Para:
            "Bespoke tətbiqi inkişaf xidmətləri ilə digital landşaftda irəli qalın. Mobil proqramlardan müəssisə həllərinə qədər yenilikləri hərəkətə gətirən və istifadəçi təcrübələrini artıran tətbiqlər istehsal edirik.",
          projBaliKeyLi8H: "Veb-sayt inkişafı:",
          projBaliKeyLi8Para:
            "Web inkişaf xidmətlərimizlə güclü onlayn iştirak təşkil. Bizim bacarıqlı developers vizual çarpıcı və funksional veb-saytlar yaradır, müsbət və maraqlı istifadəçi təcrübəsini təmin edir.",
          projPayH: "Müştəri",
          projPayPara: "Atlanta",
          projPayDUrH: "Davametmə müddəti",
          projPayDurPara: "6 Aylar",
          projPayDateH: "Tarix",
          projPayDatePara: "Gedişlər",
          projPayCateH: "Kateqoriya",
          projPayCatePara: "Hr Payroll Management",
          projPayKeyLi1H: "Avtomatlaşdırılmış Hesablamalar:",
          projPayKeyLi1Para:
            "Maaş ərizələri işçilərin əmək haqqı, vergilər, ixtisarlar və mənfəətlə bağlı kompleks hesablaşmaları avtomatlaşdırmağa xidmət edir. Bu, insan xətası ehtimalını azaldır və işçilərə dəqiq ödəniş edilməsini təmin edir.",
          projPayKeyLi2H: "Vaxt effektivliyi:",
          projPayKeyLi2Para:
            "Məvacib proseslərini avtomatlaşdırma ilə tətbiq HR və maliyyə departamentləri üçün vaxta qənaət edir. Bunun sayəsində onlar hər bir maaş müddəti ərzində saatlarla əllə işlənmədən başqa strateji işlərə də diqqət yetirə bilərlər.",
          projPayKeyLi3H: "Vergiyə Uyğunluq:",
          projPayKeyLi3Para:
            "Maaş ərizələri tez-tez vergi masalarını və qaydalarını özündə birləşdirir. Bu, maaş vergilərinin dəqiq hesablanmasını və şirkətin bütün səviyyələrdə (yerli, ştat, federal) vergi qanunlarına uyğunluğunu təmin edir.",
          projPayKeyLi4H: "Birbaşa Depozit:",
          projPayKeyLi4Para:
            "Bir çox maaş ərizələri birbaşa depoziti asanlaşdırır və işçilərə maaşlarını birbaşa bank hesablarına almağa imkan verir. Bu isə kağız yoxlaması ehtiyacını aradan qaldırır və inzibati overhead-in azalmasına səbəb olur.",
          projPayKeyLi5H: "İşçi Özünə xidmət:",
          projPayKeyLi5Para:
            "Müasir maaş ərizələrində tez-tez işçilərin maaş stublarına, vergi formalarına daxil ola biləcəkləri özünəxidmət portalları daxil olur. Ünvan və ya bank hesabı detalları kimi şəxsi məlumatları yeniləmək, HR işçilərinin yükünü azaltmaq.",
          projPayKeyLi6H: "Customizable Hesabat:",
          projPayKeyLi6Para:
            "Payroll applications özəl hesabat xüsusiyyətləri təklif edir. Bu, bizneslərə maaş icmalları, vergi hesabatları, işçilərin qazanc bəyannamələri kimi müxtəlif hesabatların öz spesifik ehtiyaclarına uyğun olmasına imkan verir.",
          projPayKeyLi7H: "İnteqrasiya Bacarıqları:",
          projPayKeyLi7Para:
            "Bu tətbiqlər mühasibat proqram təminatı, vaxt və iştirak sistemləri və insan resurslarının idarə edilməsi sistemləri kimi digər sistemlərlə inteqrasiya edə bilər, məlumat axınını axıda bilər və redundant məlumat giriş aradan qaldırıla bilər.",
          projPayKeyLi8H: "Hərtərəfli Mənfəətin İdarə Edilməsi:",
          projPayKeyLi8Para:
            "Bəzi maaş ərizələrinə tibbi yardım, təqaüd planları və maaşlı istirahət kimi işçilərin mənfəətini idarə etmək üçün səciyyəvi xüsusiyyətlər daxildir. Bu, işçilərin kompensasiyasının bütün sahələrinin səmərəli şəkildə həll olunmasını təmin edir.",
          projPayKeyLi9H: "Tabeçilik xəbərdarlıqları:",
          projPayKeyLi9Para:
            "Maaş ərizələrinə tez-tez icra haqqında xəbərdarlıqlar və xatırlatmalar daxil olur ki, qarşıdan gələn müddətlər, reqlamentdə dəyişikliklər və ya əmək qanunvericiliyinə və qaydalarına riayət olunmasını qorumaq üçün tələb olunan hər hansı hərəkət barədə administratorlara məlumat verə.",
          projPayKeyLi10H: "Skalability:",
          projPayKeyLi10Para:
            "Payroll applications müəssisələrin inkişafı üçün nəzərdə tutulmuşdur. İstər şirkətin kiçik, istərsə də böyük olmasından asılı olmayaraq, ərizə performansını və səmərəliliyini qurban vermədən işçilərin və maaş komplekslərinin sayının artmasının öhdəsindən gələ bilər.",
          projCabsH: "Müştəri",
          projCabsPara: "Kerala Cabs",
          projCabsDurH: "Davametmə müddəti",
          projCabsDurPara: "Getmək üzrə",
          projCabsDateH: "Tarix",
          projCabsDataPara: "Gedişlər",
          projCabsCateH: "Kateqoriya",
          projCabsCatePara: "Taksi Xidməti",
          projCabsKeyLi1H: "Regional İnteqrasiya:",
          projCabsKeyLi1Para:
            "Kerala bölgəsi üçün xüsusi olaraq uyğunlaşdırılmış, şəhər və kənd yerləri boyunca seyrək naviqasiyanı təmin etmişdir, - deyə o qeyd etmişdir.",
          projCabsKeyLi2H: "Özəlləşdirilmiş Rezervasiya Seçimləri:",
          projCabsKeyLi2Para:
            "Point-to-point, saat icarəsi və outstation trips kimi müxtəlif rezervasiya variantları təmin edir, istifadəçilərin müxtəlif səyahət ehtiyaclarını təmin edir.",
          projCabsKeyLi3H: "Sürücü Yoxlaması:",
          projCabsKeyLi3Para:
            "Sərnişinlərin təhlükəsizliyinə və etibarlılığına zəmanət vermək üçün sürücülərin ətraflı arxa plan yoxlamalarını və yoxlanılmasını təmin edir.",
          projCabsKeyLi4H: "Real vaxt izləmə:",
          projCabsKeyLi4Para:
            "İstifadəçilərə proqramda öz taksilərini real vaxtda izləməyə imkan verir. Səyahət boyunca şəffaflıq və qəlb rahatlığı təmin edir.",
          projCabsKeyLi5H: "Driver Ratings və Reviews:",
          projCabsKeyLi5Para:
            "Səyahətdən sonra sərnişinlərin sürücüləri qiymətləndirilməsi və nəzərdən keçirmələrinə şərait yaradır, məsuliyyətin artırılması və xidmətin keyfiyyət standartlarının qorunması.",
          projCabsKeyLi6H: "Yerli Cazibələrlə İnteqrasiya:",
          projCabsKeyLi6Para:
            "Yerli turistik məkanlar üçün tövsiyələr və inteqrasiya olunmuş rezervasiya xidmətləri təklif edir, Keralaya gələn qonaqlar üçün ümumi səyahət təcrübəsini artırır.",
          projCabsKeyLi7H: "Fare Şəffaflığı:",
          projCabsKeyLi7Para:
            "Məsafə və zamana əsaslanan ön pul hesablamalarını nümayiş etdirir, şəffaflığı təmin edir və sərnişinlər üçün sürprizlərdən qaçır.",
          projCabsKeyLi8H: "Promosyonlar və Endirimlər:",
          projCabsKeyLi8Para:
            "İstifadəçilərə mütəmadi olaraq promosyonlar və endirimlər təqdim edir, ridesləri daha münasib edir və müştərilərə sadiqliyi təşviq edir.",
          projCabsKeyLi9H: "Nağdsız Ödənişlər:",
          projCabsKeyLi9Para:
            "Kredit/debet kartları, rəqəmsal pul kisələri və UPI kimi rahat nağdsız ödəniş variantlarını təklif edir. Nağd pul daşımaq və kontaktsız əməliyyatların təşkili ilə bağlı olan problem azalır.",
          projLogiH: "Müştəri",
          projLogiPara: "Al Razaa Logistics",
          projLogiDurH: "Davametmə müddəti",
          projLogiDurPara: "2 Ay",
          projLogiDateH: "Tarix",
          projLogiDatePara: "Gedişlər",
          projLogiCateH: "Kateqoriya",
          projLogiCatePara: "Logistika",
          projLogiKeyLi1H: "İstifadəçi dostu interfeysi:",
          projLogiKeyLi1Para:
            "Səmərəli göndərişlərin idarə edilməsi üçün asan istifadə platforması.",
          projLogiKeyLi2H: "Tez Göndərmə Rezervasiyası:",
          projLogiKeyLi2Para:
            "Sürətli və hassle-free rezervasiya üçün streamlined prosesi.",
          projLogiKeyLi3H: "Real Vaxt İzləmə:",
          projLogiKeyLi3Para:
            "Canlı göndərmə monitorinqi üçün qabaqcıl izləmə sistemləri.",
          projLogiKeyLi4H: "Özəlləşdirilmiş Həllər:",
          projLogiKeyLi4Para:
            "Müxtəlif biznes ehtiyacları üçün uyğunlaşdırılmış logistika variantları.",
          projLogiKeyLi5H: "Şəffaf Qiymətləndirmə",
          projLogiKeyLi5Para:
            "Aydın qiymət strukturları və ön qiymət hesablamaları.",
          projLogiKeyLi6H: "24/7 Müştəri dəstəyi:",
          projLogiKeyLi6Para:
            "İstifadəçilərin sorğu və məsələləri üçün saat ərzində yardım.",
          projLogiKeyLi7H: "Təchizat Zənciri Görünüş:",
          projLogiKeyLi7Para:
            "Əməkdaşlığın və planlaşdırmanın təkmilləşdirilməsi üçün artırılmış görünüş.",
          projLogiKeyLi8H: "Risklərin İdarə Edilməsi:",
          projLogiKeyLi8Para:
            "Göndərişlərin mühafizəsi və risklərin idarə edilməsi üçün ciddi tədbirlər görülür.",
          projLogiKeyLi9H: "Ətraf Mühitin Davamlılığı:",
          projLogiKeyLi9Para:
            "Eko-dost məşğuliyyətlərə və həll yollarına sadiqlik.",
          projLogiKeyLi10H: "Davamlı Təkmilləşdirmə:",
          projLogiKeyLi10Para:
            "Daha yaxşı xidmət və təcrübə üçün feedback-driven artırılması.",
          projOvanH: "Müştəri",
          projOvanPara: "Ovantica",
          projOvanDurH: "Davametmə müddəti",
          projOvanDurPara: "6 ay",
          projOvanDateH: "Tarix",
          projOvanDatePara: "20 Apr 2017",
          projOvanCateH: "Kateqoriya",
          projOvanCatePara: "Ekommers",
          projOvanKeyLi1H: "İstifadəçi dostu interfeysi:",
          projOvanKeyLi1Para:
            "Intuitiv veb-sayt ... asan naviqasiya və tikişsiz istifadəçi təcrübəsi üçün proqram dizaynı.",
          projOvanKeyLi2H: "Məhsul Siyahıları:",
          projOvanKeyLi2Para:
            "Spesifikasiyalar, şərt və qiymətlər daxil olmaqla, ətraflı təsvirlərə malik olan secondhand electronics-in kompleks siyahıları.",
          projOvanKeyLi3H: "Axtarış və Filtr Seçimləri:",
          projOvanKeyLi3Para:
            "İstifadəçilərə xüsusi elektronika modellərini, markaları, şərtləri və s. tapmaqda kömək etmək üçün qabaqcıl axtarış və filtr funksiyası, tez bir zamanda.",
          projOvanKeyLi4H: "Zəmanət seçimi:",
          projOvanKeyLi4Para:
            "Alıcılara inam aşılamaq və məhsulun keyfiyyətinə zəmanət vermək üçün ikinci el telefonlar üçün zəmanət variantları təklif edin.",
          projOvanKeyLi5H: "Köhnə elektronika xüsusiyyətinizi satın:",
          projOvanKeyLi5Para:
            "İstifadəçilərin köhnə elektronikalarını satışa asanlıqla sadalaya biləcəkləri həsr olunmuş bölmə. O cümlədən, şərti təsvir etmək, şəkillər yükləmək və qiymət təyin etmək variantları.",
          projOvanKeyLi6H: "Təhlükəsiz Əməliyyatlar:",
          projOvanKeyLi6Para:
            "Əməliyyatlar zamanı istifadəçilərin maliyyə məlumatlarını qorumaq üçün təhlükəsiz ödəniş dəhlizlərini həyata keçirin və məlumatların şifrlənməsini təmin edin.",
          projOvanKeyLi7H: "Keyfiyyət Zəmanəti:",
          projOvanKeyLi7Para:
            "Keyfiyyət standartlarına cavab verməsi üçün platformada siyahıya alınmazdan əvvəl bütün ikincili elektronika üçün ciddi keyfiyyət yoxlamaları və yoxlamaları həyata keçirin.",
          projOvanKeyLi8H: "Müştəri Dəstəyi:",
          projOvanKeyLi8Para:
            "Canlı söhbət, e-poçt və ya telefon dəstəyi kimi responsive müştəri dəstək kanalları təklif edin, hər hansı bir sorğu və ya məsələ ilə bağlı istifadəçilər kömək.",
          projOvanKeyLi9H: "Promosyonlar və Endirimlər:",
          projOvanKeyLi9Para:
            "Müştəriləri cəlb etmək və təkrar alış-verişi təşviq etmək üçün promosyon müqavilələri, endirimlər və sədaqət mükafatlarını təklif etmək.",
          projPureH: "Müştəri",
          projPurePara: "Rice Purity Club",
          projPureDurH: "Davametmə müddəti",
          projPureDurPara: "1 ay",
          projPureDateH: "Tarix",
          projPureDatePara: "15 Apr 2024",
          projPureCateH: "Kateqoriya",
          projPureCatePara: "Əyləncə",
          projPureKeyLi1H: "Sorğu kitabçası:",
          projPureKeyLi1Para:
            "İmtahan bir sıra çox seçimli suallar və ya bildirişlər olan sorğu kitabçası kimi təqdim olunur.",
          projPureKeyLi2H: "Böyük Sual Bank:",
          projPureKeyLi2Para:
            "Əsasən cinsi əlaqə, narkotik, münasibət və şəxsi davranış kimi geniş mövzuları əhatə edən çoxlu sayda suallar ehtiva edir.",
          projPureKeyLi3H: "Self-Grading System:",
          projPureKeyLi3Para:
            "After completing the questionnaire, respondents can submit their answers and receive an instant purity score based on their responses.",
          projPureKeyLi4H: "Gizlilik Seçimləri:",
          projPureKeyLi4Para:
            "Bəzi veb-saytlar gizlilik variantları təklif edir. Bunun sayəsində istifadəçilər nəticələrini açıq şəkildə paylaşmadan imtahanı anonim şəkildə qəbul edə bilərlər.",
          projLetsH: "Gəl, 'Xəyalını Qur' layihəsini edək",
          projLetsPara:
            "Müştərilərinizi heyran edən və fərqlənən bir veb-saytın olmasını istəyirsiniz? Onda biz kömək etməyə hazırıq! Bizimlə əlaqə saxlamaq və fikirlərinizi müzakirə etmək üçün aşağıdakı düyməni basın.",
          projLetsBtn: "Başlayaq",

          projSpeedH: "Müştəri",
          projSpeedPara: "SPEED-P",
          projSpeedDurH: "Müddət",
          projSpeedDurPara: "2 Ay",
          projSpeedDateH: "Tarix",
          projSpeedDatePara: "15 Aprel 2024",
          projSpeedCateH: "Kateqoriya",
          projSpeedCatePara: "Satış",
          projSpeedKeyLi1H: "Geniş analitika:",
          projSpeedKeyLi1Para:
            "Müxtəlif biznes funksiyaları üzrə ətraflı məlumat və hesabatlar təqdim edir, məlumat əsaslı qərar qəbul etməyə imkan yaradır.",
          projSpeedKeyLi2H: "İrəli Planlaşdırma və Cədvəlləşdirmə:",
          projSpeedKeyLi2Para:
            "Resursların bölüşdürülməsini və layihə cədvəllərini optimallaşdıraraq əməliyyat səmərəliliyini və məhsuldarlığı artırır.",
          projSpeedKeyLi3H: "Müəssisə Aktivlərinin İdarə Edilməsi:",
          projSpeedKeyLi3Para:
            "Fiziki aktivləri izləyir və idarə edir, onların optimal istifadə və texniki xidmətini təmin edir.",
          projSpeedKeyLi4H: "Müştəri Münasibətləri İdarəetməsi (CRM):",
          projSpeedKeyLi4Para:
            "Müştəri əlaqələrini, satış proseslərini və müştəri xidmətlərini idarə edərək müştəri məmnuniyyəti və saxlama səviyyəsini artırır.",
          projSpeedKeyLi5H: "Təchizat Zəncirinin İdarə Edilməsi:",
          projSpeedKeyLi5Para:
            "Təchizat zənciri əməliyyatlarını, o cümlədən satınalma, inventar idarəetmə və logistikayı sadələşdirərək xərcləri azaldır və səmərəliliyi artırır.",
          projSpeedKeyLi6H: "İnsan Kapitalının İdarə Edilməsi (HCM):",
          projSpeedKeyLi6Para:
            "İşçi həyat dövrü proseslərini, işə qəbul və işə alışdan tutmuş performans idarəetməsi və əmək haqqı ödəməsinə qədər idarə edir.",
          projSpeedKeyLi7H: "İstehsalın İdarə Edilməsi:",
          projSpeedKeyLi7Para:
            "İstehsal planlaşdırılması, icrası və monitorinqini asanlaşdıraraq istehsal proseslərinin səmərəliliyini və keyfiyyət standartlarına cavab verməsini təmin edir.",
          projSpeedKeyLi8H: "Maliyyə İdarəetməsi:",
          projSpeedKeyLi8Para:
            "Maliyyə əməliyyatlarını, o cümlədən mühasibatlıq, büdcə tərtibi və maliyyə hesabatlarını idarə edərək maliyyə sağlamlığını və uyğunluğunu təmin edir.",
          //Meat market
          projMMH: "Müştəri",
          projMMPara: "Ət Bazarı",
          projMMDurH: "Müddət",
          projMMDurPara: "2 Ay",
          projMMDateH: "Tarix",
          projMMDatePara: "10 Avqust 2024",
          projMMCateH: "Kateqoriya",
          projMMCatePara: "Elektron Ticarət",
          projMMKeyLi1H: "Fərdiləşdirilə Bilən Çatdırılma Cədvəlləri:",
          projMMKeyLi1Para:
            "Müştərilərə təzə ətin vaxtında çatdırılmasını təmin etmək üçün uyğun çatdırılma vaxtını seçməyə icazə verin.",
          projMMKeyLi2H: "Real-vaxtlı Sifariş İzləmə:",
          projMMKeyLi2Para:
            "Müştəriləri məlumatlandırmaq və əlaqədə saxlamaq üçün hazırlıqdan qapıya çatdırılmaya qədər sifarişlərin canlı izlənilməsini təmin edin.",
          projMMKeyLi3H: "Eksklüziv Üzv Endirimləri:",
          projMMKeyLi3Para:
            "Tez-tez alış-veriş edənlər üçün sevdikləri məhsullarda xüsusi təkliflər və endirimlərə giriş imkanı verən bir sadiqlik proqramı yaradın.",
          projMMKeyLi4H: "Təzəlik Zəmanəti:",
          projMMKeyLi4Para:
            "Ən yüksək keyfiyyətli və təzə ətə zəmanət verin və standartlara uyğun gəlmədiyi halda açıq qaytarma siyasəti təqdim edin.",
          projMMKeyLi5H: "Toplu Sifariş Endirimləri:",
          projMMKeyLi5Para:
            "Ailələrə və ya toplu alıcılara qənaət etmələri üçün daha böyük sifarişlər üçün xüsusi qiymət təklif edin.",
          projMMKeyLi6H: "Elastik Ödəniş Seçimləri:",
          projMMKeyLi6Para:
            "Müştərilərin rahatlığı üçün yerində ödəniş, kredit/debit kartları və rəqəmsal cüzdanlar daxil olmaqla müxtəlif ödəniş üsullarını dəstəkləyin.",
          //RG
          projrgH: "Müştəri",
          projrgPara: "RG Dizaynları",
          projrgDurH: "Müddət",
          projrgDurPara: "2 ay",
          projrgDateH: "Tarix",
          projrgDatePara: "10 Sentyabr 2024",
          projrgCateH: "Kateqoriya",
          projrgCatePara: "Elektron ticarət",
          projrgKeyLi1H: "Fərdi Naxış Rəqəmsallaşdırılması:",
          projrgKeyLi1Para:
            "RG Dizaynları istənilən loqo, şəkil və ya qrafikanı maşına hazır naxış faylına çevirmək üçün fərdiləşdirilmiş rəqəmsallaşdırma xidmətləri təklif edir. Bu, mükəmməl tikiş keyfiyyəti, dəqiqlik və bir neçə formatda miqyaslana bilən nəticəni təmin edir.",
          projrgKeyLi2H: "Müxtəlif Naxışlar:",
          projrgKeyLi2Para:
            "Platforma, bayramlar, təbiət və xüsusi tədbirlər də daxil olmaqla müxtəlif mövzulara uyğun olan geniş hazırlanmış naxış kitabxanası təqdim edir. Bu dizaynlar müxtəlif maşın növləri üçün optimallaşdırılıb, uyğunluğu və istifadənin asanlığını təmin edir.",
          projrgKeyLi3H: "İnkişaf etmiş Redaktə Alətləri:",
          projrgKeyLi3Para:
            "RG Dizaynları müştərilərə tikiş növlərini, rəngləri və naxışları xüsusi layihə ehtiyaclarına uyğunlaşdırmağa imkan verən güclü onlayn redaktə alətləri təqdim edir.",
          projrgKeyLi4H: "Yüksək Həlli Tikiş:",
          projrgKeyLi4Para:
            "Bütün naxışlar yüksək həlli tikiş naxışları ilə hazırlanmışdır, istər parçalar, istərsə də daha mürəkkəb tekstillər olsun, təmiz və peşəkar nəticələri təmin edir.",
          projrgKeyLi5H: "Toplu Dizayn Seçimləri:",
          projrgKeyLi5Para:
            "Böyük miqyaslı layihələr üçün RG Dizaynları, müştərilərə kolleksiyaları rəqabətli qiymətlərlə almağa imkan verən toplu dizayn seçimləri təklif edir ki, bu da bizneslər və ya peşəkar naxışçılar üçün idealdır.",
          projrgKeyLi6H: "Sürətli Təslim Zamanı:",
          projrgKeyLi6Para:
            "RG Dizaynları sürətli və etibarlı rəqəmsallaşdırma xidmətləri təklif etməkdə qürur duyur, xüsusi sifarişlərin vaxtında emal olunmasını və çatdırılmasını təmin edərək sıx layihə vaxtlarına uyğun olur.",

          //Project end

          //Header Start
          navHome: "Ana səhifə",
          navServices: "Xidmətlər",
          navServicesSub1: "Veb-sayt inkişafı",
          navServicesSub2: "Mobil App inkişaf",
          navServicesSub3: "Proqram təminatı inkişafı",
          navServicesSub4: "DevOps",
          navServicesSub5: "Bulud Hesablama",
          navServicesSub6: "Rəqəmsal Marketinq",
          navProject: "Layihə",
          navAbout: "Haqqımızda",
          navContact: "Bağlan",
          navEnquiry: "Sorğu üçün",
        },
      },
      ar: {
        translation: {
          //Footer Start
          footerpara:
            "نحن شركة تطوير برمجيات تبني منتجات للشركات في جميع أنحاء العالم. لقد ساعدناهم على بناء منتجات أفضل وتحسين عملياتهم التجارية وتنمية أعمالهم.",
          quickLinkHead: "روابط سريعة",
          quickLink1: "تطوير المواقع الإلكترونية",
          quickLink2: "تطوير تطبيقات الجوال",
          quickLink3: "تطوير البرمجيات",
          quickLink4: "حوسبة سحابية",
          quickLink5: "التسويق الرقمي",
          quickLink6: "عن الشركة",
          quickLink7: "المشاريع",
          quickLink8: "ديف أوبس",
          quickLink9: "الاتصال",
          newsLetterHead: "الرساله الاخباريه",
          newspara: "قم بالغناء للحصول على المزيد من كل التحديثات",
          newsletterplaceHolder: "أدخل البريد الإلكتروني",
          newsletterBtn: "اشترك الآن",
          copyRights: "© حقوق الطبع والنشر 2024 ذا تك هورس. جميع الحقوق محفوظة", //Footer End
          //Home Start
          bannerSpan1: "20 عاما من الخبرة في حلول تصميم المواقع",
          bannerH1: "بناء حلول الغد الرقمية اليوم",
          getStartBtn: "ابدأ بنا",
          bannerimgSpan1: "تصميم المواقع",
          bannerSpan2: "20 عاما من الخبرة في الحلول الرقمية",
          bannerH2: "شركاؤك في التكنولوجيا في عالم رقمي",
          bannerimgSpan2: "تطور",
          coreFeatureH: "الميزات الأساسية",
          coreFeatureSub: "الخدمات التي نقدمها",
          coreCards1: "تصميم عصري متجاوب",
          coreCards2: "تخصيص قوي",
          coreCards3: "رسوم متحركة رائعة وحديثة",
          coreCards4: "الترميز الصديق لكبار المسئولين الاقتصاديين",
          coreCards5: "أفضل الدعم الفني",
          coreCards6: "تخطيطات متنوعة واختلاف المنظر",
          AllaboutH: "كل شيء عن ذا تك هورس",
          AllaboutSub: "أفضل حلول الوكالات الرقمية للنمو",
          missionH: "مهمة:",
          missionPara:
            "مهمتنا هي استخدام تكنولوجيا الذكاء الاصطناعي المتقدمة لإحداث ثورة في الصناعات وتحسين التجارب البشرية وبناء مستقبل أفضل. فريقنا الماهر ، ذو الجذور العميقة في كل من حلول تكنولوجيا المعلومات الكلاسيكية والحديثة والأعمال التجارية الدولية ، مكرس لتقديم خدمات من الدرجة الأولى على مستوى العالم. نهدف إلى أن نكون الشركة الرائدة الأكثر موثوقية في تعزيز التفاعلات البشرية من خلال التكنولوجيا المبتكرة والعمل الجماعي الاستثنائي.",
          visionH: "رؤية:",
          visionPara:
            "أن نكون روادا في حلول تكنولوجيا المعلومات القائمة على الذكاء الاصطناعي ، وتعزيز ثقافة الإبداع والعمل الجماعي والتعلم المستمر. نحن نميز أنفسنا من خلال شغفنا لتطوير حلول فعالة ودعم نمو عملائنا في كل خطوة على الطريق.",
          philosophyH: "فلسفة:",
          philosophyPara:
            "فلسفتنا هي إنشاء حلول الذكاء الاصطناعي تعزز القدرات البشرية وتحسن تجارب المستخدم. نحن نعطي الأولوية لممارسات الذكاء الاصطناعي الأخلاقية وخصوصية البيانات والاستدامة البيئية ، كل ذلك مع البقاء في طليعة البحث والابتكار الذكاء الاصطناعي. إن التزامنا بالتميز والعمل الجماعي يدعم ثقافة شركتنا ، ويؤثر على قراراتنا ويقود تطوير حلول الذكاء الاصطناعي التحويلية.",
          coredesignH: "التصميم الأساسي المميز",
          corededignSub: "ما نقدمه للشركات",
          core1: "تصميم وتطوير المواقع الإلكترونية",
          core2: "تطوير تطبيقات الجوال",
          core3: "تطوير البرمجيات",
          core4: "ديف أوبس",
          core5: "التسويق الرقمي",
          core6: "حوسبة سحابية",
          recentProjH: "المشاريع الحديثة",
          recentProjSub: "انظر إلى أحدث معرض الأعمال",
          wishitonH: "WishItOn نسيج",
          speedpH: "Speed-P نسيج",
          wishitonMobH: "WishItOn تطبيق الموبايل",
          workingProcessH: "آلية العمل",
          workingProcessSub: "كيف نعمل",
          infoGatherH: "جمع المعلومات",
          infoGatherPara:
            "نبدأ كل مشروع من خلال فهم شامل لاحتياجات العميل وأهدافه وتوقعاته.",
          PlanningH: "التخطيط والتحليل",
          PlanningPara:
            "بعد ذلك ، نقوم بتطوير خطة منظمة لتوجيه المشروع حتى الانتهاء ضمن الجدول الزمني والميزانية المتفق عليها. في هذه المرحلة الحرجة ، قم بتحليل المعلومات التي تم جمعها لتحديد التحديات والفرص المحتملة.",
          developH: "تطور",
          developPara:
            "ابدأ في إنشاء موقع الويب أو التطبيق باستخدام أفضل الممارسات في الترميز والتصميم.",
          testingH: "الاختبار والإطلاق",
          testingPara:
            "إجراء اختبار شامل لضمان أداء التطبيق أو موقع الويب بشكل جيد عبر جميع الأنظمة الأساسية وحالات الاستخدام المطلوبة. معالجة أي مشاكل تم العثور عليها أثناء مرحلة الاختبار. بمجرد اكتمال الاختبار ، سنشرع في الإطلاق.",
          HaveH: "لديك أي مشروع في الأذهان ?",
          Weare: "نحن نعمل على تحسين محركات البحث ?",
          formH: "هل لديك أي مشروع في الاعتبار! لا تتردد في الاتصال بنا أو",
          formHSpan: "قل مرحبا",
          locationLabel: "مكان",
          locationCon: "تشيناي,الهند",
          emailLabel: "عنوان البريد الإلكتروني",
          emailCon: "info@thetechhorse.com",
          phoneLabel: "رقم الهاتف",
          formHead: "أرسل لنا رسالة",
          fullNameHolder: "الاسم الكامل",
          emailHolder: "عنوان البريد الإلكتروني",
          subjectHolder: "موضوع",
          messageHolder: "اكتب رسالة",
          sendmesBtn: "ارسل رسائل", //Home end
          //Web Start
          webBannerH: "تصميم وتطوير المواقع الإلكترونية",
          webBannerSpan: "منة",
          webhome: "وطن",
          webTitle: "تصميم وتطوير المواقع الإلكترونية",
          webWhat: "ما نقدمه",
          webWhatH: "حلول ويب رائعة مصممة ل Excel",
          webWhatSpan:
            "استفد من قوة سنوات الخبرة مع The TechHorse ، شريكك في تصميم وتطوير مواقع الويب الرائدة. ارتق بتواجدك الرقمي من خلال خدماتنا المخصصة المصممة خصيصا لتلبية احتياجات عملك.",
          learnMoreBtn: "التعرف على المزيد",
          webTailor: "حلول ويب مصممة خصيصا للتفكير في المستقبل",
          webTailorPara:
            "في The TechHorse ، كل موقع هو تحفة فنية ، تم تصميمها بدقة مع وضع عميلك في الاعتبار. بالإضافة إلى الجماليات ، نتعمق في الفروق الدقيقة في تجربة المستخدم ، وكفاءة تحسين محركات البحث ، والدقة التقنية ليس فقط لبناء مواقع الويب ، ولكن التجارب الرقمية القوية.",
          webOurExpertise: "تشمل خبرتنا :",
          webListT1: "تصميم موقع متجاوب:",
          webListT1Para: "تصميمات سلسة تتكيف بسلاسة مع أي جهاز.",
          webListT2: "تصميم تجربة المستخدم:",
          webListT2Para: "واجهات بديهية تتفاعل وتسعد.",
          webListT3: "نظام إدارة المحتوى وتكامل التجارة الإلكترونية:",
          webListT3Para: "حلول مبسطة تعزز الوظائف وسهولة الإدارة.",
          webListT4: "استراتيجية محتوى الموقع:",
          webListT4Para:
            "أطر المحتوى الاستراتيجية التي تدفع المشاركة والتحويل.",
          webListT5: "عبر المتصفح واختبار النظام الأساسي:",
          webListT5Para: "ضمان أداء متسق عبر جميع نقاط اتصال المستخدم.",
          webListT6: "سهولة الاستخدام وتحليل المنافسة:",
          webListT6Para: "رؤى تبقيك في المقدمة في المشهد التنافسي.",
          strategyH: "إنشاء الاستراتيجية",
          strategyPara:
            "صياغة مخططك للنجاح من خلال أبحاث السوق الشاملة والمواءمة الاستراتيجية.",
          webdegH: "تصميم المواقع الإلكترونية",
          webdegPara: "تصور التصاميم المميزة التي تلخص جوهر علامتك التجارية.",
          webDevH: "تطور",
          webDevPara:
            "هندسة موقعك باستخدام أحدث التقنيات للحصول على وظائف مثالية.",
          projTestH: "اختبار المشروع",
          projTestPara:
            "اختبارات صارمة من قبل خبرائنا لضمان أداء لا تشوبه شائبة.",
          projLaunchH: "إطلاق المشروع",
          projLaunchPara: "إطلاق موقعك بدقة لإحداث تأثير قوي في السوق.",
          customwebSolH: "حلول الويب المخصصة",
          customwebSolPara:
            "ارتق بتواجدك الرقمي من خلال مواقع الويب المصممة بشكل فريد والتي تلبي احتياجات عملك. بالاستفادة من WordPress و HTML و CSS المتقدمين ، نضمن أن يكون موقعك سهل الإدارة ومصمما على النحو الأمثل لمحركات البحث.",
          customwebli1: "سهل التحرير وسهل الاستخدام لمحرك البحث",
          customwebli2: "قابل للتخصيص بدرجة عالية",
          customwebli3: "نظام إدارة المحتوى الأكثر شعبية في العالم",
          webTechFeatureH: "ميزات التكنولوجيا",
          webTechFeatureSub: "الميزات الحديثة ذات الإمكانات الكاملة",
          webResH: "تصميم متجاوب",
          webResSub: "تصميم ويب محسن لكل جهاز",
          webResPara:
            "يضمن تصميمنا سريع الاستجابة أن يوفر موقع الويب الخاص بك تجربة مستخدم استثنائية على كل جهاز. سيوفر موقعك وظائف سلسة واتساقا جماليا ، سواء تم الوصول إليه عبر سطح المكتب أو الكمبيوتر المحمول أو الجهاز اللوحي أو الهاتف الذكي.",
          webResli1: "واجهات متوافقة مع الجوال",
          webResli2: "التوافق العالمي",
          webResli3: "تحسين تجربة المستخدم وتحسين محركات البحث",
          webPostH:
            "سنفعل تحسين SEO مجاني على موقع الويب الخاص بك لحركة المرور العضوية",
          webPostPara:
            "يمكن أن تؤدي حركة المرور العضوية ، مدفوعة بتحسين محركات البحث الفعالة ، إلى زيادة بنسبة 40٪ في الإيرادات ، حيث يثق المستخدمون في المواقع ذات التصنيف العضوي أكثر من الإعلانات. بالإضافة إلى ذلك ، تشهد مواقع الويب ذات الوجود العضوي القوي معدل احتفاظ بالعملاء أعلى 5 مرات من تلك التي تعتمد فقط على حركة المرور المدفوعة.",
          createwebBtn: "إنشاء موقع الويب الخاص بك",
          webSupportMarkH: "الدعم والتسويق",
          webSupportMarkSub: "التسويق لدعم تصميم الويب الخاص بك",
          webSupportMarkPara:
            "لحسن الحظ ، نحن لسنا مجرد مصممين ومطورين هنا - نحن كتاب واستراتيجيون وتقنيون ومبدعون ، وكلنا نعمل لتحقيق نفس الهدف النهائي: نجاح عملائنا. كوكالة تسويق رقمي متكاملة الخدمات",
          webSeo: "خدمة تحسين محركات البحث",
          webcopywrite: "كوبيوريتنغ",
          webppc: "قدره شرائيه",
          webseoH: "خدمات تحسين محركات البحث",
          webseoPara:
            "إذا كنت تتطلع إلى قيادة السوق عبر الإنترنت ، فأنت بحاجة إلى استراتيجية تحسين محركات البحث الشاملة",
          webcopyH: "كوبيوريتنغ",
          webcopyPara:
            "قم بتضخيم علامتك التجارية والتحكم في المحادثة من خلال استراتيجية تسويق المحتوى الاستراتيجية ",
          webppcH: "الدفع لكل نقرة",
          webpccPara:
            "تدور إدارة الدفع لكل نقرة (PPC) حول تقديم الإعلان المناسب لعملائك المستقبليين في الوقت المحدد",
          webcraftH: "اصنع رؤيتك بدقة",
          webcraftPara:
            "هل أنت مستعد لتحويل وجودك الرقمي؟ في The Tech Horser ، نحن متخصصون في إنشاء مواقع ويب تبرز وتردد صداها بعمق مع جمهورك. دعونا نناقش كيف يمكننا تحقيق رؤيتك في الحياة. انقر أدناه للبدء.",
          webletstartBtn: "هيا بنا نبدأ",
          //Web End

          //Mobile Start
          mobileBannerH: "تطوير تطبيقات الجوال",
          mobileBannerSpan: "منة",
          mobilehome: "وطن",
          mobileTitle: "تطوير تطبيقات الجوال",
          mobilewhatH: "ما نقدمه",
          mobileWhatSUb: "التميز في تصميم وتطوير التطبيقات",
          mobileWhatPara:
            "تسخير 20 عاما من الخبرة الرائدة لصياغة تجارب رقمية استثنائية.",
          mobilerightPara:
            "يتجاوز تطوير التطبيقات الحديثة ما هو أبعد من إنشاء تطبيقات وظيفية. يتعلق الأمر بإنشاء تجربة مستخدم جذابة وتحسين الأداء ودمج التقنيات المتطورة. إليك ما نتخصص فيه:",
          mobileRightList1: "تصميم التطبيقات المتجاوبة",
          mobileRightList2: "ابتكار تجربة المستخدم",
          mobileRightList3: "حلول تكامل التطبيقات",
          mobileRightList4: "استراتيجية محتوى التطبيق",
          mobileRightList5: "اختبار شامل",
          mobileRightList6: "تحليل السوق وسهولة الاستخدام",
          mobilestrategyH: "صياغة الاستراتيجية",
          mobilestrategyPara:
            "صياغة استراتيجيات دقيقة لوضع تطبيقك لتحقيق نجاح السوق.",
          mobileappDesignH: "تصميم التطبيقات",
          mobileappDesignPara:
            "تطوير مفاهيم تصميم جذابة بصريا وسهلة الاستخدام.",
          mobileAppdevH: "تطوير التطبيقات",
          mobileAppdevPara: "إنشاء حلول تطبيقات قوية وقابلة للتطوير بدقة.",
          mobileApptestH: "اختبار التطبيق",
          mobileApptestPara: "ضمان أعلى أداء من خلال بروتوكولات اختبار صارمة.",
          mobileAppLaunchH: "إطلاق التطبيق",
          mobileAppLaunchPara:
            "نشر تطبيقك بشكل استراتيجي لتحقيق اختراق مثالي للسوق.",
          mobileflutterH:
            "Flutter و React native و Kotlin و Swift تطوير تطبيقات الأجهزة المحمولة",
          mobileflutterPara:
            "Flutter و React Native هي أطر عمل لتطوير الأجهزة المحمولة عبر الأنظمة الأساسية ، Kotlin و Swift هي لغات برمجة تستخدم بشكل أساسي لتطوير Android و iOS . لكل منها نقاط قوتها وحالات الاستخدام والميزات الخاصة بالنظام الأساسي",
          mobileflutterList1: "عبر منصة",
          mobileflutterList2: "تطوير واسع النطاق",
          mobileflutterList3: "كفاءة",
          mobileflutterList4: "الايجاز",
          mobileResH: "تصميم متجاوب",
          mobileResSub: "تصميم لأي تصميم تطبيق مستجيب لأي جهاز",
          mobileResPara:
            "يسمح تصميم التطبيق سريع الاستجابة لتطبيقك بالتكيف مع أي جهاز يتم عرضه وتوفير تجربة مثالية له. هذا يعني أن تطبيقك سيبدو رائعا ويعمل بشكل لا تشوبه شائبة للمستخدم على أي جهاز هاتف ذكي.",
          mobileReslist1: "متوافق مع الجوال",
          mobileReslist2: "تصميم لكل جهاز",
          mobileReslist3: "تجربة مستخدم إيجابية وتساعد على تحسين محركات البحث",
          mobileBoostH:
            "عزز ظهور تطبيقك - تم تضمين خدمات تحسين محركات البحث المجانية",
          mobileBoostPara:
            "قم بتشغيل تطبيقك بميزة تنافسية. نحن نقدم خدمات تحسين محركات البحث الحصرية والمجانية لتطبيقك على متجر Google Play ومتجر تطبيقات Apple ، مما يضمن وصوله إلى جمهوره وترتيبه بشكل بارز.",
          mobileCreateAppBtn: "أنشئ تطبيقك",
          mobileSeoH: "الدعم والتسويق",
          mobileSEOSub: "التسويق لدعم تصميم تطبيقك",
          mobileSeoPara:
            "لحسن الحظ ، نحن لسنا مجرد مصممين ومطورين هنا - نحن كتاب واستراتيجيون وتقنيون ومبدعون ، وكلنا نعمل لتحقيق نفس الهدف النهائي: نجاح عملائنا. كوكالة تسويق رقمي متكاملة الخدمات",
          mobileSeoSerH: "خدمة تحسين محركات البحث",
          mobileSeoCopyH: "كوبيوريتنغ",
          mobilePPCH: "قدره شرائيه",
          mobileSEOSERH: "خدمات تحسين محركات البحث",
          mobileSEOSERPara:
            "إذا كنت تتطلع إلى قيادة السوق عبر الإنترنت ، فأنت بحاجة إلى استراتيجية تحسين محركات البحث الشاملة",
          mobileSeoCopy: "كوبيوريتنغ",
          mobileSeoCopyPara:
            "قم بتضخيم علامتك التجارية والتحكم في المحادثة باستخدام استراتيجية تسويق المحتوى الاستراتيجي",
          mobileSeoPPC: "الدفع لكل نقرة",
          mobileSeoPPCPara:
            "تدور إدارة الدفع لكل نقرة (PPC) حول تقديم الإعلان المناسب لعملائك المستقبليين في الوقت المحدد",
          mobilenextappHead: "دعنا ننشئ تطبيقك التالي",
          mobilenextappPara:
            "هل أنت جاهز لتطبيق يبرز ويهيمن على السوق؟ نحن هنا لنجعل رؤيتك تنبض بالحياة. انقر أدناه لبدء تحويل أفكارك إلى نجاح رقمي.",
          mobileLanuchBtn: "أطلق رؤيتك", //Mobile End
          //Software Start
          softBannerH: "تطوير البرمجيات",
          softBannerSpan: "منة",
          softBannerHome: "وطن",
          softBannerHomeTitle: "تطوير البرمجيات",
          softCompanyH: "احصائيات الشركة",
          softCompanySub: "عزز رؤيتك من خلال الحلول البرمجية الرائدة",
          softCompanyPara:
            "حول أفكارك إلى حلول برمجية رائدة. تعد خدمات التطوير التي يقودها الخبراء لدينا بتنفيذ سلس ونتائج فائقة ، مما يعدك للنجاح - دون الحاجة إلى خلفية تقنية.",
          softWorkH: "آلية العمل",
          softWorkSub: "كيف نعمل",
          softwork1H: "فهم الرؤية",
          softwork1Para:
            "نبدأ بفهم أهدافك ومتطلباتك بدقة ، مما يضمن أساسا متينا لمشروعك",
          softwork2H: "مخطط نجاحك",
          softwork2Para:
            "يتعاون الاستراتيجيون والمطورون لدينا لصياغة خارطة طريق مفصلة تتوافق مع أهدافك",
          softwork3H: "صقل المفهوم",
          softwork3Para:
            "نقوم بتحليل وصقل التصميم لتلبية توقعاتك ومعاييرنا العالية",
          softwork4H: "التنفيذ بامتياز",
          softwork4Para:
            "بعد إجراء اختبارات صارمة للحصول على أداء لا تشوبه شائبة ، يتم تشغيل برنامجك ، وهو جاهز لإحداث تأثير",
          softharnessH: "استفد من أحدث البرامج لدفع أعمالك إلى الأمام",
          softharnessPara:
            "استفد من خبرتنا للتنقل في رحلة تطوير البرامج الخاصة بك بدقة. يتفوق فريقنا في صياغة استراتيجيات مصممة خصيصا لاحتياجاتك الفريدة ، ويرشدك لتحقيق أهداف عملك وتجاوزها.",
          softHarnessschBtn: "حدد موعدا لاستشارة مجانية",
          softHarright1H: "اتخاذ قرارات أكثر ذكاء",
          softHarright1Para:
            "استفد من قوة البيانات الضخمة لتعزيز عملية صنع القرار ودفع عملك إلى الأمام من خلال رؤى قابلة للتنفيذ.",
          softHarright2H: "إثبات أعمالك في المستقبل",
          softHarright2Para:
            "التكيف والازدهار في سوق متطورة من خلال استراتيجيات التحول الرقمي الشاملة الخاصة بنا.",
          softHarright3H: "التبسيط والتوسع",
          softHarright3Para:
            "تسريع دورة التطوير وتعزيز الكفاءة من خلال حلولنا القوية المستندة إلى السحابة.",
          softHarright4H: "حلول مخصصة، نتائج استثنائية",
          softHarright4Para:
            "إنشاء تطبيقات ويب أو جوال مؤثرة وقابلة للتطوير مصممة خصيصا لتلبية احتياجات عملك للحصول على تجارب مستخدم استثنائية.",
          softCurH1: "فضولي ما إذا كان",
          softCurSpan: "The TechHorse",
          softCurH2: "هو المناسب لمشروعك?",
          softCurSub: "Sإيكينغ بريميير لخدمات تطوير البرمجيات?",
          softCurPara1:
            "دعونا نفهم رؤيتك بالتفصيل. بعد مراجعة متطلباتك ، يمكننا تحديد موعد استشارة مجانية غير ملزمة لتحديد النطاق وتقديم استراتيجية تقديرية لضمان نجاحك.",
          softCurPara2:
            "كل مشروع فريد من نوعه ، وكذلك نهجنا. لهذا السبب ندعوك إلى استشارة مجانية مع أحد مطوري البرامج المخضرمين لدينا. تم تصميم هذه الجلسة لتكييف استراتيجياتنا وفقا لاحتياجاتك وإظهار كيف يمكن ل Tech Horser أن يحقق مشروعك.",
          softFormH: "أرسل لنا رسالة",
          softFullName: "الاسم الكامل",
          softemail: "Email Address",
          softSubject: "موضوع",
          softMeg: "اكتب رسالة",
          softSendBtn: "ارسل رسائل",
          softLetsH: "دعنا نصمم برنامجك الجديد",
          softLetsPara:
            "هل تريد أن يكون لديك برنامج يبرز ويثير إعجاب عملائك؟ ثم نحن على استعداد للمساعدة! انقر فوق الزر أدناه للاتصال بنا ومناقشة أفكارك.",
          softLetsBtn: "هيا بنا نبدأ", //Software end
          //Devops start
          devopsBannerH: "DevOps",
          devopsHome: "وطن",
          devopsHomeNav: "DevOps",
          devopsYRSEXP: "سنوات من الخبرة في حل DevOps",
          devopsSolH: "DevOps محاليل",
          devopsSolSub: "ارتق بعملياتك باستخدام هندسة Elite DevOps",
          devopsSolPara:
            "في The TechHorse ، نقوم بهندسة التميز. تم تصميم خدمات DevOps الخاصة بنا لتبسيط عملية تسليم البرامج الخاصة بك ، مما يضمن قابلية تطوير قوية وأمانا صارما للمؤسسات من جميع الأحجام. كن شريكا لنا لتحويل عملياتك بدقة وكفاءة.",
          devopsCliSta: "رضا العملاء",
          devopsMissH: "المهمة والرؤية",
          devopsMissSub: "نهدف إلى تقديم حلول إبداعية عالية الجودة",
          devopsMissPara:
            "في The TechHorse ، نقوم بصياغة حلول ويب مبتكرة بشكل فريد تحدد معايير الصناعة. فريقنا في طليعة التقدم التكنولوجي ، يحتضن ويدمج أحدث الابتكارات للارتقاء بوجودك الرقمي.",
          devopsSerH: "خدماتنا الشعبية",
          devopsSerSub: "خدمة DevOps مذهلة",
          devopsCloud1H: "الترحيل السحابي",
          devopsCloud1Para:
            "التركيز على توفير تخطيط جميل نظرة العميل جعل الاستيراد",
          devopsCloud2H: "الخدمات المصغرة",
          devopsCloud2Para:
            "التركيز على توفير تخطيط جميل نظرة العميل جعل الاستيراد",
          devopsCloud3H: "المراقبة والتسجيل",
          devopsCloud3Para:
            "التركيز على توفير تخطيط جميل نظرة العميل جعل الاستيراد",
          devopsCloud4H: "التسليم المستمر",
          devopsCloud4Para:
            "التركيز على توفير تخطيط جميل نظرة العميل جعل الاستيراد",
          devopswhyH: "لماذا أخترتنا",
          devopswhySub: "خدمات DevOps التي يمكنك الاعتماد عليها",
          devopslist1H: "حلول قابلة للتطوير",
          devopslist1Para:
            "تم تصميم استراتيجيات DevOps الخاصة بنا لقابلية التوسع ، ودعم النمو دون المساس بالأداء. نحن نضمن أن البنية التحتية الخاصة بك تتطور مع احتياجات عملك.",
          devopslist2H: "الأتمتة المتقدمة",
          devopslist2Para:
            "نحن نعطي الأولوية للأتمتة لتبسيط عملياتك ، من نشر التعليمات البرمجية إلى إدارة البنية التحتية ، وتقليل الأخطاء وزيادة الكفاءة.",
          devopslist3H: "التكامل المستمر والنشر المستمر (CI/CD)",
          devopslist3Para:
            "احتضن التسليم السريع من خلال حلول CI / CD الخاصة بنا التي تضمن أن تكون تحديثات البرامج الخاصة بك سلسة وآمنة ، مما يتيح وقتا أسرع للوصول إلى السوق واستمرارية خدمة قوية.",
          devopslist4H: "نماذج المشاركة المرنة",
          devopslist4Para:
            "من خلال نماذج المشاركة التي تركز على العميل ، يمكنك التوسع أو التقليل بناء على متطلبات المشروع ، مما يضمن التحكم الأمثل في ميزانياتك ومواردك.",
          devopsSupportList1: "التكامل المستمر",
          devopsSupportList2: "إدارة التكوين",
          devopsSupportList3: "الاختبار الآلي",
          devopsSupportList4: "أدوات التعاون",
          devopsSupportList5: "إدارة الإصدار",
          devopsSupportList6: "التحكم في الإصدار",
          devopsSupFeatureH: "الدعم والميزات",
          devopsSupFeatureSub: "ميزات هندسة DevOps المذهلة",
          devopsSupFeaturePara:
            "نحن نقدم خدمات DevOps شاملة تتيح وقتا أسرع للوصول إلى السوق ، وتعاونا محسنا ، وجودة محسنة للمنتج. يستفيد فريق الخبراء لدينا من أفضل ممارسات الصناعة والتقنيات المتطورة لمساعدتك على تحقيق أهداف DevOps بكفاءة وفعالية.",
          devopsLearnbtn: "اعرف المزيد عنا",
          devopsLifeH: "دورة حياة DevOps",
          devopslife1H: "أفرج",
          devopslife1Para:
            "نشر برامج أو تحديثات جديدة في بيئات الإنتاج لضمان التوافر السلس للمستخدمين النهائيين. تشمل هذه المرحلة الحاسمة جميع الخطوات التحضيرية اللازمة.",
          devopslife2H: "نشر",
          devopslife2Para:
            "تثبيت تحديثات البرامج أو التطبيقات الجديدة عبر بيئات الإنتاج أو التدريج أو الاختبار لإتاحة وظائف جديدة للاستخدام الفوري.",
          devopslife3H: "اشتغل",
          devopslife3Para:
            "إدارة وصيانة أنظمة البرمجيات طوال دورة حياتها. تدمج DevOps التطوير (Dev) والعمليات (Ops) لتعزيز التعاون والكفاءة.",
          devopslife4H: "مراقب",
          devopslife4Para:
            "الإشراف المستمر على أداء البرامج والبنية التحتية لضمان التشغيل الأمثل والموثوقية. تلعب المراقبة دورا حيويا في التحسين الاستباقي وسلامة النظام.",
          devopslife5H: "إطلاق المشروع",
          devopslife5Para: "إطلاق موقعك بدقة لإحداث تأثير قوي في السوق.",
          devopsEleH: "ارتق بعملياتك باستخدام DevOps الخبير",
          devopsElePara:
            "هل تحتاج إلى استراتيجية DevOps سلسة وفعالة وآمنة تتوافق مع أهداف عملك؟ نحن على استعداد لهندسة نجاحك. انقر فوق الزر أدناه لبدء تحسين عملياتك مع فريق الخبراء لدينا.",
          devopsEleBtn: "هيا بنا نبدأ",
          //Devops End

          //Cloud Start
          cloudBannerH: "سحابة",
          cloudBannerSpan: "الحوسبه",
          cloudBannerHome: "وطن",
          cloudBannerHomeSpan: "حوسبة سحابية",
          cloudCompH: "احصائيات الشركة",
          cloudCompSub: "أطلق العنان للأداء المتفوق مع حلول النخبة السحابية",
          cloudCompPara:
            "ارتق بأعمالك باستخدام الحوسبة السحابية عالية الأداء من TechHorse. توفر شبكتنا المتطورة تكاملا سلسا للخوادم وقواعد البيانات والبرامج والمزيد.",
          cloudSerH: "خدماتنا",
          cloudSerSub: "خدمة الحوسبة السحابية المذهلة",
          cloudSerli1H: "الخدمة الذاتية عند الطلب",
          cloudSerli1Para:
            "يتيح ذلك للعميل مراقبة وقت تشغيل الخادم وقدراته وتخزين الشبكة المخصص باستمرار.",
          cloudSerli2H: "خدمة القياس وإعداد التقارير",
          cloudSerli2Para:
            "يساعد في مراقبة الفواتير وضمان الاستخدام الأمثل للموارد.",
          cloudSerli3H: "تحليل البيانات الضخمة",
          cloudSerli3Para:
            "تشير تحليلات البيانات الضخمة إلى الأساليب والتطبيقات المستخدمة لجمع ومعالجة واستخلاص رؤى من مجموعات بيانات متنوعة وكبيرة الحجم وعالية السرعة.",
          cloudSerli4H: "أمن البيانات وقابلية التوسع",
          cloudSerli4Para:
            "لدى العديد من العملاء أعباء عمل يمكن تشغيلها بشكل فعال من حيث التكلفة بسبب قابلية التوسع السريع للحوسبة السحابية.",
          cloudwhyH: "لماذا أخترتنا",
          cloudwhySub: "حلول سحابية استثنائية يمكنك الوثوق بها",
          cloudwhyLi1H: "بنية قابلة للتطوير",
          cloudwhyLi1Para:
            "التكيف والنمو بلا حدود من خلال بنيتنا التحتية السحابية القابلة للتطوير التي تدعم عملك في كل مرحلة.",
          cloudwhyLi2H: "آمن وموثوق",
          cloudwhyLi2Para:
            "قم بحماية بياناتك من خلال بروتوكولات الأمان من الدرجة الأولى الخاصة بنا واستمتع بوقت تشغيل متسق مع خدماتنا السحابية الموثوقة.",
          cloudwhyLi3H: "تكامل سهل",
          cloudwhyLi3Para:
            "دمج حلولنا السحابية بسلاسة مع منصاتك الحالية من أجل تشغيل سلس وفعال ومتماسك.",
          cloudEveH: "ارتق بتواجدك الرقمي من خلال حلولنا السحابية",
          cloudEvePara:
            "قم بتحويل استراتيجيتك الرقمية من خلال بنيتنا التحتية السحابية القوية والقابلة للتطوير. انقر أدناه لبدء الابتكار.",
          cloudLetsbtn: "هيا بنا نبدأ",
          //Cloud end

          //Digital Start
          digiBannerH: "رقمي",
          digiBannerSpan: "تسويق",
          digiBannerHome: "وطن",
          digiBannerHomeNav: "التسويق الرقمي",
          digiWhatH: "ما الذي نفعله",
          digiWhatSub: "ارتق بعلامتك التجارية من خلال التسويق الرقمي الخبير",
          digiseoLi1H: "خدمات تحسين محركات البحث",
          digiseoLi1Para:
            "حقق أقصى قدر من ظهورك واحتل المرتبة الأولى من خلال حلول تحسين محركات البحث الاستراتيجية المصممة للنمو المستدام.",
          digiseoLi2H: "التسويق عبر وسائل التواصل الاجتماعي",
          digiseoLi2Para:
            "قم بإشراك جمهورك وتوسيعه من خلال حملات الوسائط الاجتماعية المخصصة التي يتردد صداها وتحولها.",
          digiseoLi3H: "إعلانات جوجل",
          digiseoLi3Para:
            "زيادة عدد الزيارات المستهدفة وتحقيق نتائج فورية باستخدام حملات المدارة بخبرة.",
          digiseoLi4H: "تصميم موقع مخصص",
          digiseoLi4Para:
            "صمم حضورا فريدا عبر الإنترنت من خلال تصميمات مواقع الويب المخصصة التي تأسر الزوار وتحولها.",
          digiseoLi5H: "شوبيفاي تصميم الموقع",
          digiseoLi5Para:
            "إطلاق متجر Shopify مذهل ومحسن للمبيعات مصمم خصيصا لاحتياجات علامتك التجارية.",
          digiseoLi6H: "جيل الرصاص",
          digiseoLi6Para:
            "توليد عملاء محتملين ذوي جودة عالية مع استراتيجيات محسنة للتحويل واكتساب العملاء.",
          digiseoLi7H: "تسويق المحتوى",
          digiseoLi7Para:
            "ضع علامتك التجارية كشركة رائدة في الصناعة من خلال محتوى مقنع يعلم ويلهم.",
          digiseoLi8H: "العلاقات العامة الرقمية",
          digiseoLi8Para:
            "عزز سمعتك ومدى وصولك من خلال خدمات العلاقات العامة الرقمية الشاملة التي تبني علامتك التجارية وتحميها.",
          digiseoLi9H: "العلامات التجاريه",
          digiseoLi9Para:
            "قم بإنشاء هوية علامة تجارية لا تنسى من خلال حلول العلامات التجارية الإبداعية التي تعبر عن روح عملك وقيمه.",
          digiSata1H: "حملات مصممة بشكل استراتيجي لتعزيز الرؤية",
          digiSata1Para:
            "أطلق العنان لإمكانات علامتك التجارية من خلال استراتيجيات التسويق الرقمي المتطورة المصممة خصيصا لزيادة تواجدك عبر الإنترنت وتحقيق نتائج مؤثرة.",
          digiSata2H: "دفع النمو من خلال الإعلانات الرقمية المستهدفة",
          digiSata2Para:
            "استفد من قوة إعلانات Google المستهدفة بدقة وتسويق المحتوى للوصول إلى عملائك المثاليين حيث يكونون أكثر نشاطا وتقبلا.",
          digiSata3H: "عزِّز علامتك التجارية من خلال الخدمات الرقمية الشاملة",
          digiSata3Para:
            "بدءًا من العلامة التجارية الأولية وحتى العلاقات العامة الرقمية، قم بإنشاء هوية متماسكة تتحدث عن قيمك وتلقى صدى لدى جمهورك المستهدف.",
          digiCoreH: "الميزات الأساسية",
          digiCoreSub: "ميزات أساسية مذهلة",
          digicore1H: "استراتيجية قائمة على البيانات",
          digicore1Para:
            "قم بتخصيص جهودك التسويقية بدقة باستخدام تحليلاتنا ومناهجنا القائمة على البيانات التي تضمن أقصى عائد استثمار وفعالية.",
          digicore2H: "حملات القناة متعددة الاتجاهات",
          digicore2Para:
            "دمج التسويق الخاص بك بسلاسة عبر جميع المنصات الرقمية ، مما يضمن تجربة موحدة للعلامة التجارية تأسر وتحول.",
          digicore3H: "إنشاء المحتوى الإبداعي",
          digicore3Para:
            "استفد من خدمات المحتوى المبتكرة لدينا لصياغة روايات مقنعة يتردد صداها مع جمهورك وتشجع على المشاركة.",
          digicore4H: "تقنيات تحسين محركات البحث المتقدمة",
          digicore4Para:
            "ارفع مستوى وجودك في محرك البحث من خلال أساليب تحسين محركات البحث المتطورة لدينا لزيادة حركة المرور وزيادة التحويلات.",
          digicore5H: "الإعلانات المستهدفة",
          digicore5Para:
            "الاستفادة من استهداف الإعلانات المتطور للوصول إلى العملاء المحتملين بشكل أكثر فعالية، وتعزيز توليد العملاء المحتملين والمبيعات.",
          digicore6H: "تقارير شاملة",
          digicore6Para:
            "ابق على اطلاع بالتقارير التفصيلية التي تتعقب تقدم الحملة ومقاييسها ، مما يتيح اتخاذ قرارات مستنيرة وتعديلات استراتيجية.",
          digiChooseH: "لماذا أخترتنا",
          digiChooseSub: "خبرة موثوقة في التسويق الرقمي",
          digiChoose1H: "نتائج مثبتة",
          digiChoose1Para:
            "سجلنا الحافل يتحدث عن نفسه. نحن نقدم تحسينات قابلة للقياس في حركة المرور والمشاركة والتحويلات ، مما يؤدي إلى نمو الأعمال الحقيقي.",
          digiChoose2H: "تحليلات شاملة",
          digiChoose2Para:
            "من خلال تحليلاتنا التفصيلية ، يمكنك الحصول على رؤى لم يسبق لها مثيل. سوف يساعدونك على فهم جمهورك وتحسين استراتيجيات التسويق الخاصة بك بشكل فعال.",
          digiChoose3H: "حلول مبتكرة",
          digiChoose3Para:
            "نبقى في الطليعة ، وننفذ أحدث اتجاهات وتقنيات التسويق الرقمي للحفاظ على علامتك التجارية ذات صلة وتنافسية.",
          digiunlockH: "أطلق العنان لإمكانياتك الرقمية: استشارة مجانية متاحة",
          digiunlockPara:
            "هل أنت مستعد لتحويل تواجدك عبر الإنترنت؟ ابدأ باستشارة مجانية! انقر أدناه لمناقشة رؤيتك واكتشاف استراتيجيات التسويق الرقمي المصممة خصيصا والتي تجعل موقع الويب الخاص بك متميزا ويثير إعجاب عملائك.",
          digiunlockBtn: "هيا بنا نبدأ",
          //Digital End

          //About Start
          abtBannerH: "عن",
          abtBannerSpan: "لنا",
          abtBannerHome: "وطن",
          abtBannerHomeNav: "من نحن",
          abtwhatH: "ما الذي نفعله",
          abtwhatSub: "نحن فريق تكنولوجيا المعلومات المهنية",
          abtwhatPara:
            "نحن شركة تطوير برمجيات تبني منتجات للشركات في جميع أنحاء العالم. لقد ساعدناهم على بناء منتجات أفضل وتحسين عملياتهم التجارية وتنمية أعمالهم. نحن مختلفون عن شركات تطوير البرمجيات الأخرى لأننا متحمسون لما نقوم به وكيف نقوم به. نحن نحب بناء المنتجات التي يمكن أن تساعد عملائنا على النجاح ونحب العمل معهم في كل خطوة على الطريق.",
          abtwhatLi1Para:
            "أن نكون شركة تصميم التجربة البشرية الأفضل والأكثر ثقة في العالم.",
          abtwhatLi2Para:
            "لتعزيز ورفع التفاعل البشري. السعي لتحقيق التميز من خلال العمل الجماعي المتفاني.",
          abtcreateBtn: "إنشاء موقع الويب الخاص بك",
          abtYearexp: "سنوات من الخبرة",
          abtexpH:
            "20 عاما من الخبرة في هذا العالم ، كونها الخيار الأول والأهم لرجال الأعمال.",
          abtexpLi1: "تطوير المواقع الإلكترونية",
          abtexpLi2: "تطوير البرمجيات",
          abtexpLi3: "حوسبة سحابية",
          abtexpLi4: "تطوير تطبيقات الجوال",
          abtexpLi5: "DevOps",
          abtexpLi6: "التسويق الرقمي",
          abtwebLi1H: "تصميم المواقع الإلكترونية",
          abtwebLi1Para:
            "تصور التصاميم المميزة التي تلخص جوهر علامتك التجارية.",
          abtwebLi2H: "تصميم التطبيقات",
          abtwebLi2Para: "تطوير مفاهيم تصميم جذابة بصريا وسهلة الاستخدام.",
          abtwebLi3H: "مخطط نجاحك",
          abtwebLi3Para:
            "يتعاون الاستراتيجيون والمطورون لدينا لصياغة خارطة طريق مفصلة تتوافق مع أهدافك.",
          abtwebLi4H: "تحسين لمحركات البحث",
          abtwebLi4Para:
            "حقق أقصى قدر من ظهورك واحتل المرتبة الأولى من خلال حلول تحسين محركات البحث الاستراتيجية المصممة لتحقيق النمو المستدام.",
          abtLetH: "دعنا نصمم موقع الويب الجديد الخاص بك",
          abtLetPara:
            "هل تريد أن يكون لديك موقع ويب يبرز ويثير إعجاب عملائك؟ ثم نحن على استعداد للمساعدة! انقر فوق الزر أدناه للاتصال بنا ومناقشة أفكارك.",
          abtLetBtn: "هيا بنا نبدأ",
          //About End

          //Contact start
          contactBannerH: "اتصال",
          contactBannerHome: "الرئيسية",
          contactBannerHomeNav: "اتصال",
          contactusH: "اتصل بنا",
          contactusSub: "لنبدأ مشروعًا جديدًا أو نعمل معًا! تواصل معنا",
          contactusPara:
            "اتصل بنا اليوم لبدء مشروع جديد أو التعاون في المبادرات الحالية. نحن متحمسون لسماع منك!",
          contactfullnamelabel: "الاسم الكامل",
          contactfullnamePlace: "أدخل اسمك",
          contactfullemaillabel: "عنوان البريد الإلكتروني",
          contactfullemailPlace: "أدخل بريدك الإلكتروني",
          contactfullphonelabel: "رقم الهاتف",
          contactfullphonePlace: "أدخل رقم هاتفك",
          contactfullSubjectlabel: "الموضوع",
          contactfullSubjectPlace: "الموضوع",
          contactfullmeglabel: "اكتب رسالة",
          contactfullmegPlace: "اكتب رسالة",
          contactSendbtn: "أرسل رسالة",
          contactLocH: "الموقع",
          contactLocPara: "رويابيتاه، تشيناي، تاميل نادو 600014",
          contactEmailH: "عنوان البريد الإلكتروني",
          contactPhoneH: "رقم الهاتف",
          contactFollowH: "تابعنا",
          contactUSAH: "الولايات المتحدة الأمريكية",
          contactUSLOc: "بوكا راتون، فل 33428",
          contactAus: "أستراليا",
          contactAusLOc: "ملبورن فيك 3016",
          contactDub: "دبي",
          contactDubLoc: "الراشدية 3 7613",
          //Contact end

          //Project start
          projBannerH: "بعض مشاريع عملائنا المرموقين",
          projBannerHome: "الرئيسية",
          projBannerHomeNav: "مشروع",
          projwishCLientH: "العميل",
          projwishCLientPara: "Wishiton",
          projwishDurH: "المدة",
          projwishDurPara: "4 أشهر",
          projwishDateH: "التاريخ",
          projwishDatePara: "5 نوفمبر 2023",
          projwishCateH: "الفئة",
          projwishCatePara: "الترفيه",
          prjKeyH: "الميزات الرئيسية",
          projWishLi1:
            "يمكن التسجيل باستخدام رقم الهاتف، جيميل، والتحقق من OTP لرقم الهاتف المحمول.",
          projWishLi2: "تنظيم احتفالات أعياد الميلاد وإرسال التهاني.",
          projWishLi3: "الحصول على إشعارات وتذكير الأشخاص قبل موعد الحدث.",
          projWishLi4:
            "يسهل مساعد الصوت أليكسا إنشاء أحداث لأعياد الميلاد القادمة.",
          projWishLi5: "إدارة الأحداث باستخدام مساعد الصوت أليكسا.",
          projWishLi6:
            "إرسال التهاني عبر الرسائل، الواتساب والبريد الإلكتروني.",
          projWishLi7: "إرسال التهاني مع هدايا مثيرة ومخصصة.",
          projWishLi8: "التحقق من توفر متجر الهدايا باستخدام الرمز البريدي.",
          projWishLi9: "إرسال التهاني مع فيديوهات مخصصة خاصة بك.",
          projWishLi10: "إرسال الدعوات لمجموعة من الأشخاص بسهولة وفي وقت أقل.",
          projWishLi11: "الحصول على عروض مثيرة باستخدام الكوبونات.",
          projWishLi12: "تقديم جوائز لجميع المنتجات.",
          projWishLi13: "الدفع بسهولة عبر الإنترنت باستخدام RazorPay.",
          projDry0H: "العميل",
          projDryoPara: "Dryo Laundry",
          projDryoDurH: "المدة",
          projDryoDurPara: "3 أشهر",
          projDryoDateH: "التاريخ",
          projDryoDatePara: "جارٍ العمل عليه",
          projDryoCateH: "الفئة",
          projDryoCatePara: "خدمة الأعمال",
          projDryoKeyLi1H: "الراحة:",
          projDryoKeyLi1Para:
            "يجلب Dryo الراحة لعملية الغسيل عن طريق السماح للمستخدمين بجدولة استلام وتسليم الغسيل في الوقت والمكان المفضلين لديهم. هذا يلغي الحاجة لزيارة مغاسل الملابس التقليدية أو قضاء الوقت في غسل الملابس بأنفسهم.",
          projDryoKeyLi2H: "توفير الوقت:",
          projDryoKeyLi2Para:
            "مع Dryo، يمكن للمستخدمين استعادة الوقت الذي كانوا سيقضونه في غسل الملابس واستخدامه في مهام أخرى مهمة أو أنشطة ترفيهية. هذا مفيد بشكل خاص للمحترفين المشغولين والأسر والأفراد ذوي الجداول الزمنية المزدحمة.",
          projDryoKeyLi3H: "التخصيص:",
          projDryoKeyLi3Para:
            "يقدم التطبيق خيارات تخصيص، مما يسمح للمستخدمين بتحديد تفضيلاتهم في الغسيل مثل نوع المنظف، منعم الأقمشة، درجة حرارة الماء والمزيد. هذا يضمن أن كل حمولة غسيل تكون مخصصة لتفضيلات واحتياجات المستخدم.",
          projDryoKeyLi4H: "خدمة عالية الجودة:",
          projDryoKeyLi4Para:
            "يضمن Dryo خدمة غسيل عالية الجودة من خلال الشراكة مع مرافق غسيل موثوقة ومراجعة. هذا يضمن أن يحصل المستخدمون على غسيل نظيف ومصان بشكل جيد في كل مرة.",
          projDryoKeyLi5H: "الشفافية:",
          projDryoKeyLi5Para:
            "يوفر التطبيق شفافية طوال عملية الغسيل، مما يسمح للمستخدمين بتتبع حالة غسيلهم من الاستلام حتى التسليم. هذه الشفافية تعزز الثقة والاطمئنان لدى المستخدمين بشأن سلامة وأمان ملابسهم.",
          projDryoKeyLi6H: "صديق للبيئة:",
          projDryoKeyLi6Para:
            "يشجع Dryo على الاستدامة من خلال تقديم خيارات غسيل صديقة للبيئة، مثل استخدام المنظفات الآمنة بيئيًا والغسالات التي تستهلك الطاقة بكفاءة. هذا يروق للمستخدمين الذين يهتمون بالبيئة ويفضلون الممارسات الصديقة للبيئة.",
          projDryoKeyLi7H: "القدرة على التحمل:",
          projDryoKeyLi7Para:
            "على الرغم من الراحة وجودة الخدمة، يبقى Dryo ميسور التكلفة، حيث يقدم أسعار تنافسية مقارنة بخدمات الغسيل التقليدية. هذا يجعل خدمة الغسيل الاحترافية في متناول مجموعة أوسع من المستخدمين.",
          projDryoKeyLi8H: "واجهة مستخدم سهلة الاستخدام:",
          projDryoKeyLi8Para:
            "يتميز التطبيق بواجهة مستخدم سهلة الاستخدام وسهلة التنقل، مما يجعل عملية حجز الغسيل بسيطة وسلسة للمستخدمين من جميع الأعمار والخلفيات التقنية.",

          projBaliH: "العميل",
          projBaliPara: "Balitek",
          projBaliDurH: "المدة",
          projBaliDurPara: "شهران",
          projBaliDateH: "التاريخ",
          projBaliDatePara: "29 فبراير 2024",
          projBaliCateH: "الفئة",
          projBaliCatePara: "حلول تكنولوجيا المعلومات",
          projBaliKeyLi1H: "استشارات الأعمال في تكنولوجيا المعلومات:",
          projBaliKeyLi1Para:
            "استفد من رؤيتنا الاستراتيجية لمواءمة البنية التحتية لتكنولوجيا المعلومات مع أهداف عملك. يعمل مستشارونا بشكل تعاوني لتحسين استراتيجيتك التكنولوجية لتحقيق أقصى كفاءة.",
          projBaliKeyLi2H: "خدمات تكنولوجيا المعلومات المدارة:",
          projBaliKeyLi2Para:
            "اعتمد على خبرائنا في تشغيل تكنولوجيا المعلومات. تضمن خدمات تكنولوجيا المعلومات المدارة لدينا المراقبة والصيانة والدعم الاستباقي، مما يتيح لك التركيز على وظائف عملك الأساسية.",
          projBaliKeyLi3H: "الحلول السحابية:",
          projBaliKeyLi3Para:
            "تبنّى المرونة والقابلية للتوسع في السحابة مع حلولنا المخصصة. من الانتقال إلى التحسين، نرشدك خلال كل خطوة للاستفادة الكاملة من تكنولوجيا السحابة.",
          projBaliKeyLi4H: "أمن تكنولوجيا المعلومات:",
          projBaliKeyLi4Para:
            "حماية أصولك الرقمية مع إجراءات الأمان القوية لدينا في تكنولوجيا المعلومات. نستخدم أحدث التقنيات وأفضل الممارسات الصناعية لحماية عملك من التهديدات الإلكترونية المتطورة.",
          projBaliKeyLi5H: "تصميم وتنفيذ بنية الشبكة:",
          projBaliKeyLi5Para:
            "بناء بنية تحتية قوية وقابلة للتوسع تلبي احتياجات عملك. يصمم خبراؤنا وينفذون الحلول التي تضمن الاتصال السلس والأداء الأمثل.",
          projBaliKeyLi6H: "تطوير البرمجيات:",
          projBaliKeyLi6Para:
            "حوّل أفكارك إلى واقع مع خدماتنا لتطوير البرمجيات المخصصة. نحن متخصصون في إنشاء حلول قابلة للتوسع وفعالة وسهلة الاستخدام لتلبية متطلباتك الفريدة.",
          projBaliKeyLi7H: "تطوير التطبيقات:",
          projBaliKeyLi7Para:
            "ابقَ في طليعة المشهد الرقمي مع خدماتنا لتطوير التطبيقات المخصصة. من تطبيقات الهواتف المحمولة إلى الحلول المؤسسية، نصمم التطبيقات التي تعزز الابتكار وتحسن تجارب المستخدمين.",
          projBaliKeyLi8H: "تطوير الويب:",
          projBaliKeyLi8Para:
            "أنشئ حضورًا قويًا على الإنترنت مع خدماتنا لتطوير الويب. يقوم مطورونا المهرة بإنشاء مواقع ويب رائعة بصريًا ووظيفية، مما يضمن تجربة مستخدم إيجابية وجذابة.",

          projPayH: "العميل",
          projPayPara: "Atlanta",
          projPayDurH: "المدة",
          projPayDurPara: "6 أشهر",
          projPayDateH: "التاريخ",
          projPayDatePara: "قيد التقدم",
          projPayCateH: "الفئة",
          projPayCatePara: "إدارة الرواتب",
          projPayKeyLi1H: "الحسابات الآلية:",
          projPayKeyLi1Para:
            "تقوم تطبيقات الرواتب بأتمتة الحسابات المعقدة المتعلقة برواتب الموظفين والضرائب والخصومات والمزايا. يقلل هذا من احتمال الخطأ البشري ويضمن دفعًا دقيقًا للموظفين.",
          projPayKeyLi2H: "كفاءة الوقت:",
          projPayKeyLi2Para:
            "من خلال أتمتة عمليات الرواتب، يوفر التطبيق الوقت لأقسام الموارد البشرية والمالية، مما يسمح لها بالتركيز على المهام الاستراتيجية الأخرى بدلاً من قضاء ساعات في معالجة الرواتب يدويًا في كل فترة دفع.",
          projPayKeyLi3H: "الامتثال الضريبي:",
          projPayKeyLi3Para:
            "غالبًا ما تتكامل تطبيقات الرواتب مع جداول الضرائب واللوائح، مما يضمن حساب ضرائب الرواتب بدقة وامتثال الشركة لقوانين الضرائب على جميع المستويات (المحلية، الولائية، الفيدرالية).",
          projPayKeyLi4H: "الإيداع المباشر:",
          projPayKeyLi4Para:
            "تسهل العديد من تطبيقات الرواتب الإيداع المباشر، مما يسمح للموظفين بتلقي رواتبهم مباشرة في حساباتهم المصرفية. يلغي هذا الحاجة إلى الشيكات الورقية ويقلل من العبء الإداري.",
          projPayKeyLi5H: "خدمة ذاتية للموظفين:",
          projPayKeyLi5Para:
            "غالبًا ما تتضمن تطبيقات الرواتب الحديثة بوابات خدمة ذاتية حيث يمكن للموظفين الوصول إلى قسائم الرواتب الخاصة بهم والنماذج الضريبية وتحديث المعلومات الشخصية مثل العنوان أو تفاصيل الحساب المصرفي، مما يقلل العبء على موظفي الموارد البشرية.",
          projPayKeyLi6H: "التقارير القابلة للتخصيص:",
          projPayKeyLi6Para:
            "توفر تطبيقات الرواتب ميزات إعداد التقارير القابلة للتخصيص، مما يتيح للشركات إنشاء تقارير متنوعة مثل ملخصات الرواتب والتقارير الضريبية وبيانات أرباح الموظفين وفقًا لاحتياجاتها الخاصة.",
          projPayKeyLi7H: "قدرات التكامل:",
          projPayKeyLi7Para:
            "يمكن لهذه التطبيقات التكامل مع الأنظمة الأخرى مثل برامج المحاسبة وأنظمة الحضور والانصراف وأنظمة إدارة الموارد البشرية، مما يبسط تدفق البيانات ويزيل إدخال البيانات المكرر.",
          projPayKeyLi8H: "إدارة شاملة للمزايا:",
          projPayKeyLi8Para:
            "تتضمن بعض تطبيقات الرواتب ميزات لإدارة مزايا الموظفين مثل الرعاية الصحية وخطط التقاعد والإجازات المدفوعة. يضمن هذا التعامل مع جميع جوانب تعويضات الموظفين بكفاءة.",
          projPayKeyLi9H: "تنبيهات الامتثال:",
          projPayKeyLi9Para:
            "غالبًا ما تتضمن تطبيقات الرواتب تنبيهات وتذكيرات بالامتثال لإخطار المسؤولين بالمواعيد النهائية القادمة والتغييرات في اللوائح أو أي إجراءات مطلوبة للحفاظ على الامتثال لقوانين وأنظمة العمل.",
          projPayKeyLi10H: "قابلية التوسع:",
          projPayKeyLi10Para:
            "تم تصميم تطبيقات الرواتب لاستيعاب نمو الشركات. سواء كانت الشركة صغيرة أو كبيرة، يمكن للتطبيق التوسع للتعامل مع زيادة عدد الموظفين وتعقيدات الرواتب دون التضحية بالأداء أو الكفاءة.",

          projCabsH: "العميل",
          projCabsPara: "Kerala Cabs",
          projCabsDurH: "المدة",
          projCabsDurPara: "مستمر",
          projCabsDateH: "التاريخ",
          projCabsDataPara: "قيد التقدم",
          projCabsCateH: "الفئة",
          projCabsCatePara: "خدمة سيارات الأجرة",
          projCabsKeyLi1H: "التكامل الإقليمي:",
          projCabsKeyLi1Para:
            "مصمم خصيصًا لمنطقة كيرالا، مما يضمن التنقل السلس عبر المناطق الحضرية والريفية على حد سواء.",
          projCabsKeyLi2H: "خيارات الحجز المخصصة:",
          projCabsKeyLi2Para:
            "يوفر خيارات حجز متنوعة مثل الحجز من نقطة إلى نقطة، وتأجير بالساعات، والرحلات الخارجية، مما يلبي احتياجات السفر المختلفة للمستخدمين.",
          projCabsKeyLi3H: "التحقق من السائق:",
          projCabsKeyLi3Para:
            "يضمن فحص الخلفية والتحقق من السائقين بدقة لضمان الأمان والموثوقية للركاب.",
          projCabsKeyLi4H: "تتبع الوقت الفعلي:",
          projCabsKeyLi4Para:
            "يسمح للمستخدمين بتتبع سيارتهم الأجرة في الوقت الفعلي على التطبيق، مما يوفر الشفافية وراحة البال طوال الرحلة.",
          projCabsKeyLi5H: "تقييمات ومراجعات السائقين:",
          projCabsKeyLi5Para:
            "يمكن الركاب من تقييم ومراجعة السائقين بعد الرحلة، مما يعزز المساءلة ويحافظ على معايير جودة الخدمة.",
          projCabsKeyLi6H: "التكامل مع المعالم السياحية المحلية:",
          projCabsKeyLi6Para:
            "يوفر التوصيات وخدمات الحجز المتكاملة للمعالم السياحية المحلية، مما يعزز تجربة السفر الإجمالية للزوار إلى كيرالا.",
          projCabsKeyLi7H: "شفافية الأسعار:",
          projCabsKeyLi7Para:
            "يعرض تقديرات الأجرة مقدماً بناءً على المسافة والوقت، مما يضمن الشفافية وتجنب المفاجآت للركاب.",
          projCabsKeyLi8H: "العروض والخصومات:",
          projCabsKeyLi8Para:
            "يوفر العروض والخصومات المنتظمة للمستخدمين، مما يجعل الرحلات أكثر تكلفة ويعزز ولاء العملاء.",
          projCabsKeyLi9H: "المدفوعات غير النقدية:",
          projCabsKeyLi9Para:
            "يوفر خيارات الدفع غير النقدية المريحة مثل بطاقات الائتمان / الخصم، المحافظ الرقمية، و UPI، مما يقلل من متاعب حمل النقود ويعزز المعاملات اللا تلامسية.",

          projLogiH: "العميل",
          projLogiPara: "Al Razaa Logistics",
          projLogiDurH: "المدة",
          projLogiDurPara: "شهران",
          projLogiDateH: "التاريخ",
          projLogiDatePara: "قيد التقدم",
          projLogiCateH: "الفئة",
          projLogiCatePara: "اللوجستيات",
          projLogiKeyLi1H: "واجهة سهلة الاستخدام:",
          projLogiKeyLi1Para: "منصة سهلة الاستخدام لإدارة الشحنات بكفاءة.",
          projLogiKeyLi2H: "حجز الشحن السريع:",
          projLogiKeyLi2Para: "عملية مبسطة للحجز السريع والسهل.",
          projLogiKeyLi3H: "تتبع الوقت الفعلي:",
          projLogiKeyLi3Para: "أنظمة تتبع متقدمة لمراقبة الشحنات بشكل حي.",
          projLogiKeyLi4H: "حلول مخصصة:",
          projLogiKeyLi4Para:
            "خيارات لوجستية مخصصة لتلبية احتياجات الأعمال المتنوعة.",
          projLogiKeyLi5H: "شفافية التسعير:",
          projLogiKeyLi5Para: "هيكل تسعير واضح وتقديرات التكلفة المسبقة.",
          projLogiKeyLi6H: "دعم العملاء على مدار الساعة:",
          projLogiKeyLi6Para:
            "مساعدة متاحة على مدار الساعة للاستفسارات والمشاكل.",
          projLogiKeyLi7H: "رؤية سلسلة التوريد:",
          projLogiKeyLi7Para: "رؤية محسنة لتحسين التعاون والتخطيط.",
          projLogiKeyLi8H: "إدارة المخاطر:",
          projLogiKeyLi8Para: "إجراءات قوية لحماية الشحنات وإدارة المخاطر.",
          projLogiKeyLi9H: "الاستدامة البيئية:",
          projLogiKeyLi9Para: "التزام بممارسات وحلول صديقة للبيئة.",
          projLogiKeyLi10H: "التحسين المستمر:",
          projLogiKeyLi10Para:
            "تحسينات مستمرة مدفوعة بالتغذية الراجعة لتحسين الخدمة والتجربة.",

          projOvanH: "العميل",
          projOvanPara: "Ovantica",
          projOvanDurH: "المدة",
          projOvanDurPara: "6 أشهر",
          projOvanDateH: "التاريخ",
          projOvanDatePara: "20 أبريل 2017",
          projOvanCateH: "الفئة",
          projOvanCatePara: "التجارة الإلكترونية",
          projOvanKeyLi1H: "واجهة سهلة الاستخدام:",
          projOvanKeyLi1Para:
            "تصميم موقع وتطبيق سهل الاستخدام لتسهيل التنقل وتوفير تجربة مستخدم سلسة.",
          projOvanKeyLi2H: "قوائم المنتجات:",
          projOvanKeyLi2Para:
            "قوائم شاملة للإلكترونيات المستعملة مع أوصاف تفصيلية، بما في ذلك المواصفات والحالة والتسعير.",
          projOvanKeyLi3H: "خيارات البحث والتصفية:",
          projOvanKeyLi3Para:
            "وظائف بحث وتصفية متقدمة لمساعدة المستخدمين في العثور على نماذج الإلكترونيات المحددة، العلامات التجارية، الحالات، وغيرها بسرعة.",
          projOvanKeyLi4H: "خيار الضمان:",
          projOvanKeyLi4Para:
            "تقديم خيارات الضمان للإلكترونيات المستعملة لتعزيز الثقة في المشترين وتوفير ضمان لجودة المنتجات.",
          projOvanKeyLi5H: "ميزة بيع الإلكترونيات القديمة:",
          projOvanKeyLi5Para:
            "قسم مخصص حيث يمكن للمستخدمين إدراج إلكترونياتهم القديمة للبيع بسهولة، بما في ذلك الخيارات لوصف الحالة، تحميل الصور، وتحديد السعر.",
          projOvanKeyLi6H: "المعاملات الآمنة:",
          projOvanKeyLi6Para:
            "تنفيذ بوابات دفع آمنة وضمان تشفير البيانات لحماية المعلومات المالية للمستخدمين أثناء المعاملات.",
          projOvanKeyLi7H: "ضمان الجودة:",
          projOvanKeyLi7Para:
            "تنفيذ عمليات فحص الجودة الصارمة لجميع الإلكترونيات المستعملة قبل إدراجها على المنصة لضمان توافقها مع معايير الجودة.",
          projOvanKeyLi8H: "دعم العملاء:",
          projOvanKeyLi8Para:
            "توفير قنوات دعم العملاء السريعة، مثل الدردشة المباشرة، البريد الإلكتروني، أو الدعم الهاتفي لمساعدة المستخدمين في أي استفسارات أو مشاكل.",
          projOvanKeyLi9H: "العروض والخصومات:",
          projOvanKeyLi9Para:
            "تقديم عروض ترويجية وخصومات ومكافآت ولاء لجذب العملاء وتشجيع عمليات الشراء المتكررة.",

          projPureH: "العميل",
          projPurePara: "نادي نقاء الأرز",
          projPureDurH: "المدة",
          projPureDurPara: "شهر واحد",
          projPureDateH: "التاريخ",
          projPureDatePara: "15 أبريل 2024",
          projPureCateH: "الفئة",
          projPureCatePara: "ترفيه",
          projPureKeyLi1H: "صيغة الاستبيان:",
          projPureKeyLi1Para:
            "يتم تقديم الاختبار كاستبيان يحتوي على سلسلة من الأسئلة المتعددة الخيارات أو العبارات.",
          projPureKeyLi2H: "بنك أسئلة كبير:",
          projPureKeyLi2Para:
            "يحتوي عادةً على عدد كبير من الأسئلة التي تغطي مجموعة واسعة من الموضوعات مثل الجنس، المخدرات، العلاقات، والسلوك الشخصي.",
          projPureKeyLi3H: "نظام التقدير الذاتي:",
          projPureKeyLi3Para:
            "بعد إكمال الاستبيان، يمكن للمستجيبين إرسال إجاباتهم والحصول على درجة نقاء فورية بناءً على إجاباتهم.",
          projPureKeyLi4H: "خيارات الخصوصية:",
          projPureKeyLi4Para:
            "تقدم بعض المواقع خيارات الخصوصية، مما يسمح للمستخدمين بأخذ الاختبار بشكل مجهول دون مشاركة نتائجهم علنًا.",

          projLetsH: "لنجعل مشروع أحلامك حقيقة",
          projLetsPara:
            "هل تريد موقعًا متميزًا يبهر عملائك؟ إذن نحن جاهزون للمساعدة! اضغط على الزر أدناه للتواصل معنا ومناقشة أفكارك.",
          projLetsBtn: "لنبدأ",

          projSpeedH: "عميل",
          projSpeedPara: "SPEED-P",
          projSpeedDurH: "المدة",
          projSpeedDurPara: "شهرين",
          projSpeedDateH: "التاريخ",
          projSpeedDatePara: "15 أبريل 2024",
          projSpeedCateH: "الفئة",
          projSpeedCatePara: "مبيعات",
          projSpeedKeyLi1H: "تحليلات شاملة:",
          projSpeedKeyLi1Para:
            "يوفر رؤى وتقارير مفصلة عبر وظائف العمل المختلفة، مما يمكّن اتخاذ القرارات المستندة إلى البيانات.",
          projSpeedKeyLi2H: "التخطيط والجدولة المتقدمة:",
          projSpeedKeyLi2Para:
            "تحسّن تخصيص الموارد والجداول الزمنية للمشاريع لتعزيز الكفاءة التشغيلية والإنتاجية.",
          projSpeedKeyLi3H: "إدارة الأصول المؤسسية:",
          projSpeedKeyLi3Para:
            "يتتبع ويدير الأصول المادية، لضمان استخدامها الأمثل وصيانتها.",
          projSpeedKeyLi4H: "إدارة علاقات العملاء (CRM):",
          projSpeedKeyLi4Para:
            "يدير تفاعلات العملاء وعمليات المبيعات وخدمة العملاء لتحسين رضا العملاء والاحتفاظ بهم.",
          projSpeedKeyLi5H: "إدارة سلسلة التوريد:",
          projSpeedKeyLi5Para:
            "يبسط عمليات سلسلة التوريد، بما في ذلك الشراء وإدارة المخزون واللوجستيات، لخفض التكاليف وتحسين الكفاءة.",
          projSpeedKeyLi6H: "إدارة رأس المال البشري (HCM):",
          projSpeedKeyLi6Para:
            "يدير عمليات دورة حياة الموظفين، من التوظيف والتأهيل إلى إدارة الأداء والرواتب.",
          projSpeedKeyLi7H: "إدارة التصنيع:",
          projSpeedKeyLi7Para:
            "يسهل تخطيط الإنتاج والتنفيذ والمراقبة لضمان كفاءة عمليات التصنيع وتلبية معايير الجودة.",
          projSpeedKeyLi8H: "الإدارة المالية:",
          projSpeedKeyLi8Para:
            "يتولى العمليات المالية، بما في ذلك المحاسبة وإعداد الميزانية والتقارير المالية، للحفاظ على الصحة المالية والامتثال.",
          //Meat market
          projMMH: "العميل",
          projMMPara: "سوق اللحوم",
          projMMDurH: "المدة",
          projMMDurPara: "شهرين",
          projMMDateH: "التاريخ",
          projMMDatePara: "10 أغسطس 2024",
          projMMCateH: "الفئة",
          projMMCatePara: "التجارة الإلكترونية",
          projMMKeyLi1H: "جداول توصيل قابلة للتخصيص:",
          projMMKeyLi1Para:
            "السماح للعملاء باختيار أوقات توصيل محددة تناسب راحتهم، مما يضمن وصول اللحوم الطازجة في الوقت المناسب.",
          projMMKeyLi2H: "تتبع الطلبات في الوقت الحقيقي:",
          projMMKeyLi2Para:
            "توفير تتبع مباشر للطلبات من التحضير إلى التسليم للباب، لإبقاء العملاء على اطلاع وتواصل.",
          projMMKeyLi3H: "خصومات حصرية للأعضاء:",
          projMMKeyLi3Para:
            "إنشاء برنامج ولاء حيث يمكن للمشترين المتكررين الحصول على صفقات وخصومات خاصة على منتجاتهم المفضلة.",
          projMMKeyLi4H: "ضمان النضارة:",
          projMMKeyLi4Para:
            "ضمان أعلى جودة وأحدث اللحوم مع سياسة إرجاع واضحة في حال عدم تلبية المعايير.",
          projMMKeyLi5H: "خصومات على الطلبات بالجملة:",
          projMMKeyLi5Para:
            "تقديم أسعار خاصة للطلبات الكبيرة، لتلبية احتياجات العائلات أو المشترين بالجملة، لضمان توفير أكبر.",
          projMMKeyLi6H: "خيارات دفع مرنة:",
          projMMKeyLi6Para:
            "دعم مجموعة متنوعة من طرق الدفع، بما في ذلك الدفع عند التسليم، وبطاقات الائتمان/الخصم، والمحافظ الرقمية، لراحة إضافية.",

          //RG
          projrgH: "العميل",
          projrgPara: "تصاميم RG",
          projrgDurH: "المدة",
          projrgDurPara: "شهرين",
          projrgDateH: "التاريخ",
          projrgDatePara: "10 سبتمبر 2024",
          projrgCateH: "الفئة",
          projrgCatePara: "التجارة الإلكترونية",
          projrgKeyLi1H: "تحويل التطريز المخصص إلى رقمية:",
          projrgKeyLi1Para:
            "تقدم تصاميم RG خدمات تحويل رقمية مخصصة لتحويل أي شعار أو صورة أو رسم إلى ملف تطريز جاهز للماكينة، مما يضمن جودة ودقة وحجم التطريز المثالي عبر عدة تنسيقات.",
          projrgKeyLi2H: "أنماط تطريز متنوعة:",
          projrgKeyLi2Para:
            "تتميز المنصة بمكتبة ضخمة من تصاميم التطريز الجاهزة التي تلبي مختلف المواضيع، بما في ذلك الأعياد والطبيعة والمناسبات الخاصة. تم تحسين هذه التصاميم لأنواع مختلفة من الآلات، مما يضمن التوافق وسهولة الاستخدام.",
          projrgKeyLi3H: "أدوات تحرير متقدمة:",
          projrgKeyLi3Para:
            "تقدم تصاميم RG أدوات قوية عبر الإنترنت لتخصيص وتحرير التصاميم، مما يتيح للعملاء ضبط أنواع الغرز والألوان والأنماط لتتناسب مع احتياجات مشاريعهم الخاصة.",
          projrgKeyLi4H: "غرز عالية الدقة:",
          projrgKeyLi4Para:
            "جميع تصاميم التطريز مصممة بأنماط غرز عالية الدقة، مما يضمن نتائج نظيفة واحترافية على أي مادة، من الأقمشة إلى المنسوجات الأكثر تعقيدًا.",
          projrgKeyLi5H: "خيارات التصميم بالجملة:",
          projrgKeyLi5Para:
            "لمشاريع الحجم الكبير، تقدم تصاميم RG خيارات تصميم بالجملة، مما يسمح للعملاء بشراء المجموعات بأسعار تنافسية، وهو مثالي للشركات أو محترفي التطريز.",
          projrgKeyLi6H: "وقت تسليم سريع:",
          projrgKeyLi6Para:
            "تفخر تصاميم RG بتقديم خدمات تحويل رقمية سريعة وموثوقة، مما يضمن معالجة وتسليم الطلبات المخصصة في الوقت المناسب، لتلبية المواعيد النهائية للمشاريع المشددة.",

          //Project end

          //Header Start
          navHome: "الصفحة الرئيسية",
          navServices: "الخدمات",
          navServicesSub1: "تطوير الويب",
          navServicesSub2: "تطوير تطبيقات الهاتف المحمول",
          navServicesSub3: "تطوير البرمجيات",
          navServicesSub4: "ديف أوبس",
          navServicesSub5: "الحوسبة السحابية",
          navServicesSub6: "التسويق الرقمي",
          navProject: "المشاريع",
          navAbout: "من نحن",
          navContact: "الاتصال",
          navEnquiry: "للاستفسار",
        },
      },
      ru: {
        translation: {
          //Footer Start
          footerpara:
            "Мы являемся компанией по разработке программного обеспечения, которая создает продукты для компаний по всему миру. Мы помогли им создавать более качественные продукты, улучшать бизнес-процессы и развивать свой бизнес.",
          quickLinkHead: "Быстрые ссылки",
          quickLink1: "Веб-разработка",
          quickLink2: "Разработка мобильных приложений",
          quickLink3: "Разработка программного обеспечения",
          quickLink4: "Облачные вычисления",
          quickLink5: "Цифровой маркетинг",
          quickLink6: "О компании",
          quickLink7: "Проекты",
          quickLink8: "DevOps",
          quickLink9: "Контакт",
          newsLetterHead: "Информационный бюллетень",
          newspara: "Подпишитесь, чтобы получать больше с каждым обновлением",
          newsletterplaceHolder: "Введите электронную почту",
          newsletterBtn: "Подписаться сейчас",
          copyRights:
            "© Авторское право 2024 The TechHorse. Все права защищены", //Footer End
          //Home Start
          bannerSpan1: "20 лет опыта в решениях для веб-дизайна",
          bannerH1: "Создание цифровых решений завтрашнего дня уже сегодня",
          getStartBtn: "Начните с нас",
          bannerimgSpan1: "Веб-дизайн",
          bannerSpan2: "20 лет опыта в цифровых решениях",
          bannerH2: "Ваши технологические партнеры в цифровом мире",
          bannerimgSpan2: "Развитие",
          coreFeatureH: "Основные характеристики",
          coreFeatureSub: "Услуги, которые мы предлагаем",
          coreCards1: "Адаптивный современный дизайн",
          coreCards2: "Мощная кастомизация",
          coreCards3: "Крутые и современные анимации",
          coreCards4: "SEO-дружественное кодирование",
          coreCards5: "Лучшая техническая поддержка",
          coreCards6: "Разнообразные планировки и параллакс",
          AllaboutH: "Все о TechHorse",
          AllaboutSub: "Лучшие решения для роста digital-агентств",
          missionH: "Миссия:",
          missionPara:
            "OВаша миссия состоит в том, чтобы использовать передовые технологии искусственного интеллекта, чтобы революционизировать отрасли, улучшить качество человеческого опыта и построить лучшее будущее. Наша квалифицированная команда, имеющая глубокие корни как в классических, так и в современных ИТ-решениях и международном бизнесе, стремится предоставлять услуги высшего уровня по всему миру. Мы стремимся быть самым надежным лидером в улучшении человеческого взаимодействия с помощью инновационных технологий и исключительной командной работы.",
          visionH: "Зрение:",
          visionPara:
            "Быть лидером в области ИТ-решений на основе искусственного интеллекта, продвигая культуру творчества, командной работы и непрерывного обучения. Нас отличает наша страсть к разработке эффективных решений и поддержке роста наших клиентов на каждом этапе пути.",
          philosophyH: "Философия:",
          philosophyPara:
            "Наша философия заключается в создании решений на основе искусственного интеллекта, которые расширяют возможности человека и улучшают пользовательский опыт. Мы отдаем приоритет этичным методам использования ИИ, конфиденциальности данных и экологической устойчивости, оставаясь при этом на переднем крае исследований и инноваций в области ИИ. Наше стремление к совершенству и командной работе лежит в основе нашей корпоративной культуры, влияя на наши решения и стимулируя разработку трансформационных решений в области искусственного интеллекта.",
          coredesignH: "Рекомендуемый дизайн ядра",
          corededignSub: "Что мы предлагаем для бизнеса",
          core1: "Веб-дизайн и разработка",
          core2: "Разработка мобильных приложений",
          core3: "Разработка программного обеспечения",
          core4: "девопс",
          core5: "Цифровой маркетинг",
          core6: "Облачные вычисления",
          recentProjH: "Недавние проекты",
          recentProjSub: "Посмотреть галерею последних работ",
          wishitonH: "WishItOn Паутина",
          balitekH: "BaliTek Паутина",
          wishitonMobH: "WishItOn Мобильное приложение",
          workingProcessH: "Рабочий процесс",
          workingProcessSub: "Как мы работаем",
          infoGatherH: "Сбор информации",
          infoGatherPara:
            "Мы инициируем каждый проект, глубоко понимая потребности, цели и ожидания клиента.",
          PlanningH: "Планирование и анализ",
          PlanningPara:
            "Затем мы разрабатываем структурированный план, чтобы привести проект к завершению в согласованные сроки и бюджет. На этом важнейшем этапе проанализируйте собранную информацию, чтобы определить потенциальные проблемы и возможности.",
          developH: "Развитие",
          developPara:
            "Начните создание веб-сайта или приложения, используя лучшие практики кодирования и дизайна.",
          testingH: "Тестирование и запуск",
          testingPara:
            "Проведите всестороннее тестирование, чтобы убедиться, что приложение или веб-сайт хорошо работает на всех желаемых платформах и сценариях использования. Устраните все проблемы, обнаруженные на этапе тестирования. Как только тестирование будет завершено, мы приступим к запуску.",
          HaveH: "У вас на уме любой проект ?",
          Weare: "Нам предстоит работа по SEO-оптимизации ?",
          formH:
            "Имейте в виду любой проект! Не стесняйтесь обращаться к нам или",
          formHSpan: "Поздороваться",
          locationLabel: "Местоположение",
          locationCon: "Ченнаи, Индия",
          emailLabel: "Адрес электронной почты",
          emailCon: "info@thetechhorse.com",
          phoneLabel: "Номер телефона",
          formHead: "Отправьте нам сообщение",
          fullNameHolder: "Полное имя",
          emailHolder: "Адрес электронной почты",
          subjectHolder: "Тема",
          messageHolder: "Написать сообщение",
          sendmesBtn: "Отправка сообщений", //Home end
          //Web Start
          webBannerH: "Веб-дизайн и разработка",
          webBannerSpan: "Кроме того, T",
          webhome: "Дом",
          webTitle: "Веб-дизайн ",
          webWhat: "Что мы предлагаем",
          webWhatH: "Exquisite Web Solutions Crafted to Excel",
          webWhatSpan:
            "Воспользуйтесь многолетним опытом работы с компанией The TechHorse, вашим партнером в области дизайна и разработки веб-сайтов. Повысьте свое цифровое присутствие с помощью наших индивидуальных услуг, адаптированных к потребностям вашего бизнеса.",
          learnMoreBtn: "Подробнее",
          webTailor: "Индивидуальные веб-решения, которые думают наперед",
          webTailorPara:
            "В The TechHorse каждый веб-сайт — это шедевр, тщательно созданный с учетом потребностей вашего клиента. Помимо эстетики, мы углубляемся в нюансы пользовательского опыта, эффективности SEO и технической точности, чтобы создавать не только веб-сайты, но и надежный цифровой опыт.",
          webOurExpertise: "Наша экспертиза включает в себя :",
          webListT1: "Адаптивный дизайн сайта:",
          webListT1Para:
            "Плавные конструкции, которые легко адаптируются к любому устройству.",
          webListT2: "Дизайн пользовательского опыта:",
          webListT2Para:
            "Интуитивно понятные интерфейсы, которые привлекают и восхищают.",
          webListT3: "Интеграция CMS и электронной коммерции:",
          webListT3Para:
            "Оптимизированные решения, повышающие функциональность и управляемость.",
          webListT4: "Контент-стратегия сайта:",
          webListT4Para:
            "Стратегические фреймворки контента, повышающие вовлеченность и конверсию.",
          webListT5: "Кроссбраузерное и платформенное тестирование:",
          webListT5Para:
            "Обеспечение стабильной производительности во всех точках взаимодействия с пользователем.",
          webListT6: "Юзабилити и анализ конкуренции:",
          webListT6Para:
            "Аналитика, которая поможет вам быть впереди в конкурентной среде.",
          strategyH: "Создание стратегии",
          strategyPara:
            "Разработка плана успеха с тщательным исследованием рынка и стратегическим согласованием.",
          webdegH: "Дизайн сайта",
          webdegPara:
            "Концептуализация уникального дизайна, который отражает сущность вашего бренда.",
          webDevH: "Развитие",
          webDevPara:
            "Проектирование вашего сайта с использованием передовых технологий для оптимальной функциональности.",
          projTestH: "Тестирование проекта",
          projTestPara:
            "Тщательное тестирование нашими экспертами для обеспечения безупречной работы.",
          projLaunchH: "Запуск проекта",
          projLaunchPara:
            "Точный запуск вашего сайта для оказания мощного влияния на рынок.",
          customwebSolH: "Пользовательские веб-решения",
          customwebSolPara:
            "Повысьте свое цифровое присутствие с помощью уникальных веб-сайтов, отвечающих потребностям вашего бизнеса. Используя WordPress и передовые HTML и CSS, мы гарантируем, что ваш сайт прост в управлении и оптимально разработан для поисковых систем.",
          customwebli1: "Легко редактировать и удобен для поисковых систем",
          customwebli2: "Широкие возможности настройки",
          customwebli3: "Самые популярные CMS в мире",
          webTechFeatureH: "Особенности технологии",
          webTechFeatureSub: "Полный потенциал современных функций",
          webResH: "Адаптивный дизайн",
          webResSub: "Оптимизированный веб-дизайн для каждого устройства",
          webResPara:
            "Наш адаптивный дизайн гарантирует, что ваш сайт обеспечит исключительный пользовательский опыт на любом устройстве. Ваш сайт будет обладать безупречной функциональностью и эстетической целостностью, независимо от того, доступен ли он с настольного компьютера, ноутбука, планшета или смартфона.",
          webResli1: "Интерфейсы, оптимизированные для мобильных устройств",
          webResli2: "Универсальная совместимость",
          webResli3: "Улучшенный пользовательский опыт и SEO",
          webPostH:
            "Мы сделаем бесплатную SEO оптимизацию на вашем сайте для органического трафика",
          webPostPara:
            "Органический трафик, подкрепленный эффективным SEO, может привести к увеличению дохода на 40%, так как пользователи доверяют органически ранжированным сайтам больше, чем рекламе. Кроме того, веб-сайты с сильным органическим присутствием имеют коэффициент удержания клиентов в 5 раз выше, чем те, которые полагаются исключительно на платный трафик.",
          createwebBtn: "Создайте свой сайт",
          webSupportMarkH: "Поддержка и маркетинг",
          webSupportMarkSub: "Маркетинг для поддержки вашего веб-дизайна",
          webSupportMarkPara:
            "К счастью, мы здесь не просто дизайнеры и разработчики — мы писатели, стратеги, техники и креативщики, и все они работают над одной и той же конечной целью: успехом наших клиентов. Как агентство цифрового маркетинга полного цикла",
          webSeo: "Услуги SEO",
          webcopywrite: "Копирайтинг",
          webppc: "КПП",
          webseoH: "SEO-услуги",
          webseoPara:
            "Если вы хотите управлять своим интернет-рынком, вам нужна комплексная стратегия SEO",
          webcopyH: "Копирайтинг",
          webcopyPara:
            "Усильте свой бренд и контролируйте разговор с помощью стратегической стратегии контент-маркетинга",
          webppcH: "Оплата за клик",
          webpccPara:
            "Управление контекстной рекламой заключается в том, чтобы точно доставлять нужную рекламу вашим будущим клиентам",
          webcraftH: "Создайте свое видение с точностью",
          webcraftPara:
            "Готовы ли вы трансформировать свое цифровое присутствие? В The Tech Horse мы специализируемся на создании веб-сайтов, которые выделяются и находят глубокий отклик у вашей аудитории. Давайте обсудим, как мы можем воплотить ваше видение в жизнь. Нажмите ниже, чтобы начать.",
          webletstartBtn: "Давайте начнем",
          //Web End

          //Mobile Start
          mobileBannerH: "Разработка мобильных приложений",
          mobileBannerSpan: "Кроме того, T",
          mobilehome: "Дом",
          mobileTitle: "Разработка мобильных приложений",
          mobilewhatH: "Что мы предлагаем",
          mobileWhatSUb: "Превосходство в дизайне и разработке приложений",
          mobileWhatPara:
            "Использование 20-летнего новаторского опыта для создания исключительных цифровых впечатлений.",
          mobilerightPara:
            "Современная разработка приложений выходит за рамки создания функциональных приложений. Речь идет о создании привлекательного пользовательского опыта, оптимизации производительности и внедрении передовых технологий. Вот на чем мы специализируемся:",
          mobileRightList1: "Адаптивный дизайн приложений",
          mobileRightList2: "Инновации в пользовательском интерфейсе",
          mobileRightList3: "Решения для интеграции приложений",
          mobileRightList4: "Стратегия в отношении контента приложения",
          mobileRightList5: "Всестороннее тестирование",
          mobileRightList6: "Анализ рынка и юзабилити",
          mobilestrategyH: "Формулировка стратегии",
          mobilestrategyPara:
            "Разработка точных стратегий для позиционирования вашего приложения на рынке.",
          mobileappDesignH: "Дизайн приложений",
          mobileappDesignPara:
            "Разработка визуально привлекательных и удобных для пользователя дизайн-концепций.",
          mobileAppdevH: "Разработка приложений",
          mobileAppdevPara:
            "Создание надежных и масштабируемых приложений с высокой точностью.",
          mobileApptestH: "Тестирование приложений",
          mobileApptestPara:
            "Обеспечение максимальной производительности благодаря строгим протоколам тестирования.",
          mobileAppLaunchH: "Запуск приложения",
          mobileAppLaunchPara:
            "Стратегическое развертывание приложения для оптимального проникновения на рынок.",
          mobileflutterH:
            "Разработка мобильных приложений Flutter , React native , Kotlin и Swift",
          mobileflutterPara:
            "Flutter и React Native — фреймворки для кроссплатформенной мобильной разработки, Kotlin и Swift — языки программирования, которые в основном используются для разработки на Android и iOS. Каждый из них имеет свои сильные стороны, сценарии использования и особенности, специфичные для платформы",
          mobileflutterList1: "Кроссплатформенность",
          mobileflutterList2: "Крупномасштабная разработка",
          mobileflutterList3: "Эффективность",
          mobileflutterList4: "Краткость",
          mobileResH: "Адаптивный дизайн",
          mobileResSub:
            "Дизайн для любого устройства Адаптивный дизайн приложений",
          mobileResPara:
            "Адаптивный дизайн приложения позволяет адаптироваться к любому устройству и обеспечивать оптимальную работу на любом устройстве, на котором оно просматривается. Это значит, что ваше приложение будет отлично выглядеть и безупречно функционировать для пользователя на любом смартфоне.",
          mobileReslist1: "Удобен для мобильных устройств",
          mobileReslist2: "Дизайн для любого устройства",
          mobileReslist3: "Позитивный UX и помощь SEO",
          mobileBoostH:
            "Повысьте узнаваемость вашего приложения — бесплатные услуги SEO включены",
          mobileBoostPara:
            "Запустите свое приложение с конкурентным преимуществом. Мы предлагаем эксклюзивные, бесплатные услуги SEO для вашего приложения в Google Play Store и Apple App Store, гарантируя, что оно достигнет своей аудитории и займет видное место.",
          mobileCreateAppBtn: "Создайте свое приложение",
          mobileSeoH: "Поддержка и маркетинг",
          mobileSEOSub: "Маркетинг для поддержки дизайна вашего приложения",
          mobileSeoPara:
            "К счастью, мы здесь не просто дизайнеры и разработчики — мы писатели, стратеги, техники и креативщики, и все они работают над одной конечной целью: успехом наших клиентов. Как агентство цифрового маркетинга полного цикла",
          mobileSeoSerH: "SEO-сервис",
          mobileSeoCopyH: "Копирайтинг",
          mobilePPCH: "КПП",
          mobileSEOSERH: "SEO услуги",
          mobileSEOSERPara:
            "Если вы хотите управлять своим интернет-рынком, вам нужна комплексная стратегия SEO",
          mobileSeoCopy: "Копирайтинг",
          mobileSeoCopyPara:
            "Усильте свой бренд и контролируйте разговор с помощью стратегической стратегии контент-маркетинга",
          mobileSeoPPC: "Оплата за клик",
          mobileSeoPPCPara:
            "Управление контекстной рекламой заключается в том, чтобы точно доставлять нужную рекламу вашим будущим клиентам",
          mobilenextappHead: "Давайте создадим ваше следующее приложение",
          mobilenextappPara:
            "Готовы к приложению, которое выделяется и доминирует на рынке? Мы здесь, чтобы воплотить ваше видение в жизнь. Нажмите ниже, чтобы начать превращать свои идеи в цифровой успех.",
          mobileLanuchBtn: "Реализуйте свое видение", //Mobile End
          //Software Start
          softBannerH: "Разработка программного обеспечения",
          softBannerSpan: "Кроме того, T",
          softBannerHome: "Дом",
          softBannerHomeTitle: "Разработка программного обеспечения",
          softCompanyH: "Статистика компании",
          softCompanySub:
            "Расширьте возможности своего видения с помощью новаторских программных решений",
          softCompanyPara:
            "Превратите свои идеи в передовые программные решения. Наши услуги по разработке, ориентированные на экспертов, обещают безупречное выполнение и превосходные результаты, подготавливая вас к успеху без технического образования.",
          softWorkH: "Рабочий процесс",
          softWorkSub: "Как мы работаем",
          softwork1H: "Понимание видения",
          softwork1Para:
            "Мы начинаем с досконального понимания ваших целей и требований, обеспечивая прочную основу для вашего проекта",
          softwork2H: "Планируйте свой успех",
          softwork2Para:
            "Наши стратеги и разработчики совместно создают подробную дорожную карту, соответствующую вашим целям",
          softwork3H: "Уточните концепцию",
          softwork3Para:
            "Мы анализируем и дорабатываем дизайн, чтобы он соответствовал вашим ожиданиям и нашим высоким стандартам",
          softwork4H: "Выполняйте с совершенством",
          softwork4Para:
            "После тщательного тестирования безупречной производительности ваше программное обеспечение запущено, готовое к влиянию на мир",
          softharnessH:
            "Используйте передовое программное обеспечение для развития вашего бизнеса",
          softharnessPara:
            "Воспользуйтесь нашим опытом, чтобы точно сориентироваться в процессе разработки программного обеспечения. Наша команда преуспевает в разработке стратегий, адаптированных к вашим уникальным потребностям, помогая вам достичь и превзойти ваши бизнес-цели.",
          softHarnessschBtn: "Записаться на бесплатную консультацию",
          softHarright1H: "Принимайте более взвешенные решения",
          softHarright1Para:
            "Используйте возможности больших данных для улучшения процесса принятия решений и продвижения бизнеса вперед с помощью практической аналитики.",
          softHarright2H: "Подготовьте свой бизнес к будущему",
          softHarright2Para:
            "Адаптируйтесь и процветайте на развивающемся рынке с помощью наших комплексных стратегий цифровой трансформации.",
          softHarright3H: "Оптимизация и масштабирование",
          softHarright3Para:
            "Ускорьте цикл разработки и повысьте эффективность с помощью наших надежных облачных решений.",
          softHarright4H: "Индивидуальные решения, исключительные результаты",
          softHarright4Para:
            "Создавайте эффективные и масштабируемые веб-приложения или мобильные приложения, адаптированные к потребностям вашего бизнеса и обеспечивающие исключительное удобство работы пользователей.",
          softCurH1: "Любопытно, есть ли",
          softCurSpan: "The TechHorse",
          softCurH2: "подходит для вашего проекта?",
          softCurSub:
            "В поисках первоклассных услуг по разработке программного обеспечения?",
          softCurPara1:
            "Давайте детально разберемся в вашем видении. После рассмотрения ваших требований мы можем назначить бесплатную консультацию, чтобы наметить объем работ и предоставить предполагаемую стратегию для обеспечения вашего успеха.",
          softCurPara2:
            "Каждый проект уникален, как и наш подход. Вот почему мы приглашаем вас на бесплатную консультацию с одним из наших опытных разработчиков программного обеспечения. Эта сессия предназначена для того, чтобы адаптировать наши стратегии к вашим потребностям и показать вам, как именно The Tech Horse может привести ваш проект к успеху.",
          softFormH: "Отправить нам сообщение",
          softFullName: "Полное имя",
          softemail: "Адрес электронной почты",
          softSubject: "Тема",
          softMeg: "Написать сообщение",
          softSendBtn: "Отправка сообщений",
          softLetsH: "Давайте разработаем ваше новое программное обеспечение",
          softLetsPara:
            "Вы хотите иметь программное обеспечение, которое выделяется и впечатляет ваших клиентов? Тогда мы готовы помочь! Нажмите кнопку ниже, чтобы связаться с нами и обсудить ваши идеи.",
          softLetsBtn: "Давайте начнем", //Software end
          //Devops start
          devopsBannerH: "DevOps",
          devopsHome: "Дом",
          devopsHomeNav: "DevOps",
          devopsYRSEXP: "Многолетний опыт работы с DevOps-решениями",
          devopsSolH: "DevOps Решения",
          devopsSolSub:
            "Повысьте эффективность своих операций с помощью Elite DevOps Engineering",
          devopsSolPara:
            "В The TechHorse мы разрабатываем совершенство. Наши услуги DevOps предназначены для оптимизации процесса поставки программного обеспечения, обеспечивая надежную масштабируемость и надежную безопасность для предприятий любого размера. Сотрудничайте с нами, чтобы преобразовать свои операции с точностью и эффективностьюy.",
          devopsCliSta: "Удовлетворенность клиентов",
          devopsMissH: "Миссия и видение",
          devopsMissSub:
            "Мы стремимся предоставить качественное творческое решение",
          devopsMissPara:
            "В The TechHorse мы создаем уникальные инновационные веб-решения, которые устанавливают отраслевые стандарты. Наша команда находится на переднем крае технологического прогресса, внедряя и интегрируя последние инновации для повышения вашего цифрового присутствия.",
          devopsSerH: "Наши популярные услуги",
          devopsSerSub: "Потрясающий сервис DevOps",
          devopsCloud1H: "Миграция в облако",
          devopsCloud1Para:
            "Фокус обеспечивает красивый макет, внешний вид клиента, делает импорт",
          devopsCloud2H: "Микросервисы",
          devopsCloud2Para:
            "Фокус обеспечивает красивый внешний вид макета клиента, делает импорт",
          devopsCloud3H: "Мониторинг и ведение журналов",
          devopsCloud3Para:
            "Фокус обеспечивает красивый внешний вид макета клиента, делает импорт",
          devopsCloud4H: "Непрерывная поставка",
          devopsCloud4Para:
            "Фокус обеспечивает красивый внешний вид макета клиента, делает импорт",
          devopswhyH: "Почему выбирают нас",
          devopswhySub: "Услуги DevOps, на которые можно положиться",
          devopslist1H: "Масштабируемые решения",
          devopslist1Para:
            "Наши стратегии DevOps разработаны с учетом масштабируемости и поддержки роста без ущерба для производительности. Мы гарантируем, что ваша инфраструктура будет развиваться в соответствии с потребностями вашего бизнеса.",
          devopslist2H: "Расширенная автоматизация",
          devopslist2Para:
            "Мы отдаем приоритет автоматизации, чтобы оптимизировать ваши операции, от развертывания кода до управления инфраструктурой, сокращая количество ошибок и повышая эффективность.",
          devopslist3H:
            "Непрерывная интеграция и непрерывное развертывание (CI/CD)",
          devopslist3Para:
            "Обеспечьте быструю доставку с помощью наших решений CI/CD, которые обеспечивают бесперебойную и безопасную работу обновлений программного обеспечения, ускоряя вывод продукции на рынок и обеспечивая надежную непрерывность обслуживания.",
          devopslist4H: "Гибкие модели взаимодействия",
          devopslist4Para:
            "С помощью наших клиентоориентированных моделей взаимодействия вы можете увеличивать или уменьшать масштаб в зависимости от требований проекта, обеспечивая оптимальный контроль над бюджетами и ресурсами.",
          devopsSupportList1: "Непрерывная интеграция",
          devopsSupportList2: "Управление конфигурацией",
          devopsSupportList3: "Автоматизированное тестирование",
          devopsSupportList4: "Инструменты для совместной работы",
          devopsSupportList5: "Управление релизами",
          devopsSupportList6: "Контроль версий",
          devopsSupFeatureH: "Поддержка и функции",
          devopsSupFeatureSub: "Потрясающие возможности проектирования DevOps",
          devopsSupFeaturePara:
            "Мы предлагаем комплексные услуги DevOps, которые позволяют сократить время выхода на рынок, улучшить совместную работу и повысить качество продукции. Наша команда экспертов использует лучшие отраслевые практики и передовые технологии, чтобы помочь вам эффективно и результативно достичь ваших целей DevOps.",
          devopsLearnbtn: "Узнайте больше о нас",
          devopsLifeH: "Жизненный цикл DevOps",
          devopslife1H: "Отпускать",
          devopslife1Para:
            "Развертывание нового программного обеспечения или обновлений в производственных средах для обеспечения бесперебойной доступности для конечных пользователей. Этот решающий этап включает в себя все необходимые подготовительные шаги.",
          devopslife2H: "Развертывать",
          devopslife2Para:
            "Установка обновлений программного обеспечения или новых приложений в рабочей, промежуточной или тестовой средах, чтобы сделать новые функции доступными для немедленного использования..",
          devopslife3H: "Управлять",
          devopslife3Para:
            "Управление и поддержка программных систем на протяжении всего их жизненного цикла. DevOps объединяет разработку (Dev) и эксплуатацию (Ops) для улучшения совместной работы и повышения эффективности.",
          devopslife4H: "Монитор",
          devopslife4Para:
            "Непрерывный контроль производительности программного обеспечения и инфраструктуры для обеспечения оптимальной работы и надежности. Мониторинг играет жизненно важную роль в упреждающем улучшении и целостности системы.",
          devopslife5H: "Запуск проекта",
          devopslife5Para:
            "Точный запуск сайта для оказания мощного влияния на рынок.",
          devopsEleH:
            "Повысьте эффективность своей деятельности с помощью эксперта по DevOps",
          devopsElePara:
            "Вам нужна бесшовная, эффективная и безопасная стратегия DevOps, соответствующая вашим бизнес-целям? Мы готовы помочь вам добиться успеха. Нажмите кнопку ниже, чтобы начать оптимизацию ваших операций с нашей командой экспертов.",
          devopsEleBtn: "Давайте начнем",
          //Devops End

          //Cloud Start
          cloudBannerH: "Облако",
          cloudBannerSpan: "Вычисление",
          cloudBannerHome: "Дом",
          cloudBannerHomeSpan: "Облачные вычисления",
          cloudCompH: "Статистика компании",
          cloudCompSub:
            "Раскройте потенциал производительности с помощью решений Elite Cloud",
          cloudCompPara:
            "Повысьте уровень своего бизнеса с помощью высокопроизводительных облачных вычислений The TechHorse. Наша сложная сеть обеспечивает бесшовную интеграцию серверов, баз данных, программного обеспечения и многого другого.",
          cloudSerH: "Наши услуги",
          cloudSerSub: "Удивительный сервис облачных вычислений",
          cloudSerli1H: "Самообслуживание по требованию",
          cloudSerli1Para:
            "Это позволяет клиенту постоянно контролировать время безотказной работы сервера, его возможности и выделенное сетевое хранилище.",
          cloudSerli2H: "Услуги по измерению и отчетности",
          cloudSerli2Para:
            "Это помогает контролировать выставление счетов и обеспечивать оптимальное использование ресурсов.",
          cloudSerli3H: "Анализ больших данных",
          cloudSerli3Para:
            "Аналитика больших данных — это методы и приложения, используемые для сбора, обработки и извлечения аналитических сведений из разнообразных, объемных и высокоскоростных наборов данных.",
          cloudSerli4H: "Безопасность и масштабируемость данных",
          cloudSerli4Para:
            "У многих клиентов есть рабочие нагрузки, которые могут быть выполнены очень экономично благодаря быстрой масштабируемости облачных вычислений.",
          cloudwhyH: "Почему выбирают нас",
          cloudwhySub:
            "Исключительные облачные решения, которым можно доверять",
          cloudwhyLi1H: "Масштабируемая архитектура",
          cloudwhyLi1Para:
            "Адаптируйтесь и развивайтесь без ограничений с помощью нашей масштабируемой облачной инфраструктуры, которая поддерживает ваш бизнес на каждом этапе.",
          cloudwhyLi2H: "Безопасность и надежность",
          cloudwhyLi2Para:
            "Защитите свои данные с помощью наших протоколов безопасности высшего уровня и наслаждайтесь стабильной безотказной работой с нашими надежными облачными сервисами.",
          cloudwhyLi3H: "Простая интеграция",
          cloudwhyLi3Para:
            "Бесшовная интеграция наших облачных решений с существующими платформами для бесперебойной, эффективной и слаженной работы.",
          cloudEveH:
            "Повысьте свое цифровое присутствие с помощью наших облачных решений",
          cloudEvePara:
            "Трансформируйте свою цифровую стратегию с помощью нашей масштабируемой и надежной облачной инфраструктуры. Нажмите ниже, чтобы начать внедрять инновации.",
          cloudLetsbtn: "Давайте начнем",
          //Cloud end

          //Digital Start
          digiBannerH: "Цифровой",
          digiBannerSpan: "Маркетинг",
          digiBannerHome: "Дом",
          digiBannerHomeNav: "Цифровой маркетинг",
          digiWhatH: "Что мы делаем",
          digiWhatSub:
            "Повысьте свой бренд с помощью экспертного цифрового маркетинга",
          digiseoLi1H: "SEO услуги",
          digiseoLi1Para:
            "Увеличьте свою видимость и занимайте первые места с помощью наших стратегических решений для SEO, разработанных для устойчивого роста.",
          digiseoLi2H: "Маркетинг в социальных сетях",
          digiseoLi2Para:
            "Привлекайте и расширяйте свою аудиторию с помощью персонализированных кампаний в социальных сетях, которые находят отклик и конвертируют ....",
          digiseoLi3H: "Реклама в Google",
          digiseoLi3Para:
            "Привлекайте целевой трафик и добивайтесь немедленных результатов с помощью профессионально управляемых кампаний Google Ads.",
          digiseoLi4H: "Индивидуальный дизайн сайта",
          digiseoLi4Para:
            "Создайте уникальное присутствие в Интернете с помощью индивидуального дизайна веб-сайтов, которые привлекают и конвертируют посетителей.",
          digiseoLi5H: "Дизайн сайта Shopify",
          digiseoLi5Para:
            "Запустите потрясающий, оптимизированный для продаж магазин Shopify, адаптированный к потребностям вашего бренда.",
          digiseoLi6H: "Лидогенерация",
          digiseoLi6Para:
            "Генерируйте высококачественные лиды с помощью стратегий, оптимизированных для конверсии и привлечения клиентов.",
          digiseoLi7H: "Контент-маркетинг",
          digiseoLi7Para:
            "Позиционируйте свой бренд как лидера отрасли с помощью привлекательного контента, который информирует и вдохновляет.",
          digiseoLi8H: "Цифровой PR",
          digiseoLi8Para:
            "Повысьте свою репутацию и охват аудитории с помощью наших комплексных услуг по цифровому PR, которые создадут и защитят ваш бренд.",
          digiseoLi9H: "Фирменная символика",
          digiseoLi9Para:
            "Создайте запоминающийся фирменный стиль с помощью наших креативных решений для брендинга, которые выражают дух и ценности вашего бизнеса.",
          digiSata1H:
            "Стратегически продуманные кампании для повышения узнаваемости",
          digiSata1Para:
            "Раскройте потенциал своего бренда с помощью передовых стратегий цифрового маркетинга, разработанных для увеличения вашего присутствия в Интернете и достижения впечатляющих результатов.",
          digiSata2H:
            "Стимулируйте рост с помощью таргетированной цифровой рекламы",
          digiSata2Para:
            "Используйте возможности Google Рекламы и контент-маркетинга с точным таргетингом, чтобы охватить идеальных клиентов там, где они наиболее активны и восприимчивы.",
          digiSata3H:
            "Развивайте свой бренд с помощью комплексных цифровых услуг",
          digiSata3Para:
            "От первоначального брендинга до цифрового PR создайте целостную айдентику, которая соответствует вашим ценностям и находит отклик у вашей целевой аудитории.",
          digiCoreH: "Основные характеристики",
          digiCoreSub: "Удивительные основные функции",
          digicore1H: "Стратегия, основанная на данных",
          digicore1Para:
            "Точно адаптируйте свои маркетинговые усилия, используя нашу аналитику и подходы, основанные на данных, которые обеспечивают максимальную окупаемость инвестиций и эффективность.",
          digicore2H: "Омниканальные кампании",
          digicore2Para:
            "Бесшовно интегрируйте свой маркетинг со всеми цифровыми платформами, обеспечивая единый опыт взаимодействия с брендом, который очаровывает и конвертирует.",
          digicore3H: "Создание творческого контента",
          digicore3Para:
            "Используйте наши инновационные контент-сервисы для создания убедительных историй, которые находят отклик у вашей аудитории и стимулируют вовлеченность.",
          digicore4H: "Передовые методы SEO",
          digicore4Para:
            "Повысьте свое присутствие в поисковых системах с помощью наших передовых тактик SEO для привлечения трафика и увеличения конверсии.",
          digicore5H: "Таргетированная реклама",
          digicore5Para:
            "Используйте сложный таргетинг рекламы для более эффективного охвата потенциальных клиентов, повышения лидогенерации и продаж.",
          digicore6H: "Комплексная отчетность",
          digicore6Para:
            "Оставайтесь в курсе событий с помощью подробных отчетов, которые отслеживают ход кампании и показатели, позволяя принимать обоснованные решения и корректировать стратегию.",
          digiChooseH: "Почему выбирают нас",
          digiChooseSub: "Надежная экспертиза в области цифрового маркетинга",
          digiChoose1H: "Проверенные результаты",
          digiChoose1Para:
            "Наш послужной список говорит сам за себя. Мы обеспечиваем измеримое улучшение трафика, вовлеченности и конверсии, способствуя реальному росту бизнеса.",
          digiChoose2H: "Комплексная аналитика",
          digiChoose2Para:
            "С помощью нашей подробной аналитики вы можете получить невиданную ранее информацию. Они помогут вам понять свою аудиторию и эффективно оптимизировать маркетинговые стратегии.",
          digiChoose3H: "Инновационные решения",
          digiChoose3Para:
            "Мы остаемся на шаг впереди, внедряя последние тенденции и технологии цифрового маркетинга, чтобы ваш бренд оставался актуальным и конкурентоспособным.",
          digiunlockH:
            "Раскройте свой цифровой потенциал: доступна бесплатная консультация",
          digiunlockPara:
            "Готовы трансформировать свое присутствие в Интернете? Начните с бесплатной консультации! Нажмите ниже, чтобы обсудить свое видение и открыть для себя индивидуальные стратегии цифрового маркетинга, которые выделят ваш сайт и произведут впечатление на ваших клиентов.",
          digiunlockBtn: "Давайте начнем",
          //Digital End

          //About Start
          abtBannerH: "Около",
          abtBannerSpan: "Нас",
          abtBannerHome: "Дом",
          abtBannerHomeNav: "О нас",
          abtwhatH: "Что мы делаем",
          abtwhatSub: "Мы — профессиональная IT-команда",
          abtwhatPara:
            "Мы являемся компанией по разработке программного обеспечения, которая создает продукты для компаний по всему миру. Мы помогли им создавать более качественные продукты, улучшать бизнес-процессы и развивать свой бизнес. Мы отличаемся от других компаний-разработчиков программного обеспечения, потому что мы увлечены тем, что мы делаем и как мы это делаем. Мы любим создавать продукты, которые могут помочь нашим клиентам добиться успеха, и нам нравится работать с ними на каждом этапе пути.",
          abtwhatLi1Para:
            "Быть лучшей и самой надежной в мире компанией по дизайну человеческого опыта.",
          abtwhatLi2Para:
            "Для улучшения и повышения уровня человеческого взаимодействия. Стремиться к совершенству благодаря самоотверженной командной работе.",
          abtcreateBtn: "Создайте свой сайт",
          abtYearexp: "Многолетний опыт работы",
          abtexpH:
            "20 лет опыта работы в этом мире, быть первым и главным выбором деловых людей.",
          abtexpLi1: "Веб-разработка",
          abtexpLi2: "Разработка программного обеспечения",
          abtexpLi3: "Облачные вычисления",
          abtexpLi4: "Разработка мобильных приложений",
          abtexpLi5: "DevOps",
          abtexpLi6: "Цифровой маркетинг",
          abtwebLi1H: "Дизайн сайта",
          abtwebLi1Para:
            "Концептуализация отличительных дизайнов, которые заключают в себе суть вашего бренда.",
          abtwebLi2H: "Дизайн приложений",
          abtwebLi2Para:
            "Разработка визуально привлекательных и удобных для пользователя дизайн-концепций.",
          abtwebLi3H: "Планируйте свой успех",
          abtwebLi3Para:
            "Наши стратеги и разработчики совместно создают подробную дорожную карту, соответствующую вашим целям.",
          abtwebLi4H: "Оптимизируйте для поисковых систем",
          abtwebLi4Para:
            "Увеличьте свою видимость и занимайте первые места с помощью наших стратегических решений для SEO, разработанных для устойчивого роста.",
          abtLetH: "Давайте создадим дизайн вашего нового сайта",
          abtLetPara:
            "Вы хотите иметь сайт, который выделяется и впечатляет ваших клиентов? Тогда мы готовы помочь! Нажмите кнопку ниже, чтобы связаться с нами и обсудить ваши идеи.",
          abtLetBtn: "Давайте начнем",
          //About End

          //Contact start
          contactBannerH: "Контакт",
          contactBannerHome: "Дом",
          contactBannerHomeNav: "Контакт",
          contactusH: "Свяжитесь с нами",
          contactusSub:
            "Давайте начнем новый проект или будем работать вместе! Свяжитесь с нами",
          contactusPara:
            "Свяжитесь с нами сегодня, чтобы начать новый проект или сотрудничать в рамках существующих инициатив. Мы будем рады услышать ваше мнение!",
          contactfullnamelabel: "Полное имя",
          contactfullnamePlace: "Введите свое имя",
          contactfullemaillabel: "Адрес электронной почты",
          contactfullemailPlace: "Введите свой адрес электронной почты",
          contactfullphonelabel: "Номер телефона",
          contactfullphonePlace: "Введите свой номер телефона",
          contactfullSubjectlabel: "Тема",
          contactfullSubjectPlace: "Тема",
          contactfullmeglabel: "Написать сообщение",
          contactfullmegPlace: "Написать сообщение",
          contactSendbtn: "Отправить сообщение",
          contactLocH: "МЕСТОПОЛОЖЕНИЕ",
          contactLocPara: "Royapettah, Chennai, Tamil Nadu 600014",
          contactEmailH: "АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ",
          contactPhoneH: "НОМЕР ТЕЛЕФОНА",
          contactFollowH: "Следите за нами",
          contactUSAH: "USA",
          contactUSLOc: "Бока-Ратон, Флорида 33428",
          contactAus: "Австралия",
          contactAusLOc: " Мельбурн Вик 3016",
          contactDub: "Дубай",
          contactDubLoc: "Аль-Рашидия 3 7613",
          //Contact end

          //Project start
          projBannerH: "Несколько проектов наших престижных клиентов",
          projBannerHome: "Дом",
          projBannerHomeNav: "Проект",
          projwishCLientH: "Клиент",
          projwishCLientPara: "Wishiton",
          projwishDurH: "Длительность",
          projwishDurPara: "4 месяца",
          projwishDateH: "Дата",
          projwishDatePara: "5 ноя 2023",
          projwishCateH: "Категория",
          projwishCatePara: "Развлечение",
          prjKeyH: "Ключевые особенности",
          projWishLi1:
            "Зарегистрироваться можно с помощью номера телефона, Gmail и подтверждения OTP для номера мобильного телефона.",
          projWishLi2:
            "Организация празднования дня рождения и отправка пожеланий.",
          projWishLi3:
            "Получение уведомлений и напоминаний до даты мероприятия.",
          projWishLi4:
            "Голосовой помощник Alexa позволяет легко создавать события для предстоящих дней рождения.",
          projWishLi5:
            "Управляйте событиями с помощью голосового помощника Alexa.",
          projWishLi6:
            "Отправляйте пожелания через сообщение, WhatsApp и mail.",
          projWishLi7:
            "Отправляйте пожелания вместе с интересными и индивидуальными подарками.",
          projWishLi8:
            "Проверьте наличие сувенирного магазина с помощью пин-кода.",
          projWishLi9: "Отправляйте пожелания с помощью собственных видео.",
          projWishLi10:
            "Отправляйте приглашения группе людей легко и за меньшее время.",
          projWishLi11: "Получайте выгодные предложения с помощью купонов.",
          projWishLi12: "Приз за предложение на все продукты.",
          projWishLi13: "Оплатите легко через интернет с помощью razor Pay.",
          projDry0H: "Клиент",
          projDryoPara: "Dryo Laundry",
          projDryoDurH: "Длительность",
          projDryoDurPara: "3 месяца",
          projDryoDateH: "Дата",
          projDryoDatePara: "Развиваться",
          projDryoCateH: "Категория",
          projDryoCatePara: "Бизнес-сервис",
          projDryoKeyLi1H: "Удобство:",
          projDryoKeyLi1Para:
            "Dryo обеспечивает удобство процесса стирки, позволяя пользователям планировать забор и доставку белья в удобное для них время и место. Это избавляет пользователей от необходимости посещать физические прачечные самообслуживания или тратить время на стирку самостоятельно.",
          projDryoKeyLi2H: "Экономия времени:",
          projDryoKeyLi2Para:
            "С помощью Dryo пользователи могут освободить время, которое они потратили бы на стирку, и использовать его для других важных задач или досуга. Это особенно полезно для занятых специалистов, семей и людей с напряженным графиком.",
          projDryoKeyLi3H: "Настройка:",
          projDryoKeyLi3Para:
            "Приложение предлагает варианты настройки, позволяющие пользователям указывать свои предпочтения в отношении стирки, такие как тип моющего средства, кондиционер для белья, температура воды и многое другое. Это гарантирует, что каждая загрузка белья будет адаптирована к предпочтениям и потребностям пользователя.",
          projDryoKeyLi4H: "Качественный сервис:",
          projDryoKeyLi4Para:
            "Dryo обеспечивает высококачественные услуги прачечной, сотрудничая с надежными и проверенными прачечными. Это гарантирует, что пользователи всегда получают чистое и ухоженное белье.",
          projDryoKeyLi5H: "Прозрачность:",
          projDryoKeyLi5Para:
            "Приложение обеспечивает прозрачность на протяжении всего процесса стирки, позволяя пользователям отслеживать статус своего белья от забора до доставки. Такая прозрачность вселяет доверие и уверенность в пользователях в отношении безопасности и сохранности их одежды.",
          projDryoKeyLi6H: "Экологичность:",
          projDryoKeyLi6Para:
            "Dryo способствует устойчивому развитию, предлагая экологически чистые варианты стирки, такие как использование экологически безопасных моющих средств и энергоэффективных стиральных машин. Это привлекает пользователей, заботящихся об окружающей среде, которые отдают приоритет экологически чистым методам.",
          projDryoKeyLi7H: "Доступность:",
          projDryoKeyLi7Para:
            "Несмотря на удобство и качество обслуживания, Dryo остается доступным, предлагая конкурентоспособные цены по сравнению с традиционными услугами прачечной. Это делает профессиональную прачечную доступной для более широкого круга пользователей.",
          projDryoKeyLi8H: "Удобный интерфейс:",
          projDryoKeyLi8Para:
            "Приложение имеет удобный интерфейс, в котором легко ориентироваться, что делает процесс бронирования прачечной простым и интуитивно понятным для пользователей всех возрастов и технологических знаний.",
          projBaliH: "Клиент",
          projBaliPara: "Balitek",
          projBaliDurH: "Длительность",
          projBaliDurPara: "2 месяца",
          projBaliDateH: "Дата",
          projBaliDatePara: "29 фев 2024",
          projBaliCateH: "Категория",
          projBaliCatePara: "IT-решения",
          projBaliKeyLi1H:
            "Бизнес-консалтинг в области информационных технологий:",
          projBaliKeyLi1Para:
            "Воспользуйтесь нашими стратегическими знаниями, чтобы привести свою ИТ-инфраструктуру в соответствие с бизнес-целями. Наши консультанты работают вместе, чтобы оптимизировать вашу технологическую стратегию для достижения максимальной эффективности.",
          projBaliKeyLi2H: "Управляемые ИТ-услуги:",
          projBaliKeyLi2Para:
            "Доверьте свои ИТ-операции нашим специалистам. Наши управляемые ИТ-услуги обеспечивают упреждающий мониторинг, обслуживание и поддержку, позволяя вам сосредоточиться на основных бизнес-функциях.",
          projBaliKeyLi3H: "Облачные решения:",
          projBaliKeyLi3Para:
            "Оцените гибкость и масштабируемость облака с помощью наших специализированных решений. От миграции до оптимизации — мы поможем вам на каждом этапе, чтобы использовать весь потенциал облачных технологий.",
          projBaliKeyLi4H: "ИТ-безопасность:",
          projBaliKeyLi4Para:
            "Защитите свои цифровые активы с помощью наших надежных мер ИТ-безопасности. Мы используем передовые технологии и лучшие отраслевые практики для защиты вашего бизнеса от развивающихся киберугроз.",
          projBaliKeyLi5H: "Проектирование и внедрение сетевой инфраструктуры:",
          projBaliKeyLi5Para:
            "Создайте надежную и масштабируемую сетевую инфраструктуру, адаптированную к потребностям вашего бизнеса. Наши специалисты разрабатывают и внедряют решения, обеспечивающие бесперебойную связь и оптимальную производительность.",
          projBaliKeyLi6H: "Разработка программного обеспечения:",
          projBaliKeyLi6Para:
            "Воплотите свои идеи в реальность с помощью наших услуг по разработке программного обеспечения на заказ. Мы специализируемся на создании масштабируемых, эффективных и удобных в использовании решений для удовлетворения ваших уникальных требований.",
          projBaliKeyLi7H: "Разработка приложений:",
          projBaliKeyLi7Para:
            "Оставайтесь на шаг впереди в цифровом ландшафте с помощью наших услуг по разработке приложений на заказ. От мобильных приложений до корпоративных решений — мы создаем приложения, которые стимулируют инновации и улучшают пользовательский опыт.",
          projBaliKeyLi8H: "Веб-разработка:",
          projBaliKeyLi8Para:
            "Обеспечьте сильное присутствие в Интернете с помощью наших услуг по веб-разработке. Наши опытные разработчики создают визуально ошеломляющие и функциональные веб-сайты, обеспечивая положительный и привлекательный пользовательский опыт.",
          projPayH: "Клиент",
          projPayPara: "Атланта",
          projPayDUrH: "Длительность",
          projPayDurPara: "6 месяцев",
          projPayDateH: "Дата",
          projPayDatePara: "Развиваться",
          projPayCateH: "Категория",
          projPayCatePara: "Управление персоналом по расчету заработной платы",
          projPayKeyLi1H: "Автоматизированные расчеты:",
          projPayKeyLi1Para:
            "Приложения для расчета заработной платы автоматизируют сложные расчеты, связанные с заработной платой сотрудников, налогами, вычетами и льготами. Это снижает вероятность человеческой ошибки и обеспечивает точную оплату труда сотрудников.",
          projPayKeyLi2H: "Экономия времени:",
          projPayKeyLi2Para:
            "Автоматизируя процессы расчета заработной платы, приложение экономит время HR и финансовых отделов, позволяя им сосредоточиться на других стратегических задачах, а не тратить часы на ручную обработку расчета заработной платы в каждом платежном периоде.",
          projPayKeyLi3H: "Соблюдение налогового законодательства:",
          projPayKeyLi3Para:
            "Приложения для расчета заработной платы часто интегрируют налоговые таблицы и нормативные акты, гарантируя, что налоги на заработную плату рассчитываются точно и что компания соблюдает налоговое законодательство на всех уровнях (местном, региональном, федеральном).",
          projPayKeyLi4H: "Прямой депозит:",
          projPayKeyLi4Para:
            "Многие приложения для расчета заработной платы способствуют прямому переводу, позволяя сотрудникам получать заработную плату непосредственно на свои банковские счета. Это устраняет необходимость в бумажных чеках и снижает административные издержки.",
          projPayKeyLi5H: "Самообслуживание сотрудников:",
          projPayKeyLi5Para:
            "Современные приложения для расчета заработной платы часто включают в себя порталы самообслуживания, где сотрудники могут получить доступ к квитанциям о заработной плате, налоговым формам и обновить личную информацию, такую как адрес или реквизиты банковского счета, что снижает нагрузку на персонал отдела кадров.",
          projPayKeyLi6H: "Настраиваемая отчетность:",
          projPayKeyLi6Para:
            "Приложения для расчета заработной платы предлагают настраиваемые функции отчетности, позволяющие предприятиям создавать различные отчеты, такие как сводки по заработной плате, налоговые отчеты и отчеты о доходах сотрудников, адаптированные к их конкретным потребностям.",
          projPayKeyLi7H: "Возможности интеграции:",
          projPayKeyLi7Para:
            "Эти приложения могут интегрироваться с другими системами, такими как бухгалтерское программное обеспечение, системы учета рабочего времени и управления персоналом, оптимизируя поток данных и устраняя избыточный ввод данных.",
          projPayKeyLi8H: "Комплексное управление льготами:",
          projPayKeyLi8Para:
            "Некоторые приложения для расчета заработной платы включают функции для управления льготами для сотрудников, такими как здравоохранение, пенсионные планы и оплачиваемые отгулы. Это гарантирует, что все аспекты вознаграждения сотрудников обрабатываются эффективно.",
          projPayKeyLi9H: "Оповещения о соответствии нормативным требованиям:",
          projPayKeyLi9Para:
            "Приложения для расчета заработной платы часто включают оповещения о соответствии требованиям и напоминания для уведомления администраторов о приближающихся сроках, изменениях в нормативных актах или любых действиях, необходимых для соблюдения трудового законодательства и нормативных актов.",
          projPayKeyLi10H: "Масштабируемость:",
          projPayKeyLi10Para:
            "Приложения для расчета заработной платы предназначены для адаптации к росту бизнеса. Независимо от того, является ли компания маленькой или большой, приложение можно масштабировать, чтобы справляться с растущим числом сотрудников и сложностями расчета заработной платы без ущерба для производительности или эффективности.",
          projCabsH: "Клиент",
          projCabsPara: "Kerala Cabs",
          projCabsDurH: "Длительность",
          projCabsDurPara: "Продолжается",
          projCabsDateH: "Дата",
          projCabsDataPara: "Развиваться",
          projCabsCateH: "Категория",
          projCabsCatePara: "Служба такси",
          projCabsKeyLi1H: "Региональная интеграция:",
          projCabsKeyLi1Para:
            "Специально разработано для региона Керала, обеспечивая бесперебойную навигацию как по городским, так и по сельским районам.",
          projCabsKeyLi2H: "Индивидуальные варианты бронирования:",
          projCabsKeyLi2Para:
            "Предоставляет различные варианты бронирования, такие как двухточечная аренда, почасовая аренда и поездки за пределы станции, удовлетворяя различные потребности пользователей в поездках..",
          projCabsKeyLi3H: "Верификация водителя:",
          projCabsKeyLi3Para:
            "Обеспечивает тщательную проверку биографических данных и верификацию водителей, чтобы гарантировать безопасность и надежность для пассажиров.",
          projCabsKeyLi4H: "Отслеживание в режиме реального времени:",
          projCabsKeyLi4Para:
            "Позволяет пользователям отслеживать свою кабину в режиме реального времени в приложении, обеспечивая прозрачность и спокойствие на протяжении всей поездки.",
          projCabsKeyLi5H: "Рейтинги и отзывы водителей:",
          projCabsKeyLi5Para:
            "Позволяет пассажирам оценивать и оставлять отзывы о водителях после поездки, повышая подотчетность и поддерживая стандарты качества обслуживания.",
          projCabsKeyLi6H: "Интеграция с местными достопримечательностями:",
          projCabsKeyLi6Para:
            "Предлагает рекомендации и интегрированные услуги по бронированию местных туристических достопримечательностей, улучшая общее впечатление от путешествия для посетителей Кералы.",
          projCabsKeyLi7H: "Прозрачность тарифов:",
          projCabsKeyLi7Para:
            "Отображает предварительную оценку стоимости проезда в зависимости от расстояния и времени, обеспечивая прозрачность и избегая сюрпризов для пассажиров.",
          projCabsKeyLi8H: "Акции и скидки:",
          projCabsKeyLi8Para:
            "Предоставляет регулярные акции и скидки пользователям, делая поездки более доступными и повышая лояльность клиентов.",
          projCabsKeyLi9H: "Безналичный расчет:",
          projCabsKeyLi9Para:
            "Предлагает удобные варианты безналичных платежей, такие как кредитные/дебетовые карты, цифровые кошельки и UPI, уменьшая хлопоты, связанные с ношением наличных денег, и способствуя бесконтактным транзакциям..",
          projLogiH: "Клиент",
          projLogiPara: "Al Razaa Logistics",
          projLogiDurH: "Длительность",
          projLogiDurPara: "2 месяца",
          projLogiDateH: "Дата",
          projLogiDatePara: "Развиваться",
          projLogiCateH: "Категория",
          projLogiCatePara: "Логистика",
          projLogiKeyLi1H: "Удобный интерфейс:",
          projLogiKeyLi1Para:
            "Простая в использовании платформа для эффективного управления отправлениями.",
          projLogiKeyLi2H: "Быстрое бронирование доставки:",
          projLogiKeyLi2Para:
            "Оптимизированный процесс для быстрого и беспроблемного бронирования.",
          projLogiKeyLi3H: "Отслеживание в режиме реального времени:",
          projLogiKeyLi3Para:
            "Усовершенствованные системы слежения для мониторинга грузов в режиме реального времени.",
          projLogiKeyLi4H: "Индивидуальные решения:",
          projLogiKeyLi4Para:
            "Индивидуальные логистические возможности для различных потребностей бизнеса.",
          projLogiKeyLi5H: "Прозрачное ценообразование",
          projLogiKeyLi5Para:
            "Четкая структура ценообразования и предварительная оценка затрат.",
          projLogiKeyLi6H: "Поддержка клиентов 24/7:",
          projLogiKeyLi6Para:
            "Круглосуточная помощь по запросам и проблемам пользователей.",
          projLogiKeyLi7H: "Прозрачность цепочки поставок:",
          projLogiKeyLi7Para:
            "Улучшенная видимость для улучшения совместной работы и планирования.",
          projLogiKeyLi8H: "Управление риском:",
          projLogiKeyLi8Para:
            "Надежные меры по обеспечению сохранности грузов и управлению рисками.",
          projLogiKeyLi9H: "Экологическая устойчивость:",
          projLogiKeyLi9Para:
            "Приверженность экологически чистым методам и решениям.",
          projLogiKeyLi10H: "Постоянное совершенствование:",
          projLogiKeyLi10Para:
            "Усовершенствования, основанные на отзывах, для повышения качества обслуживания и опыта.",
          projOvanH: "Клиент",
          projOvanPara: "Ovantica",
          projOvanDurH: "Длительность",
          projOvanDurPara: "6 месяцев",
          projOvanDateH: "Дата",
          projOvanDatePara: "20 а 2017",
          projOvanCateH: "Категория",
          projOvanCatePara: "Электронная коммерция",
          projOvanKeyLi1H: "Удобный интерфейс:",
          projOvanKeyLi1Para:
            "Интуитивно понятный дизайн веб-сайтов и приложений для удобной навигации и удобного взаимодействия с пользователем.",
          projOvanKeyLi2H: "Списки продуктов:",
          projOvanKeyLi2Para:
            "Полный список подержанной электроники с подробным описанием, включая характеристики, состояние и цены.",
          projOvanKeyLi3H: "Параметры поиска и фильтрации:",
          projOvanKeyLi3Para:
            "Расширенные функции поиска и фильтрации, помогающие пользователям быстро находить конкретные модели электроники, бренды, условия и т. д..",
          projOvanKeyLi4H: "Опция гарантии:",
          projOvanKeyLi4Para:
            "Предложите варианты гарантии на подержанные телефоны, чтобы вселить уверенность в покупателей и обеспечить гарантию качества продукции.",
          projOvanKeyLi5H: "Продайте свою старую функцию электроники:",
          projOvanKeyLi5Para:
            "Специальный раздел, где пользователи могут легко выставить свою старую электронику на продажу, включая опции для описания состояния, загрузки фотографий и установки цены.",
          projOvanKeyLi6H: "Безопасные транзакции:",
          projOvanKeyLi6Para:
            "Внедрите безопасные платежные шлюзы и обеспечьте шифрование данных для защиты финансовой информации пользователей во время транзакций.",
          projOvanKeyLi7H: "Гарантия качества:",
          projOvanKeyLi7Para:
            "Проводите строгие проверки качества и инспекции всей подержанной электроники перед ее размещением на платформе, чтобы убедиться, что она соответствует стандартам качества.",
          projOvanKeyLi8H: "Поддержка клиентов:",
          projOvanKeyLi8Para:
            "Предложите гибкие каналы поддержки клиентов, такие как онлайн-чат, электронная почта или поддержка по телефону, чтобы помочь пользователям с любыми запросами или проблемами, которые могут у них возникнуть.",
          projOvanKeyLi9H: "Акции и скидки:",
          projOvanKeyLi9Para:
            "Предлагайте рекламные предложения, скидки и вознаграждения за лояльность, чтобы привлечь клиентов и побудить к повторным покупкам.",
          projPureH: "Клиент",
          projPurePara: "Rice Purity Club",
          projPureDurH: "Длительность",
          projPureDurPara: "1 месяц",
          projPureDateH: "Дата",
          projPureDatePara: "15 Апр 2024",
          projPureCateH: "Категория",
          projPureCatePara: "Развлечение",
          projPureKeyLi1H: "Формат анкеты:",
          projPureKeyLi1Para:
            "Тест представлен в виде анкеты с серией вопросов или утверждений с несколькими вариантами ответов.",
          projPureKeyLi2H: "Большая база вопросов:",
          projPureKeyLi2Para:
            "Как правило, он содержит большое количество вопросов, охватывающих широкий круг тем, таких как сексуальность, наркотики, отношения и личное поведение.",
          projPureKeyLi3H: "Система самооценки:",
          projPureKeyLi3Para:
            "После заполнения анкеты респонденты могут отправить свои ответы и получить мгновенную оценку чистоты на основе своих ответов.",
          projPureKeyLi4H: "Параметры конфиденциальности:",
          projPureKeyLi4Para:
            "Некоторые веб-сайты предлагают варианты конфиденциальности, позволяющие пользователям проходить тест анонимно, не делясь своими результатами публично.",
          projLetsH: "Давайте создадим проект вашей мечты",
          projLetsPara:
            "Вы хотите иметь сайт, который выделяется и впечатляет ваших клиентов? Тогда мы готовы помочь! Нажмите кнопку ниже, чтобы связаться с нами и обсудить ваши идеи.",
          projLetsBtn: "Давайте начнем",

          projSpeedH: "Клиент",
          projSpeedPara: "SPEED-P",
          projSpeedDurH: "Длительность",
          projSpeedDurPara: "2 месяца",
          projSpeedDateH: "Дата",
          projSpeedDatePara: "15 Апр 2024",
          projSpeedCateH: "Категория",
          projSpeedCatePara: "Сбытовой",
          projSpeedKeyLi1H: "Комплексная аналитика:",
          projSpeedKeyLi1Para:
            "Предоставляет подробную аналитику и отчеты по различным бизнес-функциям, позволяя принимать решения на основе данных.",
          projSpeedKeyLi2H: "Расширенное планирование и составление графиков:",
          projSpeedKeyLi2Para:
            "Оптимизирует распределение ресурсов и сроки реализации проектов для повышения операционной эффективности и производительности.",
          projSpeedKeyLi3H: "Управление активами предприятия:",
          projSpeedKeyLi3Para:
            "Отслеживает и управляет физическими активами, обеспечивая их оптимальное использование и техническое обслуживание.",
          projSpeedKeyLi4H: "Управление взаимоотношениями с клиентами (CRM):",
          projSpeedKeyLi4Para:
            "Управление взаимодействием с клиентами, процессами продаж и обслуживанием клиентов для повышения удовлетворенности и удержания клиентов.",
          projSpeedKeyLi5H: "Управление цепочками поставок:",
          projSpeedKeyLi5Para:
            "Оптимизирует операции в цепочке поставок, включая закупки, управление запасами и логистику, для снижения затрат и повышения эффективности.",
          projSpeedKeyLi6H: "Управление человеческим капиталом (HCM):",
          projSpeedKeyLi6Para:
            "Управление процессами жизненного цикла сотрудников, от найма и адаптации до управления эффективностью и расчета заработной платы.",
          projSpeedKeyLi7H: "Управление производством:",
          projSpeedKeyLi7Para:
            "Облегчает планирование, выполнение и мониторинг производства для обеспечения эффективности производственных процессов и соответствия стандартам качества.",
          projSpeedKeyLi8H: "Управление финансами:",
          projSpeedKeyLi8Para:
            "Обрабатывает финансовые операции, включая бухгалтерский учет, бюджетирование и финансовую отчетность, для поддержания финансового здоровья и соответствия нормативным требованиям..",
          //Meat market
          projMMH: "Клиент",
          projMMPara: "Мясной рынок",
          projMMDurH: "Продолжительность",
          projMMDurPara: "2 месяца",
          projMMDateH: "Дата",
          projMMDatePara: "10 августа 2024",
          projMMCateH: "Категория",
          projMMCatePara: "Электронная коммерция",
          projMMKeyLi1H: "Настраиваемые графики доставки:",
          projMMKeyLi1Para:
            "Позволяет клиентам выбирать определенные временные слоты для доставки, чтобы обеспечить своевременную доставку свежего мяса.",
          projMMKeyLi2H: "Отслеживание заказов в реальном времени:",
          projMMKeyLi2Para:
            "Предоставляет возможность отслеживания заказов в режиме реального времени, от подготовки до доставки, чтобы клиенты были в курсе и вовлечены.",
          projMMKeyLi3H: "Эксклюзивные скидки для участников:",
          projMMKeyLi3Para:
            "Создайте программу лояльности, где частые покупатели получают доступ к специальным предложениям и скидкам на любимые товары.",
          projMMKeyLi4H: "Гарантия свежести:",
          projMMKeyLi4Para:
            "Гарантирует наивысшее качество и свежесть мяса с четкой политикой возврата, если стандарты не соответствуют.",
          projMMKeyLi5H: "Скидки на оптовые заказы:",
          projMMKeyLi5Para:
            "Предлагает специальные цены для крупных заказов, удовлетворяя потребности семей или оптовых покупателей, обеспечивая экономию.",
          projMMKeyLi6H: "Гибкие способы оплаты:",
          projMMKeyLi6Para:
            "Поддерживает различные способы оплаты, включая оплату при доставке, кредитные/дебетовые карты и цифровые кошельки для дополнительного удобства.",
          //RG
          projrgH: "Клиент",
          projrgPara: "RG Designs",
          projrgDurH: "Продолжительность",
          projrgDurPara: "2 месяца",
          projrgDateH: "Дата",
          projrgDatePara: "10 сентября 2024",
          projrgCateH: "Категория",
          projrgCatePara: "Электронная коммерция",
          projrgKeyLi1H: "Персонализированная цифровая вышивка:",
          projrgKeyLi1Para:
            "RG Designs предлагает персонализированные услуги оцифровки для преобразования любого логотипа, изображения или графики в файл вышивки, готовый к использованию на машине. Это обеспечивает идеальное качество стежков, точность и масштабируемость в различных форматах.",
          projrgKeyLi2H: "Разнообразные узоры для вышивки:",
          projrgKeyLi2Para:
            "Платформа предлагает обширную библиотеку готовых дизайнов для вышивки, охватывающих различные темы, включая праздники, природу и специальные мероприятия. Эти дизайны оптимизированы для различных типов машин, обеспечивая совместимость и простоту использования.",
          projrgKeyLi3H: "Продвинутые инструменты редактирования:",
          projrgKeyLi3Para:
            "RG Designs предоставляет мощные онлайн-инструменты для настройки и редактирования дизайнов, позволяя клиентам регулировать типы стежков, цвета и узоры в соответствии с потребностями их проектов.",
          projrgKeyLi4H: "Высокое разрешение стежков:",
          projrgKeyLi4Para:
            "Все дизайны для вышивки создаются с высококачественными стежковыми узорами, обеспечивая чистый и профессиональный результат на любом материале, от тканей до более сложных текстилей.",
          projrgKeyLi5H: "Опции для крупномасштабных дизайнов:",
          projrgKeyLi5Para:
            "Для крупных проектов RG Designs предлагает варианты для массового дизайна, позволяя клиентам приобретать коллекции по конкурентоспособным ценам, что идеально подходит для бизнеса или профессиональных вышивальщиков.",
          projrgKeyLi6H: "Быстрое время выполнения заказа:",
          projrgKeyLi6Para:
            "RG Designs гордится тем, что предлагает быстрые и надежные услуги оцифровки, гарантируя, что индивидуальные заказы обрабатываются и доставляются своевременно, соответствуя строгим срокам проекта.",

          //Project end

          //Header Start
          navHome: "Дом",
          navServices: "Услуги",
          navServicesSub1: "Веб-разработка",
          navServicesSub2: "Разработка мобильных приложений",
          navServicesSub3: "Разработка программного обеспечения",
          navServicesSub4: "DevOps",
          navServicesSub5: "Облачные вычисления",
          navServicesSub6: "Цифровой маркетинг",
          navProject: "Проект",
          navAbout: "О нас",
          navContact: "Контакт",
          navEnquiry: "Для запроса",
        },
      },
    },
  });
