import React, { useEffect } from "react";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";
import "../css/flaticon.min.css";
import "../css/fontawesome-5.14.0.min.css";
import "../Main.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Cloud = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          Unlock the Power of the Cloud: Cloud Computing Services | The
          TechHorse
        </title>
        <meta
          name="description"
          content="Elevate your business with our comprehensive cloud computing services. Scale, secure, and innovate with confidence. Explore now!"
        />
      </Helmet>
      <Header />
      <section className="page-banner-area cloud-banner pt-245 rpt-150 pb-170 rpb-100 rel z-1 bgc-lighter text-center">
        <div className="container">
          <div className="banner-inner rpt-10">
            <h1 className="page-title wow fadeInUp delay-0-2s">
              {t("cloudBannerH")} <span>{t("cloudBannerSpan")}</span>
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <a>{t("cloudBannerHome")}</a>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  style={{ color: "#fff" }}
                >
                  {t("cloudBannerHomeSpan")}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="banner-shapes">
          <div
            className="circle cloudcircle wow zoomInLeft delay-0-2s"
            data-wow-duration="2s"
          />
          <img
            className="shape-one"
            src="/images/shapes/hero-shape1.png"
            alt="Shape"
            loading="lazy"
          />
          <img
            className="shape-two"
            src="/images/shapes/hero-shape2.png"
            alt="Shape"
            loading="lazy"
          />
        </div>
      </section>
      {/* Statistics Five Area Start */}
      <section className="statistics-area-five py-80">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="statistics-five-content mb-30 rmb-65 wow fadeInRight delay-0-2s">
                <div className="section-title mb-25">
                  <span className="sub-title style-two cloudHead mb-15">
                    {t("cloudCompH")}
                  </span>
                  <h2>{t("cloudCompSub")} </h2>
                </div>
                <p>{t("cloudCompPara")}</p>
                <Link to="/contact-us">
                  <a className="theme-btn cloudlearn style-two mt-15">
                    {t("learnMoreBtn")}{" "}
                    <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="statistics-five-image wow fadeInLeft delay-0-2s">
                <img
                  src="/assets/6 cloud/img1.webp"
                  alt="Statistics"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Statistics Five Area end */}
      {/* Services Area start */}
      <section className="services-area pt-80 pb-80 rel z-1">
        <div className="container">
          <div className="section-title text-center mb-55">
            <span className="sub-title mb-15 cloudHead">{t("cloudSerH")}</span>
            <h2>{t("cloudSerSub")}</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="service-item cloudboxHov cloudboxHov wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="fi fi-tr-model-cube cloudboxicon" />
                </div>
                <div className="content">
                  <h5>
                    <a>{t("cloudSerli1H")}</a>
                  </h5>
                  <p>{t("cloudSerli1Para")} </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="service-item cloudboxHov wow fadeInUp delay-0-4s">
                <div className="icon">
                  <i className="fi fi-tr-file-medical-alt cloudboxicon" />
                </div>
                <div className="content">
                  <h5>
                    <a>{t("cloudSerli2H")}</a>
                  </h5>
                  <p>{t("cloudSerli2Para")}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="service-item cloudboxHov wow fadeInUp delay-0-6s">
                <div className="icon">
                  <i className="flaticon-optimization cloudboxicon" />
                </div>
                <div className="content">
                  <h5>
                    <a>{t("cloudSerli3H")}</a>
                  </h5>
                  <p>{t("cloudSerli3Para")}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="service-item cloudboxHov wow fadeInUp delay-0-8s">
                <div className="icon">
                  <i className="fi fi-tr-nfc-lock cloudboxicon" />
                </div>
                <div className="content">
                  <h5>
                    <a>{t("cloudSerli4H")}</a>
                  </h5>
                  <p> {t("cloudSerli4Para")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Services Area end */}
      <section className="why-choose-two-area py-80 rel z-1">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="why-choose-two-image rmb-65 wow fadeInUp delay-0-4s">
                <img
                  src="/assets/6 cloud/img2.webp"
                  alt="why choose us"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="why-choose-two-content wow fadeInUp delay-0-2s">
                <div className="section-title mb-35">
                  <span className="sub-title style-two mb-15 cloudHead">
                    {t("cloudwhyH")}
                  </span>
                  <h2>{t("cloudwhySub")}</h2>
                </div>
                <ul className="list-style-one cloudul">
                  <li>
                    <div className="content">
                      <h5>{t("cloudwhyLi1H")}</h5>
                      <p>{t("cloudwhyLi1Para")}</p>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <h5>{t("cloudwhyLi2H")}</h5>
                      <p>{t("cloudwhyLi2Para")}</p>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <h5>{t("cloudwhyLi3H")}</h5>
                      <p>{t("cloudwhyLi3Para")}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="call-to-action-area bgc-black pt-80 pb-80"
        style={{ backgroundColor: "#ecbe88" }}
      >
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-white mb-25 wow fadeInUp delay-0-2s">
                <h2>{t("cloudEveH")}</h2>
                <p>{t("cloudEvePara")}</p>
              </div>
            </div>
            <div className="col-lg-3 text-lg-end">
              <Link to="/contact-us">
                <a className="theme-btn cloudLearnbtn style-two mb-30 wow fadeInUp delay-0-4s">
                  {t("cloudLetsbtn")}{" "}
                  <i className="fas fa-angle-double-right" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Cloud;
