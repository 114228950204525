import React from 'react'
import HomeMain from '../Home/HomeMain'
import WebDevelop from '../Services/WebDevelop'
import MobileDevelop from '../Services/MobileDevelop'
import SoftwareDevelop from '../Services/SoftwareDevelop'
import Devops from '../Services/Devops'
import Cloud from '../Services/Cloud'
import Digital from '../Services/Digital'
import Aboutus from '../Aboutus'
import Contact from '../Contact'
import Project from '../Project'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const Routing = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<HomeMain/>}></Route>
                    <Route path='/web-development' element={<WebDevelop/>}></Route>
                    <Route path='/mobile-app-development' element={<MobileDevelop/>}></Route>
                    <Route path='/software-development' element={<SoftwareDevelop/>}></Route>
                    <Route path='/dev-ops' element={<Devops/>}></Route>
                    <Route path='/cloud-computing' element={<Cloud/>}></Route>
                    <Route path='/digital-marketing' element={<Digital/>}></Route>
                    <Route path='/projects' element={<Project/>}></Route>
                    <Route path='/about-us' element={<Aboutus/>}></Route>
                    <Route path='/contact-us' element={<Contact/>}></Route>
                </Routes>
            </BrowserRouter>            
        </>
    )
}

export default Routing
