import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import CoreFeatures from "./CoreFeatures";
import "../Main.css";
import { Helmet } from "react-helmet";

const HomeMain = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <title>The TechHorse: Empowering Your Digital Presence</title>
        <meta
          name="description"
          content="Explore comprehensive digital solutions at TheTechHorse. From web design to SEO, we're your partner in online success. Elevate your digital presence today!"
        />
      </Helmet>
      <CoreFeatures />
      <Footer />
    </>
  );
};

export default HomeMain;
