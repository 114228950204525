import { sideBarToggle } from "./utils";
import MobileMenu from "./MobileMenu";
import React, { useEffect, useState } from "react";
import Menu from "./Menu";
import "../Main.css";
import SideBar from "./SideBar";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <>
      <Header1 />
    </>
  );
};
export default Header;

const Header1 = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      // Determine the scroll threshold where the header should become fixed
      const scrollThreshold = 100;

      // Update the state based on the scroll position
      setScrolled(scrollTop > scrollThreshold);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove scroll event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`main-header header-two ${scrolled ? "fixed-header" : ""}`}
    >
      {/*Header-Upper*/}
      <div className="header-upper">
        <div className="container clearfix">
          <div className="header-inner rel d-flex align-items-center justify-content-between">
            <div className="logo-outer">
              <div className="logo">
                <Link to="/">
                  <a>
                    <img
                      src="/assets/tth_logo.png"
                      alt="Logo"
                      title="Logo"
                      style={{ height: "45px", width: "auto" }}
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="nav-outer clearfix mx-0">
              <MobileMenu logo={"/images/logos/logo-two.png"} />
              {/* Main Menu */}
              <nav className="main-menu d-none d-lg-block navbar-expand-lg">
                <div className="navbar-header">
                  <div className="mobile-logo my-15">
                    <a href="/">
                      <a>
                        <img
                          src="/images/logos/logo-two.png"
                          alt="Logo"
                          title="Logo"
                        />
                      </a>
                    </a>
                  </div>
                  {/* Toggle Button */}
                  <button
                    type="button"
                    className="navbar-toggle"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse"
                  >
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                <div className="navbar-collapse collapse clearfix">
                  <Menu />
                </div>
              </nav>
              {/* Main Menu End*/}
            </div>
            <div className="menu-btns">
              <div className="menu-sidebar d-none d-lg-block">
                <button onClick={() => sideBarToggle()}>
                  <span
                    className="icon-bar"
                    style={{ backgroundColor: "#000" }}
                  />
                  <span
                    className="icon-bar"
                    style={{ backgroundColor: "#000" }}
                  />
                  <span
                    className="icon-bar"
                    style={{ backgroundColor: "#000" }}
                  />
                </button>
                <SideBar />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*End Header Upper*/}
    </header>
  );
};
