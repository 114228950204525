import Slider from "react-slick";
import "../Main.css";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Hero1 = () => {
  const props = {
    infinite: true,
    arrows: false,
    dots: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    slidesToScroll: 1,
    slidesToShow: 1,
  };

  const { t } = useTranslation();
  return (
    <section
      id="home"
      className="main-slider-area bgc-black-with-lighting rel z-1"
      style={{ overflow: "hidden" }}
    >
      <Slider {...props} className="main-slider-active">
        <div className="slider-item">
          <div className="container">
            <div className="slider-content">
              <span className="sub-title">{t("bannerSpan1")}</span>
              <h1>{t("bannerH1")}</h1>
              <div className="slider-btns">
                <Link to="/contact-us">
                  <a className="theme-btn">
                    {t("getStartBtn")}{" "}
                    <FontAwesomeIcon
                      className="fas mx-2"
                      icon={faAngleDoubleRight}
                    />
                  </a>
                </Link>
                {/* <a href="">
                  <a className="theme-btn style-three" style={{color:"#fff"}}>
                    Explore Services <FontAwesomeIcon className="fas mx-2" icon={faAngleDoubleRight} />
                  </a>
                </a> */}
              </div>
            </div>
          </div>
          <div
            className="slider-video"
            style={{
              backgroundImage: "url(/assets/banner1.webp)",
            }}
          >
            {/* <a
              href="https://www.youtube.com/watch?v=9Y7ma241N8k"
              target="_blank"
              className="mfp-iframe video-play"
            >
             <FaPlay className="fas"/>
            </a> */}
            <span className="video-title cd-headline clip">
              <span className="cd-words-wrapper">
                <span className="is-visible">{t("bannerimgSpan1")}</span>
              </span>
            </span>
          </div>
        </div>
        <div className="slider-item">
          <div className="container">
            <div className="slider-content">
              <span className="sub-title">{t("bannerSpan2")}</span>
              <h1>{t("bannerH2")}</h1>
              <div className="slider-btns">
                <Link to="/contact-us">
                  <a className="theme-btn">
                    {t("getStartBtn")}{" "}
                    <FontAwesomeIcon
                      className="fas mx-2"
                      icon={faAngleDoubleRight}
                    />
                  </a>
                </Link>
                {/* <a href="">
                  <a className="theme-btn style-three"  style={{color:"#fff"}}>
                    Explore Services <FontAwesomeIcon className="fas mx-2" icon={faAngleDoubleRight}/>
                  </a>
                </a> */}
              </div>
            </div>
          </div>
          <div
            className="slider-video"
            style={{
              backgroundImage: "url(/assets/banner2.webp)",
            }}
          >
            {/* <a
              href="https://www.youtube.com/watch?v=9Y7ma241N8k"
              className="mfp-iframe video-play"
              target="_blank"
            >
              <FaPlay className="fas"/>
            </a> */}
            <span className="video-title cd-headline clip">
              <span className="cd-words-wrapper">
                <span className="is-visible">{t("bannerimgSpan2")}</span>
              </span>
            </span>
          </div>
        </div>
      </Slider>
      <div className="container">
        <div className="main-slider-dots" />
      </div>
      <div className="slider-shapes">
        <img
          className="shape dots one"
          src="/images/shapes/slider-dots.png"
          alt="Shape"
          loading="lazy"
        />
        <img
          className="shape dots two"
          src="/images/shapes/slider-dots.png"
          alt="Shape"
          loading="lazy"
        />
        <img
          className="shape wave-line"
          src="/images/shapes/slider-wave-line.png"
          alt="Shape"
          loading="lazy"
        />
        <img
          className="shape circle"
          src="/images/shapes/slider-circle.png"
          alt="Shape"
          loading="lazy"
        />
      </div>
    </section>
  );
};
export default Hero1;
