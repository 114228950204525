import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18"
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
    <a
      className="whatsapp"
      href="https://api.whatsapp.com/send/?phone=919799184337&text&type=phone_number&app_absent=0"
      target="_blank"
    >
      <img
        alt="whatsapp"
        className="img-fluid whatsapp-img"
        src="/assets/whatsapp.png"
      />
    </a>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
