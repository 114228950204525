import PageBanner from "./PageBanner";
import Header from "./Home/Header";
import Footer from "./Footer/Footer";
import "./css/flaticon.min.css";
import "./css/fontawesome-5.14.0.min.css";
import "./Main.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Contact = () => {
  const [data, setData] = useState({
    name: "",
    mail: "",
    phonenumber: "",
    subject: "",
    message: "",
  });

  const [isLoader, setIsloader] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleContact = async (e: any) => {
    e.preventDefault();
    try {
      setIsloader(true);
      await axios.post("https://tth-website-api.onrender.com/ContactUs", {
        name: data.name,
        userEmail: data.mail,
        userPhonenumber: data.phonenumber,
        subject: data.subject,
        message: data.message,
      });
      setOpen(true);
      setData({
        name: "",
        mail: "",
        phonenumber: "",
        subject: "",
        message: "",
      });
      setIsloader(false);
    } catch (err: any) {
      setIsloader(false);
      console.log(err.message);
      alert("Got internal error");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Connect with us today! Our team is ready to assist you. Reach out for inquiries, collaborations, or just to say hello. Let's start a conversation!"
        />
      </Helmet>
      <Header />
      <section
        className="page-banner-area contactBanner  pt-245 rpt-150 pb-170 rpb-100 rel z-1 bgc-lighter text-center"
        style={{ backgroundColor: "#f7f9fd" }}
      >
        <div className="container">
          <div className="banner-inner rpt-10">
            <h1 className="page-title wow fadeInUp delay-0-2s">
              {t("contactBannerH")}
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <a>{t("contactBannerHome")}</a>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  style={{ color: "#000" }}
                >
                  {t("contactBannerHomeNav")}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="banner-shapes">
          <div
            style={{ backgroundColor: "#fff" }}
            className="circle contactCircle  wow zoomInLeft delay-0-2s"
            data-wow-duration="2s"
          />
          <img
            className="shape-one"
            src="/images/shapes/hero-shape1.png"
            alt="Shape"
            loading="lazy"
          />
          <img
            className="shape-two"
            src="/images/shapes/hero-shape2.png"
            alt="Shape"
            loading="lazy"
          />
        </div>
      </section>
      <section className="contact-us-page-area py-130">
        <div className="container">
          <div className="row align-items-end justify-content-between">
            <div className="col-lg-7">
              <div className="contact-content rmb-65 wow fadeInRight delay-0-2s">
                <div className="section-title mb-25">
                  <span className="sub-title style-two mb-15 contactHead">
                    {t("contactusH")}
                  </span>
                  <h2>{t("contactusSub")} </h2>
                </div>
                <p>{t("contactusPara")}</p>
                <form
                  onSubmit={handleContact}
                  id="contactForm"
                  className="contactForm z-1 rel"
                >
                  <div className="row pt-15">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">
                          {t("contactfullnamelabel")}
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          defaultValue=""
                          placeholder={`${t("contactfullnamePlace")}`}
                          required
                          onChange={(e) =>
                            setData({ ...data, name: e.target.value })
                          }
                          data-error="Please enter your name"
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="email">
                          {t("contactfullemaillabel")}
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          defaultValue=""
                          placeholder={`${t("contactfullemailPlace")}`}
                          required
                          onChange={(e) =>
                            setData({ ...data, mail: e.target.value })
                          }
                          data-error="Please enter your Email"
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="phone_number">
                          {t("contactfullphonelabel")}
                        </label>
                        <input
                          type="text"
                          id="phone_number"
                          name="phone_number"
                          className="form-control"
                          defaultValue=""
                          onChange={(e) =>
                            setData({ ...data, phonenumber: e.target.value })
                          }
                          placeholder={`${t("contactfullphonePlace")}`}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="phone_number">
                          {t("contactfullSubjectlabel")}
                        </label>
                        <input
                          type="text"
                          id="phone_number"
                          name="phone_number"
                          className="form-control"
                          defaultValue=""
                          onChange={(e) =>
                            setData({ ...data, subject: e.target.value })
                          }
                          placeholder={`${t("contactfullSubjectPlace")}`}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="message">
                          {t("contactfullmeglabel")}
                        </label>
                        <textarea
                          name="message"
                          id="message"
                          className="form-control"
                          rows={4}
                          placeholder={`${t("contactfullmegPlace")}`}
                          required
                          data-error="Please enter your Message"
                          defaultValue={""}
                          onChange={(e) =>
                            setData({ ...data, message: e.target.value })
                          }
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group pt-5 mb-0">
                        <button
                          type="submit"
                          disabled={isLoader}
                          className="theme-btn ConsubmitBtn w-100"
                        >
                          {t("contactSendbtn")}{" "}
                          <i className="fas fa-angle-double-right" />
                        </button>
                        <div id="msgSubmit" className="hidden" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="contact-info wow fadeInLeft delay-0-2s">
                <div className="contact-info-item style-two">
                  <div className="icon">
                    <i className="fal fa-map-marker-alt" />
                  </div>
                  <div className="content">
                    <span className="title">{t("contactLocH")}</span>
                    <span className="text">{t("contactLocPara")} </span>
                  </div>
                </div>
                <div className="contact-info-item style-two">
                  <div className="icon">
                    <i className="far fa-envelope-open-text" />
                  </div>
                  <div className="content">
                    <span className="title">{t("contactEmailH")}</span>
                    <span className="text">
                      <a href="mailto:info@thetechhorse.com">
                        info@thetechhorse.com
                      </a>
                    </span>
                  </div>
                </div>
                <div className="contact-info-item style-two">
                  <div className="icon">
                    <i className="far fa-phone" />
                  </div>
                  <div className="content">
                    <span className="title">{t("contactPhoneH")}</span>
                    <span className="text">
                      <a href="tel:+91 8925790019">+91 8925790019</a>
                    </span>
                  </div>
                </div>

                <div className="follow-us">
                  <h4>{t("contactFollowH")}</h4>
                  <div className="social-style-two">
                    <a
                      href="https://www.facebook.com/profile.php?id=61550827268393"
                      target="_blank"
                      className="iconContact"
                    >
                      <i className="fi fi-brands-facebook contacticons"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/the-tech-horse-946a12299/"
                      className="iconContact"
                      target="_blank"
                    >
                      <i className="fi fi-brands-twitter-alt contacticons"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/the-tech-horse-946a12299/"
                      className="iconContact"
                      target="_blank"
                    >
                      <i className="fi fi-brands-linkedin contacticons"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/tthtechnologies/?hl=en"
                      className="iconContact"
                      target="_blank"
                    >
                      <i className="fi fi-brands-instagram contacticons" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Us Page Area end */}
      {/* Our Location Address Area start */}
      <section className="our-location-address-area rel z-1">
        <div className="container">
          <div className="row medium-gap justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="location-address-item wow fadeInUp delay-0-2s">
                <div className="top-part">
                  <img
                    src="/images/contact/location1.png"
                    alt="Location"
                    loading="lazy"
                  />
                  <h5>{t("contactUSAH")}</h5>
                </div>
                <div className="bottom-part">
                  <ul>
                    <li>
                      <i
                        className="fal fa-map-marker-alt"
                        style={{ color: "#f4c939" }}
                      />
                      {t("contactUSLOc")}
                    </li>
                    {/* <li>
                      <i className="far fa-envelope-open-text" style={{color:"#f4c939"}}/>{" "}
                      support@gmail.com
                    </li> */}
                    <li>
                      <i
                        className="far fa-phone"
                        style={{ color: "#f4c939" }}
                      />{" "}
                      +1 (256)226-2281
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-address-item wow fadeInUp delay-0-4s">
                <div className="top-part">
                  <img
                    src="/images/contact/location2.png"
                    alt="Location"
                    loading="lazy"
                  />
                  <h5>{t("contactAus")}</h5>
                </div>
                <div className="bottom-part">
                  <ul>
                    <li>
                      <i
                        className="fal fa-map-marker-alt"
                        style={{ color: "#f4c939" }}
                      />{" "}
                      {t("contactAusLOc")}
                    </li>
                    {/* <li>
                      <i className="far fa-envelope-open-text" style={{color:"#f4c939"}}/>{" "}
                      support@gmail.com
                    </li> */}
                    <li>
                      <i
                        className="far fa-phone"
                        style={{ color: "#f4c939" }}
                      />{" "}
                      +61 416795191
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-address-item wow fadeInUp delay-0-6s">
                <div className="top-part">
                  <img
                    src="/images/contact/location3.png"
                    alt="Location"
                    loading="lazy"
                  />
                  <h5>{t("contactDub")}</h5>
                </div>
                <div className="bottom-part">
                  <ul>
                    <li>
                      <i
                        className="fal fa-map-marker-alt"
                        style={{ color: "#f4c939" }}
                      />
                      {t("contactDubLoc")}
                    </li>
                    {/* <li>
                      <i className="far fa-envelope-open-text" style={{color:"#f4c939"}}/>{" "}
                      support@gmail.com
                    </li> */}
                    <li>
                      <i
                        className="far fa-phone"
                        style={{ color: "#f4c939" }}
                      />{" "}
                      +971 529817609
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Location Address Area end */}
      {/* Location Map Area Start */}
      <div className="contact-page-map wow fadeInUp delay-0-2s">
        <div className="our-location">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d124375.27555406593!2d80.18136474503294!3d13.053018362998195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x693392f67a03638b%3A0x13d92b9fcbe060a3!2s292%2C%20Royapettah%20High%20Rd%2C%20Ganapathy%20Colony%2C%20Royapettah%2C%20Chennai%2C%20Tamil%20Nadu%20600014!3m2!1d13.0530314!2d80.2637666!5e0!3m2!1sen!2sin!4v1714992229944!5m2!1sen!2sin"
            style={{ border: 0, width: "100%" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
      {/* Location Map Area End */}
      <Footer />

      {/* SuccessMessage */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center">
            <img
              className="img-fluid"
              style={{ width: "100px" }}
              src="/assets/certificate.gif"
            />
          </div>
          <h3 className="text-center mb-0">
            Your message has been sent successfully.
          </h3>
          <h3 className="text-center mt-0">We will reach you out soon...</h3>
          <div className="text-end"><Link to="/"><Button className="continue" >Continue ..</Button></Link></div>
        </Box>
      </Modal>
    </>
  );
};
export default Contact;
