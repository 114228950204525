import React, { useEffect, useRef } from "react";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";
import "../css/flaticon.min.css";
import "../css/fontawesome-5.14.0.min.css";
import "../Main.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Devops = () => {
  const valueRef = useRef<HTMLDivElement | null>(null);
  const projectRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateValue(valueRef, 0, 20, 1000);
          animateValue(projectRef, 0, 500, 1000);
        }
      });
    }, options);

    if (valueRef.current) observer.observe(valueRef.current);
    if (projectRef.current) observer.observe(projectRef.current);
  }, []);

  function animateValue(obj: any, start: any, end: any, duration: any) {
    let startTimestamp: any = null;

    const step = (timestamp: any) => {
      if (!startTimestamp) startTimestamp = timestamp;

      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const currentValue = Math.floor(progress * (end - start) + start);
      obj.current.innerText = currentValue.toString() + "+";

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }

  const {t}=useTranslation()
  return (
    <>
      <Helmet>
        <title>Elevate Your Operations: DevOps Solutions | The TechHorse</title>
        <meta
          name="description"
          content="Streamline your development and operations with our DevOps solutions. Unlock efficiency and agility for your digital infrastructure today."
        />
      </Helmet>
      <Header />
      <section className="page-banner-area devops-banner pt-245 rpt-150 pb-170 rpb-100 rel z-1 bgc-lighter text-center">
        <div className="container">
          <div className="banner-inner rpt-10">
            <h1 className="page-title wow fadeInUp delay-0-2s">
              {t("devopsBannerH")}<i className="fi fi-rr-cloud-share"></i>
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <a>{t("devopsHome")}</a>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  style={{ color: "#fff" }}
                >
                  {t("devopsBannerH")}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="banner-shapes">
          <div
            className="circle devops-circle wow zoomInLeft delay-0-2s"
            data-wow-duration="2s"
          />
          <img
            className="shape-one"
            src="/images/shapes/hero-shape1.png"
            alt="Shape"
            loading="lazy"
          />
          <img
            className="shape-two"
            src="/images/shapes/hero-shape2.png"
            alt="Shape"
            loading="lazy"
          />
        </div>
      </section>
      {/* About Area start */}
      <section className="about-area py-130 rel z-1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-6">
              <div className="about-image rmb-65 wow fadeInUp delay-0-2s">
                <img src="/assets/5 Dev/img1.webp" alt="About" loading="lazy" />
                <div
                  className="years-fo-experience devops-screen1 bgs-cover counter-text-wrap"
                  style={{
                    backgroundImage:
                      "url(/images/about/years-fo-experience-bg.png)",
                  }}
                >
                  <h2
                    ref={valueRef}
                    style={{ fontWeight: 400, color: "#fff" }}
                  ></h2>
                  <h4>{t("devopsYRSEXP")}</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="about-content wow fadeInUp delay-0-4s">
                <div className="section-title mb-30">
                  <span className="sub-title digital_head mb-15">
                  {t("devopsSolH")}
                  </span>
                  <h2>{t("devopsSolSub")}</h2>
                </div>
                <p>
                {t("devopsSolPara")}
                </p>
                <div className="client-satisfactions counter-text-wrap pt-10 pb-10">
                  <img
                    src="/images/about/hands.png"
                    alt="Hands"
                    loading="lazy"
                  />
                  <h2 ref={projectRef} style={{ fontWeight: 400 }}></h2>
                  <span className="heading">{t("devopsCliSta")}</span>
                </div>
                <Link to="/contact-us">
                  <a className="theme-btn mt-15 devopslearn">
                  {t("learnMoreBtn")} <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Area end */}
      {/* Mission Visson Area start */}
      <section
        className="mission-visson-area pb-130 rel z-1"
        style={{ overflow: "hidden" }}
      >
        <div className="container">
          <div className="row large-gap align-items-center">
            <div className="col-lg-6">
              <div className="mission-visson-content rmb-65 wow fadeInUp delay-0-2s">
                <div className="section-title mb-35">
                  <span className="sub-title mb-15 digital_head">
                  {t("devopsMissH")}
                  </span>
                  <h2>{t("devopsMissSub")}</h2>
                  <p>
                  {t("devopsMissPara")}
                  </p>
                </div>
                <Link to="/contact-us">
                  <a
                    className="theme-btn style-three devopslearn"
                    style={{ color: "#fff" }}
                  >
                    {t("learnMoreBtn")} <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mission-visson-image wow fadeInUp delay-0-4s">
                <img src="/assets/5 Dev/img2.webp" alt="About" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
        <div className="wave-shapes">
          <img
            className="shape one"
            src="/images/shapes/mission-vision1.png"
            alt="Wave Shape"
            loading="lazy"
          />
          <img
            className="shape two"
            src="/images/shapes/mission-vision2.png"
            alt="Wave Shape"
            loading="lazy"
          />
        </div>
      </section>
      {/* Mission Visson Area end */}
      {/* Services Area start */}
      <section className="services-area pt-130 pb-100 rel z-1">
        <div className="container">
          <div className="section-title text-center mb-55">
            <span className="sub-title mb-15 digital_head">
            {t("devopsSerH")}
            </span>
            <h2>{t("devopsSerSub")}</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="service-item devopsHov wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="fi fi-tr-cloud-share hov-boxDevops" />
                </div>
                <div className="content">
                  <h5>
                    <a>{t("devopsCloud1H")}</a>
                  </h5>
                  <p>{t("devopsCloud1Para")}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="service-item devopsHov wow fadeInUp delay-0-4s">
                <div className="icon">
                  <i className="fi fi-ts-customization-cogwheel  hov-boxDevops" />
                </div>
                <div className="content">
                  <h5>
                    <a>{t("devopsCloud2H")}</a>
                  </h5>
                  <p>{t("devopsCloud2Para")}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="service-item devopsHov wow fadeInUp delay-0-6s">
                <div className="icon">
                  <i className="fi fi-tr-discover  hov-boxDevops"></i>
                </div>
                <div className="content">
                  <h5>
                    <a>{t("devopsCloud3H")}</a>
                  </h5>
                  <p>{t("devopsCloud3Para")}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="service-item devopsHov wow fadeInUp delay-0-8s">
                <div className="icon">
                  <i className="flaticon-coding hov-boxDevops" />
                </div>
                <div className="content">
                  <h5>
                    <a>{t("devopsCloud4H")}</a>
                  </h5>
                  <p>{t("devopsCloud4Para")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Services Area end */}
      {/* Project Area start */}
      {/* <section className="project-area pb-100 rel z-1" style={{overflow:"hidden"}}>
        <div className="container-fluid gap-wide">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-md-6">
              <div className="project-item wow fadeInUp delay-0-2s">
                <div className="project-iamge">
                  <img
                    src="/images/projects/project1.jpg"
                    alt="Project"
                    loading="lazy"
                  />
                  <div className="project-over">
                    <h3>Dashboard Design</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="project-item wow fadeInUp delay-0-4s">
                <div className="project-iamge">
                  <img
                    src="/images/projects/project2.jpg"
                    alt="Project"
                    loading="lazy"
                  />
                  <div className="project-over">
                    <h3>Dashboard Design</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="project-item wow fadeInUp delay-0-6s">
                <div className="project-iamge">
                  <img
                    src="/images/projects/project3.jpg"
                    alt="Project"
                    loading="lazy"
                  />
                  <div className="project-over">
                    <h3>Dashboard Design</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="project-item wow fadeInUp delay-0-2s">
                <div className="project-iamge">
                  <img
                    src="/images/projects/project4.jpg"
                    alt="Project"
                    loading="lazy"
                  />
                  <div className="project-over">
                    <h3>Dashboard Design</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="project-item wow fadeInUp delay-0-4s">
                <div className="project-iamge">
                  <img
                    src="/images/projects/project5.jpg"
                    alt="Project"
                    loading="lazy"
                  />
                  <div className="project-over">
                    <h3>Dashboard Design</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="project-item wow fadeInUp delay-0-6s">
                <div className="project-iamge">
                  <img
                    src="/images/projects/project6.jpg"
                    alt="Project"
                    loading="lazy"
                  />
                  <div className="project-over">
                    <h3>Dashboard Design</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Project Area end */}
      {/* Why Choose Us Area start */}
      <section className="why-choose-area py-120 rpb-130 rel z-1 overflow-hidden">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-5 col-lg-6">
              <div className="why-choose-content pt-10 rmb-65 wow fadeInUp delay-0-2s">
                <div className="section-title mb-35">
                  <span className="sub-title mb-15 digital_head">
                  {t("devopswhyH")}
                  </span>
                  <h2>{t("devopswhySub")}</h2>
                </div>
                <ul className="list-style-one devopsli">
                  <li>
                    <div className="content">
                      <h5>{t("devopslist1H")}</h5>
                      <p>
                      {t("devopslist1Para")}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <h5>{t("devopslist2H")}</h5>
                      <p>
                      {t("devopslist2Para")}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <h5>
                      {t("devopslist3H")}                      </h5>
                      <p>
                      {t("devopslist3Para")}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <h5>{t("devopslist4H")}</h5>
                      <p>
                      {t("devopslist4Para")}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-image wow fadeInUp delay-0-4s">
                <img
                  src="/assets/5 Dev/img3.webp"
                  alt="why choose us"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="circle-shapes">
          <span className="shape one wow slideInDown delay-0-2s" />
          <span className="shape two wow slideInDown delay-0-4s" />
          <span className="shape three wow slideInDown delay-0-6s" />
          <span className="shape four wow slideInDown delay-0-8s" />
        </div>
      </section>
      {/* Why Choose Us Area end */}

      {/* Support & Features Area start */}
      <section
        className="support-features-area devops-banner bgs-cover bgc-lighter pt-130 pb-100 rel z-1"
        style={{
          backgroundImage: "url(/images/features/support-features-bg.png)",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-6 col-lg-7">
              <div className="support-features-wrap rmb-65 wow fadeInUp delay-0-2s">
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                    <div className="service-item devopsHov style-two">
                      <div className="icon">
                        <i className="fi fi-tr-channel hov-boxDevops" />
                      </div>
                      <h5>
                        <a>{t("devopsSupportList1")}</a>
                      </h5>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                    <div className="service-item devopsHov style-two">
                      <div className="icon">
                        <i className="fi fi-ts-it-computer hov-boxDevops" />
                      </div>
                      <h5>
                        <a>{t("devopsSupportList2")}</a>
                      </h5>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                    <div className="service-item devopsHov style-two">
                      <div className="icon">
                        <i className="flaticon-aim hov-boxDevops" />
                      </div>
                      <h5>
                        <a>{t("devopsSupportList3")}</a>
                      </h5>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                    <div className="service-item devopsHov style-two">
                      <div className="icon">
                        <i className="fi fi-ts-customize hov-boxDevops" />
                      </div>
                      <h5>
                        <a>{t("devopsSupportList4")}</a>
                      </h5>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                    <div className="service-item devopsHov style-two">
                      <div className="icon">
                        <i className="fi fi-tr-trophy-star hov-boxDevops" />
                      </div>
                      <h5>
                        <a>{t("devopsSupportList5")}</a>
                      </h5>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                    <div className="service-item devopsHov style-two">
                      <div className="icon">
                        <i className="fi fi-brands-github hov-boxDevops" />
                      </div>
                      <h5>
                        <a>{t("devopsSupportList6")}</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="support-features-content mb-30 wow fadeInUp delay-0-4s">
                <div className="section-title mb-30">
                  <span className="sub-title mb-15" style={{ color: "#fff" }}>
                  {t("devopsSupFeatureH")}
                  </span>
                  <h2 style={{ color: "#fff" }}>
                  {t("devopsSupFeatureSub")}
                  </h2>
                </div>
                <p style={{ color: "#fff" }}>
                {t("devopsSupFeaturePara")}
                </p>
                <Link to="/contact-us">
                  <a className="theme-btn devopslearnCall mt-25">
                  {t("devopsLearnbtn")}{" "}
                    <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features-area-seven pt-80 pb-30 rel z-1">
        <div className="container">
          <h3
            className="sub-title style-two mb-15 pb-30 text-center"
            style={{ color: "#E17779" }}
          >
            {t("devopsLifeH")}
          </h3>
          <div className="row row-cols-xl-5 row-cols-md-3 row-cols-sm-2 row-cols-1 justify-content-center">
            <div className="col">
              <div className="feature-item-seven mt-30 wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="fi fi-ts-method" />
                </div>
                <h5>{t("devopslife1H")}</h5>
                <p>
                {t("devopslife1Para")}</p>
              </div>
            </div>
            <div className="col">
              <div className="feature-item-seven color-two wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="fi fi-ts-workflow-setting-alt" />
                </div>
                <h5>{t("devopslife2H")}</h5>
                <p>
                {t("devopslife2Para")}{" "}
                </p>
              </div>
            </div>
            <div className="col">
              <div className="feature-item-seven color-three mt-40 wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="fi fi-tr-process" />
                </div>
                <h5>{t("devopslife3H")}</h5>
                <p>
                {t("devopslife3Para")}{" "}
                </p>
              </div>
            </div>
            <div className="col">
              <div className="feature-item-seven color-four wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="flaticon-checklist" />
                </div>
                <h5>{t("devopslife4H")}</h5>
                <p>
                {t("devopslife4Para")}{" "}
                </p>
              </div>
            </div>
            <div className="col">
              <div className="feature-item-seven color-five mt-40 wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="flaticon-goal" />
                </div>
                <h5>{t("devopslife5H")}</h5>
                <p>
                {t("devopslife5Para")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Support & Features Area end */}
      <section className="call-to-action-area devops-banner  bgc-black mt-50 pt-80 pb-50">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-white mb-25 wow fadeInUp delay-0-2s">
                <h2>{t("devopsEleH")}</h2>
                <p>
                {t("devopsElePara")}
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-lg-end">
              <Link to="/contact-us">
                <a className="theme-btn devopslearnCall style-two mb-30 wow fadeInUp delay-0-4s">
                {t("devopsEleBtn")} <i className="fas fa-angle-double-right" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Devops;
