import Slider from "react-slick";
import { projectThreeActive } from "./sliderProps";
import { useTranslation } from "react-i18next";

const RecentProjects = () => {
  const {t}=useTranslation()
  return (
    <section
      className="project-area-three py-80 rel z-1"
      id="project"
      style={{ overflow: "hidden" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg10">
            <div className="section-title text-center mb-50 wow fadeInUp delay-0-2s">
              <span className="sub-title mb-15">{t("recentProjH")}</span>
              <h2>{t("recentProjSub")}</h2>
            </div>
          </div>
        </div>
      </div>
      <Slider {...projectThreeActive} className="project-three-active">
        <div className="project-item style-two wow fadeInUp delay-0-2s">
          <div className="project-iamge">
            <img
              src="/assets/Project/recent1.jpg"
              alt="Project"
              loading="lazy"
            />
            <div className="project-over">
              {/* <a href="">
                <a className="details-btn">
                <i className="far fa-arrow-right" />
                </a>
              </a> */}
            </div>
          </div>
          <div className="project-content">
            <h4>
              <a href="">{t("wishitonH")}</a>
            </h4>
            <span className="category">{t("wishitonResp")}</span>
          </div>
        </div>
        <div className="project-item style-two wow fadeInUp delay-0-4s">
          <div className="project-iamge">
            <img
              src="/assets/new.jpg"
              alt="Project"
              loading="lazy"
            />
            <div className="project-over">
              {/* <a href="">
                <a className="details-btn">
                <i className="far fa-arrow-right" />
                </a>
              </a> */}
            </div>
          </div>
          <div className="project-content">
            <h4>
              <a href="">{t("speedpH")}</a>
            </h4>
            <span className="category">{t("wishitonResp")}</span>
          </div>
        </div>
        <div className="project-item style-two wow fadeInUp delay-0-6s">
          <div className="project-iamge">
            <img
              src="/assets/Project/recent3.jpg"
              alt="Project"
              loading="lazy"
            />
            <div className="project-over">
              {/* <a href="">
                <a className="details-btn">
                <i className="far fa-arrow-right" />
                </a>
              </a> */}
            </div>
          </div>
          <div className="project-content">
            <h4>
              <a href="">{t("wishitonMobH")}</a>
            </h4>
            <span className="category">{t("wishitonResp")}</span>
          </div>
        </div>
      </Slider>
    </section>
  );
};
export default RecentProjects;
