import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "../Main.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ClientsFeedback: React.FC = () => {
  const sliderRef = useRef<Slider>(null);

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    speed: 400,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section
      className="testimonial-area-two rel pt-80 z-1 mt-130 mb-120"
      style={{ overflow: "hidden", display: "none" }}
    >
      <div className="container for-middle-border">
        <div className="row justify-content-between align-items-center pb-90 rpb-35 wow fadeInUp delay-0-2s">
          <div className="col-xl-7 col-lg-8">
            <div className="section-title">
              {/* <span className="sub-title mb-15">Clients Testimonials</span> */}
              <h2>Behind the Scene</h2>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="slider-arrow-btns text-lg-end">
              <button className="work-prev slick-arrow" onClick={previous}>
                <i className="far fa-arrow-left" />
              </button>
              <button className="work-next slick-arrow" onClick={next}>
                <i className="far fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
        <Slider
          className="testimonial-two-active"
          {...settings}
          ref={sliderRef}
        >
          <div className="testimonial-item-two wow fadeInUp delay-0-2s">
            <div className="testimonial-author">
              <img
                src="/images/feedback/testimonial1.jpg"
                alt="Author"
                loading="lazy"
              />
            </div>
            <div className="testimonial-content d-flex justify-content-start align-items-center">
              <div className="author-description">
                <span className="h5">Karthikeyan A</span>
                <span className="designation">
                  Software Executive Developer
                </span>
              </div>
            </div>
          </div>
          <div className="testimonial-item-two wow fadeInUp delay-0-4s">
            <div className="testimonial-author">
              <img
                src="/images/feedback/testimonial2.jpg"
                alt="Author"
                loading="lazy"
              />
            </div>
            <div className="testimonial-content  d-flex justify-content-start align-items-center">
              <div className="author-description">
                <span className="h5">Pravin M</span>
                <span className="designation">MERN Stack Developer</span>
              </div>
            </div>
          </div>
          <div className="testimonial-item-two wow fadeInUp delay-0-2s">
            <div className="testimonial-author">
              <img
                src="/images/feedback/testimonial1.jpg"
                alt="Author"
                loading="lazy"
              />
            </div>
            <div className="testimonial-content  d-flex justify-content-start align-items-center">
              <div className="author-description">
                <span className="h5">Hibah</span>
                <span className="designation">Human Resource</span>
              </div>
            </div>
          </div>
          <div className="testimonial-item-two wow fadeInUp delay-0-4s">
            <div className="testimonial-author">
              <img
                src="/images/feedback/testimonial2.jpg"
                alt="Author"
                loading="lazy"
              />
            </div>
            <div className="testimonial-content  d-flex justify-content-start align-items-center">
              <div className="author-description">
                <span className="h5">Karthik K</span>
                <span className="designation">UI/UX Designer</span>
              </div>
            </div>
          </div>
          <div className="testimonial-item-two wow fadeInUp delay-0-4s">
            <div className="testimonial-author">
              <img
                src="/images/feedback/testimonial2.jpg"
                alt="Author"
                loading="lazy"
              />
            </div>
            <div className="testimonial-content  d-flex justify-content-start align-items-center">
              <div className="author-description">
                <span className="h5">Monica Vivienn J</span>
                <span className="designation">UI/UX Designer</span>
              </div>
            </div>
          </div>
          <div className="testimonial-item-two wow fadeInUp delay-0-4s">
            <div className="testimonial-author">
              <img
                src="/images/feedback/testimonial2.jpg"
                alt="Author"
                loading="lazy"
              />
            </div>
            <div className="testimonial-content  d-flex justify-content-start align-items-center">
              <div className="author-description">
                <span className="h5">Rajiya Begum</span>
                <span className="designation">Back End Developer</span>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default ClientsFeedback;
