import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

interface ProgressBarProps {
  value: number;
  color: string;
  extraCls?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  color,
  extraCls,
}) => {
  const hex2rgba = (hex: string, alpha: number = 1): string => {
    const [r, g, b] = hex.match(/\w\w/g)!.map((x: string) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };

  return (
    <div
      className={`progress-content mb-3 ${extraCls ? extraCls : "one"}`}
      style={{ width: 130, height: 130 }}
    >
      <h3>{value}%</h3>
      <CircularProgressbar
        value={value}
        strokeWidth={5}
        styles={buildStyles({
          pathColor: color,
          trailColor: hex2rgba(color, 0.2),
        })}
      />
    </div>
  );
};

export default ProgressBar;
