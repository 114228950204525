import React, { useEffect, useState } from "react";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";
import "../css/flaticon.min.css";
import "../css/fontawesome-5.14.0.min.css";
import "../Main.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useTranslation } from "react-i18next";

const SoftwareDevelop = () => {
  const [data, setData] = useState({
    name: "",
    mail: "",
    phonenumber: "",
    subject: "",
    message: "",
  });

  const handleContact = async (e: any) => {
    e.preventDefault();
    try {
      await axios.post("https://tth-website-api.onrender.com/ContactUs", {
        name: data.name,
        userEmail: data.mail,
        userPhonenumber: data.phonenumber,
        subject: data.subject,
        message: data.message,
      });
      setData({
        name: "",
        mail: "",
        phonenumber: "",
        subject: "",
        message: "",
      });
      alert("Mail Send Successfuly");
    } catch (err: any) {
      console.log(err.message);
      alert("Got internal error");
    }
  };

  const {t}=useTranslation()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <title>We Create Software According to Your Need | The TechHorse</title>
        <meta
          name="description"
          content="Transform your vision into reality with our expert software development services. We offer custom solutions tailored to elevate your digital presence. Explore now!"
        />
      </Helmet>
      <Header />

      <section className="page-banner-area software-bg pt-245 rpt-150 pb-170 rpb-100 rel z-1 bgc-lighter text-center">
        <div className="container">
          <div className="banner-inner rpt-10">
            <h1 className="page-title wow fadeInUp delay-0-2s">
              {t("softBannerH")}<span>{t("softBannerSpan")}</span>
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <a>{t("softBannerHome")}</a>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  style={{ color: "#fff" }}
                >
                  {t("softBannerHomeTitle")}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="banner-shapes">
          <div
            className="circle softwarecircle wow zoomInLeft delay-0-2s"
            data-wow-duration="2s"
          />
          <img
            className="shape-one"
            src="/images/shapes/hero-shape1.png"
            alt="Shape"
            loading="lazy"
          />
          <img
            className="shape-two"
            src="/images/shapes/hero-shape2.png"
            alt="Shape"
            loading="lazy"
          />
        </div>
      </section>
      {/* Statistics Five Area Start */}
      <section className="statistics-area-five py-130">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="statistics-five-content mb-30 rmb-65 wow fadeInRight delay-0-2s">
                <div className="section-title mb-25">
                  <span className="sub-title softwareHEad style-two mb-15">
                    {t("softCompanyH")}
                  </span>
                  <h2>
                  {t("softCompanySub")}
                  </h2>
                </div>
                <p>
                {t("softCompanyPara")}
                </p>
                <Link to="/contact-us">
                  <a className="theme-btn softwareLearn style-two mt-15">
                  {t("learnMoreBtn")} <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="statistics-five-image wow fadeInLeft delay-0-2s">
                <img
                  src="/assets/4 Soft/Group 12.webp"
                  alt="Statistics"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Statistics Five Area end */}
      {/* Working Process Area start */}
      <section
        className="work-process-area pb-95 rel z-1"
        style={{ overflow: "hidden" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
                <span className="sub-title style-two softwareHEad mb-15">
                  {t("softWorkH")}
                </span>
                <h2>{t("softWorkSub")}</h2>
              </div>
            </div>
          </div>
          <div className="work-process-wrap rel z-1">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div className="work-process-item1 mt-30 wow fadeInUp delay-0-2s">
                  <div className="icon softiconbor">
                    <span className="number">01</span>
                    <i className="flaticon-optimization softicon" />
                  </div>
                  <h4>{t("softwork1H")}</h4>
                  <p>
                  {t("softwork1Para")}</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div className="work-process-item1 wow fadeInUp delay-0-4s">
                  <div className="icon softiconbor">
                    <span className="number">02</span>
                    <i className="flaticon-link softicon" />
                  </div>
                  <h4>{t("softwork2H")}</h4>
                  <p>
                  {t("softwork2Para")}
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div className="work-process-item1 mt-55 wow fadeInUp delay-0-6s">
                  <div className="icon softiconbor">
                    <span className="number">03</span>
                    <i className="flaticon-data softicon" />
                  </div>
                  <h4>{t("softwork3H")}</h4>
                  <p>
                  {t("softwork3Para")}
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div className="work-process-item1 mt-45 wow fadeInUp delay-0-8s">
                  <div className="icon softiconbor">
                    <span className="number">04</span>
                    <i className="flaticon-star softicon" />
                  </div>
                  <h4>{t("softwork4H")}</h4>
                  <p>
                  {t("softwork4Para")}
                  </p>
                </div>
              </div>
            </div>
            <div className="work-process-shape">
              <img
                src="/images/shapes/worp-process-step.png"
                alt="Shape"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Working Process Area end */}
      {/* Harness Cutting-Edge start*/}
      <section className="call-to-action-area  bgc-black software-bg pt-80 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 p-3 d-flex justify-content-center align-items-center">
              <div>
                <h2>
                {t("softharnessH")}
                </h2>
                <p style={{ color: "#fff" }}>
                {t("softharnessPara")}
                </p>
                <Link to="/contact-us">
                  <a className="theme-btn softwareget style-two mt-15">
                  {t("softHarnessschBtn")}{" "}
                    <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 p-3">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#c1a184",
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                    }}
                  >
                    <div>
                      <i
                        className="fi fi-ts-source-data"
                        style={{ color: "#fff", fontSize: "30px" }}
                      />
                    </div>
                  </div>
                  <h4>{t("softHarright1H")}</h4>
                  <p style={{ color: "#fff" }}>
                  {t("softHarright1Para")}
                  </p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#c1a184",
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                    }}
                  >
                    <div>
                      <i
                        className="fi fi-tr-plan"
                        style={{ color: "#fff", fontSize: "30px" }}
                      />
                    </div>
                  </div>
                  <h4>{t("softHarright2H")}</h4>
                  <p style={{ color: "#fff" }}>
                  {t("softHarright2Para")}
                  </p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#c1a184",
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                    }}
                  >
                    <div>
                      <i
                        className="fi fi-tr-cloud-share"
                        style={{ color: "#fff", fontSize: "30px" }}
                      />
                    </div>
                  </div>
                  <h4>{t("softHarright3H")}</h4>
                  <p style={{ color: "#fff" }}>
                  {t("softHarright3Para")}
                  </p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#c1a184",
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                    }}
                  >
                    <div>
                      <i
                        className="fi fi-tr-customize"
                        style={{ color: "#fff", fontSize: "30px" }}
                      />
                    </div>
                  </div>
                  <h4>{t("softHarright4H")}</h4>
                  <p style={{ color: "#fff" }}>
                  {t("softHarright4Para")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Harness Cutting-Edge end*/}
      {/*Curious whether start*/}
      <section className="call-to-action-area pt-80 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 ">
              <div>
                <h2>
                  {t("softCurH1")}{" "}
                  <span className="softwareHEad"> {t("softCurSpan")}</span> {t("softCurH2")}
                </h2>
                <h3>{t("softCurSub")}</h3>
                <p>
                {t("softCurPara1")}
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <p>
              {t("softCurPara2")}
              </p>
              <form
                onSubmit={handleContact}
                id="contact-area-form"
                className="contact-area-form text-center wow fadeInRight delay-0-2s"
                name="contact-area-form"
                action="#"
                method="post"
              >
                <h4 className="softwareHEad">{t("softFormH")}</h4>
                <input
                  type="text"
                  id="full-name"
                  name="full-name"
                  className="form-control"
                  defaultValue=""
                  placeholder={`${t("softFullName")}`}
                  required
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
                <input
                  type="email"
                  id="blog-email"
                  name="blog-email"
                  className="form-control"
                  defaultValue=""
                  placeholder={`${t("softemail")}`}
                  required
                  onChange={(e) => setData({ ...data, mail: e.target.value })}
                />
                <input
                  type="text"
                  id="website"
                  name="website"
                  className="form-control"
                  defaultValue=""
                  placeholder={`${t("softSubject")}`}
                  required
                  onChange={(e) =>
                    setData({ ...data, subject: e.target.value })
                  }
                />
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  rows={5}
                  placeholder={`${t("softMeg")}`}
                  required
                  onChange={(e) =>
                    setData({ ...data, message: e.target.value })
                  }
                  defaultValue={""}
                />
                <button type="submit" className="theme-btn2 ">
                {t("softSendBtn")} <i className="fas fa-angle-double-right" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/*Curious whether end*/}
      {/* Call to Action start */}
      <section className="call-to-action-area mt-100 bgc-black software-bg pt-80 pb-50">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-white mb-25 wow fadeInUp delay-0-2s">
                <h2>{t("softLetsH")}</h2>
                <p>
                {t("softLetsPara")}
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-lg-end">
              <Link to="/contact-us">
                <a className="theme-btn softwareget style-two mb-30 wow fadeInUp delay-0-4s">
                {t("softLetsBtn")} <i className="fas fa-angle-double-right" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Call to Action end */}
      <Footer />
    </>
  );
};

export default SoftwareDevelop;
