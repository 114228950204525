import React, { useEffect, useRef, useTransition } from "react";
import Header from "./Home/Header";
import Footer from "./Footer/Footer";
import "./css/flaticon.min.css";
import "./css/fontawesome-5.14.0.min.css";
import "./Main.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Aboutus = () => {
  const valueRef = useRef<HTMLDivElement | null>(null);
  const trustRef = useRef<HTMLDivElement | null>(null);
  const teamRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateValue(valueRef, 0, 5, 1000);
          animateValue(trustRef, 0, 5, 1000);
          animateValue(teamRef, 0, 15, 1000);
        }
      });
    }, options);

    if (valueRef.current) observer.observe(valueRef.current);
    if (trustRef.current) observer.observe(trustRef.current);
    if (teamRef.current) observer.observe(teamRef.current);
  }, []);

  function animateValue(obj: any, start: any, end: any, duration: any) {
    let startTimestamp: any = null;

    const step = (timestamp: any) => {
      if (!startTimestamp) startTimestamp = timestamp;

      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const currentValue = Math.floor(progress * (end - start) + start);
      obj.current.innerText = currentValue.toString() + "+";

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }

  const {t}=useTranslation()

  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content=" We're a software development company that builds products for companies worldwide."
        />
      </Helmet>
      <Header />
      <section
        className="page-banner-area aboutBanner  pt-245 rpt-150 pb-170 rpb-100 rel z-1 bgc-lighter text-center"
        style={{ backgroundColor: "#f7f9fd" }}
      >
        <div className="container">
          <div className="banner-inner rpt-10">
            <h1 className="page-title wow fadeInUp delay-0-2s">
              {t("abtBannerH")} <span>{t("abtBannerSpan")}</span>
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <a>{t("abtBannerHome")}</a>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  style={{ color: "#000" }}
                >
                  {t("abtBannerHomeNav")}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="banner-shapes">
          <div
            style={{ backgroundColor: "#fff" }}
            className="circle aboutcircle  wow zoomInLeft delay-0-2s"
            data-wow-duration="2s"
          />
          <img
            className="shape-one"
            src="/images/shapes/hero-shape1.png"
            alt="Shape"
            loading="lazy"
          />
          <img
            className="shape-two"
            src="/images/shapes/hero-shape2.png"
            alt="Shape"
            loading="lazy"
          />
        </div>
      </section>
      <section
        className="ww-do-two-area py-130 rel z-1"
        style={{ overflow: "hidden" }}
      >
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-5 col-lg-6">
              <div className="ww-do-two-content rmb-65 wow fadeInUp delay-0-2s">
                <div className="section-title mb-30">
                  <span className="sub-title style-two mb-15 aboutHead">
                  {t("abtwhatH")}
                  </span>
                  <h2>{t("abtwhatSub")}</h2>
                </div>
                <p>
                {t("abtwhatPara")}
                </p>
                <div className="row pt-15">
                  <div className="col-sm-6">
                    <div className="feature-item-two border-right pe-sm-3">
                      <div className="icon aboutBanner">
                        <i className="fas fa-check" />
                      </div>
                      <p>
                      {t("abtwhatLi1Para")}
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="feature-item-two color-two">
                      <div className="icon aboutBanner">
                        <i className="fas fa-check" />
                      </div>
                      <p>
                      {t("abtwhatLi2Para")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ww-do-btns">
                  <Link to="/contact-us">
                    <a className="theme-btn aboutbtn mt-15">
                    {t("abtcreateBtn")}{" "}
                      <i className="fas fa-angle-double-right" />
                    </a>
                  </Link>
                  {/* <a
                    href="https://www.youtube.com/watch?v=9Y7ma241N8k"
                    className="mfp-iframe video-play-text mt-15 mx-3"
                  >
                    <i className="fas fa-play" /> <span>Watch Videos</span>
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ww-do-two-images rel">
                <div className="row">
                  <div className="col-sm-7 offset-1">
                    <img
                      className="image-one wow fadeInUp delay-0-4s"
                      src="/assets/9 about/img1.webp"
                      alt="What We Do"
                      loading="lazy"
                    />
                  </div>
                  <div className="col-sm-4">
                    <img
                      className="image-two wow fadeInUp delay-0-6s"
                      src="/assets/9 about/img2.webp"
                      alt="What We Do"
                      loading="lazy"
                    />
                    <img
                      className="image-three wow fadeInUp delay-0-8s"
                      src="/assets/9 about/img3.webp"
                      alt="What We Do"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="year-of-experience aboutBanner wow zoomIn delay-0-6s">
                  <span className="number">20</span>
                  {t("abtYearexp")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* What We Do Two Area end */}
      {/* Video Area start */}
      <section
        className="video-area bgc-black pt-50"
        style={{ backgroundColor: "#74b7c2" }}
      >
        <div className="container">
          <div className="row medium-gap align-items-end pt-80">
            <div className="col-lg-6">
              <div className="video-part rmb-65 wow fadeInRight delay-0-2s">
                <img
                  src="/assets/9 about/img4.webp"
                  alt="Video"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="video-content text-white pb-95 rpb-115 wow fadeInLeft delay-0-2s">
                <div className="section-title mb-30">
                  <h2>
                  {t("abtexpH")}
                  </h2>
                </div>
                <ul className="list-style-three">
                  <li>{t("abtexpLi1")}</li>
                  <li>{t("abtexpLi2")}</li>
                  <li>{t("abtexpLi3")}</li>
                  <li>{t("abtexpLi4")}</li>
                  <li>{t("abtexpLi5")}</li>
                  <li>{t("abtexpLi6")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="team-slider-area pt-210 pb-100 rel z-1" style={{width:"100%",overflow:"hidden"}}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center mb-50">
                <span className="sub-title style-two aboutHead mb-15">Amazing Team</span>
                <h2>We Have Well Experience Team Member</h2>
              </div>
            </div>
          </div>
          <div  className=" row ">
            <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp delay-0-2s">
                <div className='team-member '>
              <div className="image">
                <img src="/images/team/member1.jpg" alt="Member" loading="lazy"/>
              </div>
              <div className="content">
                <h5>
                  <a href="/team-profile">Richard C. Harrison</a>
                </h5>
                <span className="designation aboutHead">Senior Manager</span>
                <div className="social-style-one">
                  <a href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin-in" />
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 col-sm-12 wow fadeInUp delay-0-4s">
            <div className='team-member '>
              <div className="image">
                <img src="/images/team/member2.jpg" alt="Member" loading="lazy"/>
              </div>
              <div className="content">
                <h5>
                  <a href="/team-profile">Richard C. Harrison</a>
                </h5>
                <span className="designation aboutHead">Web Developer</span>
                <div className="social-style-one">
                  <a href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin-in" />
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 col-sm-12 wow fadeInUp delay-0-6s">
            <div className='team-member '>
              <div className="image">
                <img src="/images/team/member3.jpg" alt="Member" loading="lazy"/>
              </div>
              <div className="content">
                <h5>
                  <a href="/team-profile">Michael D. Jackson</a>
                </h5>
                <span className="designation aboutHead">Business Developer</span>
                <div className="social-style-one">
                  <a href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin-in" />
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp delay-0-8s">
            <div className='team-member '>
              <div className="image">
                <img src="/images/team/member4.jpg" alt="Member" loading="lazy"/>
              </div>
              <div className="content">
                <h5>
                  <a href="/team-profile">Damian S. Madison</a>
                </h5>
                <span className="designation aboutHead">Product Designer</span>
                <div className="social-style-one">
                  <a href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin-in" />
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
              </div>
            </div>
            </div>
        </div>
      </section> */}
      {/* Feature Six Area start */}
      <section className="freature-area-six rel z-2">
        <div className="container">
          <div
            className="feature-six-inner bgs-cover bgc-primary"
            style={{
              backgroundImage: "url(/images/background/freature-bg-line.png)",
              backgroundColor: "#98d4de",
            }}
          >
            <div className="row">
              <div className="col-lg-6">
                <div className="feature-item-three wow fadeInRight delay-0-3s">
                  <i className="fi fi-tr-site-browser" />
                  <div className="content">
                    <h4>{t("abtwebLi1H")}</h4>
                    <p>
                    {t("abtwebLi1Para")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-item-three color-two wow fadeInRight delay-0-5s">
                  <i className="flaticon-app-development" />
                  <div className="content">
                    <h4>{t("abtwebLi2H")}</h4>
                    <p>
                    {t("abtwebLi2Para")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-item-three color-three wow fadeInRight delay-0-4s">
                  <i className="flaticon-settings" />
                  <div className="content">
                    <h4>{t("abtwebLi3H")}</h4>
                    <p>
                    {t("abtwebLi3Para")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-item-three color-four wow fadeInRight delay-0-6s">
                  <i className="flaticon-optimization" />
                  <div className="content">
                    <h4>{t("abtwebLi4H")}</h4>
                    <p>
                    {t("abtwebLi4Para")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature Six Area end */}
      {/* Call to Action start */}
      <section className="call-to-action-area aboutCall mt-100 bgc-black software-bg pt-250 pb-50">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-white mb-25 wow fadeInUp delay-0-2s">
                <h2>{t("abtLetH")}</h2>
                <p>
                {t("abtLetPara")}
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-lg-end">
              <Link to="/contact-us">
                <a className="theme-btn aboutget style-two mb-30 wow fadeInUp delay-0-4s">
                {t("abtLetBtn")} <i className="fas fa-angle-double-right" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Call to Action end */}
      <Footer />
    </>
  );
};

export default Aboutus;
